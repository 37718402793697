import * as S from "./style";
import useCalendars from "hooks/common/Calendars/useCalendars";
import { isToday, subMonths } from "date-fns";
import LeftButton from "assets/img/common/L.svg";
import RightButton from "assets/img/common/R.svg";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Palette from "libs/Palette/palette";

interface CalendarProps {
  uploadDate: string;
  setUploadDate: Dispatch<SetStateAction<string>>;
}

const Calendar = ({ uploadDate, setUploadDate }: CalendarProps) => {
  const { weekCalendarList, currentDate, setCurrentDate, DAY_LIST } =
    useCalendars();

  const [Days, setDays] = useState<number>(1);

  const handleDateClick = () => {
    const clickedDate = new Date();

    setUploadDate(
      `${clickedDate.getFullYear()}-${(clickedDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${Days.toString().padStart(2, "0")}`
    );
  };

  useEffect(() => {
    handleDateClick();
  }, [Days]);

  return (
    <S.CalendarContainer>
      <S.CalendarTitleBox>
        <img
          alt="calendar"
          src={LeftButton}
          onClick={() => {
            setCurrentDate(subMonths(currentDate, 1));
          }}
        />

        <S.DateTitle>
          {currentDate.toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "long",
          })}
        </S.DateTitle>

        <img
          alt="calendar"
          src={RightButton}
          onClick={() => {
            setCurrentDate(subMonths(currentDate, -1));
          }}
        />
      </S.CalendarTitleBox>

      <S.CalendarBox>
        <div>
          <S.DayTitle>
            {DAY_LIST.map((day, index) => (
              <div key={index}>{day}</div>
            ))}
          </S.DayTitle>
        </div>

        <S.DayBox>
          {weekCalendarList.map((week, weekIndex) => (
            <div key={weekIndex}>
              {week.map((day, dayIndex) => {
                const isWeekend = dayIndex === 0 || dayIndex === 6;
                return (
                  <S.Date
                    onClick={() => {
                      setDays(day);
                    }}
                    key={dayIndex}
                    style={{
                      color: day == 0 ? "white" : "",
                    }}
                    className={`${
                      isToday(
                        new Date(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          day
                        )
                      )
                        ? "today"
                        : ""
                    } ${
                      isWeekend ? (dayIndex === 0 ? "sunday" : "saturday") : ""
                    } `}
                  >
                    <S.DayList
                      style={{
                        backgroundColor: `${
                          Days === day ? `${Palette.Primary.primary5}` : ""
                        }`,

                        color: `${Days === day ? "white" : ""}`,
                      }}
                    >
                      {day}
                    </S.DayList>
                  </S.Date>
                );
              })}
            </div>
          ))}
        </S.DayBox>
      </S.CalendarBox>
    </S.CalendarContainer>
  );
};

export default Calendar;
