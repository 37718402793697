import { styled } from "styled-components";
import Palette from "libs/Palette/palette";

export const AuthContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  background-color: #f7f7f7;

  padding-left: 20px;
  padding-right: 20px;
`;

export const AuthBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 456px;
  height: 489px;

  background-color: white;

  border-radius: 10px;

  box-shadow: 1px 1px 10px 0px rgba(196, 196, 196, 0.2);
`;

export const AuthListBox = styled.div`
  width: 100%;
`;

export const AuthLogoBox = styled.div`
  display: flex;
  justify-content: center;

  padding: 56px 0px 0px 0px;
`;

export const LoginInputList = styled.label`
  width: 100%;

  padding: 45px 40px 0px 40px;

  position: relative;

  label {
    position: absolute;

    left: 40px;
    top: 80%;

    font-size: 18px;

    transform: translateY(-90%);
    transition: all 0.2s ease;

    pointer-events: none;

    color: ${Palette.Neutral.neutral4};
  }

  input:is(:focus, :valid) ~ label {
    font-size: 12px;

    transform: translateY(-300%);

    color: ${Palette.Primary.primary5};
  }
`;

export const LoginInput = styled.input`
  width: 100%;
  height: 45px;

  border: 0;
  outline: 0;
  border-bottom: 1px solid #e7e8e9;

  font-size: 18px;

  color: ${Palette.Neutral.neutral9};

  &:focus {
    border: none;
    border-bottom: 1px solid ${Palette.Primary.primary5};
  }
`;

export const AuthContractBox = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 80px;
  margin-bottom: 8px;
  margin-left: 20px;

  width: 100%;

  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  a {
    display: flex;
    justify-content: center;

    margin-right: 20px;

    font-size: 14px;

    color: ${Palette.Neutral.neutral7};
    text-decoration: none;
  }
`;
export const ToongetherInfo = styled.div`
  font-size: 12px;
  font-family: Pretendard-Regular, Apple SD Gothic Neo, Malgun Gothic;
  color: ${Palette.Neutral.neutral5};
  b {
    font-weight: bold;
  }
`;

export const AuthList = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  @keyframes slide {
    from {
      left: -200px;
      overflow: hidden;

      display: none;
      width: 100%;
    }
    to {
      overflow: hidden;

      left: 0;
      width: 100%;
    }
  }
`;
