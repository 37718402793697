import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import useTitleMakerUpload from "hooks/Series/useSeriesUpload";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { MAX_LENGTH } from "constants/Series/series.constants";
import useDragFileUpload from "hooks/common/Drag/useDragFileUpload";
import Tooni from "assets/img/Tooni.svg";
import { Params, useParams } from "react-router-dom";
import { SeriesParam } from "repositories/ComicUpload/Series/series.repository";

interface SeriesInfoProps {
  setSection: Dispatch<SetStateAction<string>>;
  handleKeyDown: (value: HTMLTextAreaElement) => void;
  backGroundModifyFil: any;
  setModifyImgSrc: Dispatch<SetStateAction<string | any>>;
  imgModifyRef: any;
  modifyImgUrl: string;
  modifySeriesData: SeriesParam;
  onChangeModifyContent: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBackGroundImgEdit: () => void;
}

const SeriesInfo = ({
  handleKeyDown,
  backGroundModifyFil,
  setModifyImgSrc,
  imgModifyRef,
  modifyImgUrl,
  modifySeriesData,
  onChangeModifyContent,
  onBackGroundImgEdit,
}: SeriesInfoProps) => {
  const { seriesid }: Readonly<Params<"seriesid">> = useParams();
  const { ...attr } = useTitleMakerUpload();
  const [hoverIndex, setHoverIndex] = useState<boolean>(false);

  const { onChangeFiles, dragRef } = useDragFileUpload({
    File: seriesid ? backGroundModifyFil : attr.backGroundFile,
    setFile: seriesid ? setModifyImgSrc : attr.setImgSrc,
    dragRef: seriesid ? imgModifyRef : attr.imgRef,
  });

  console.log(attr.imgSrc);

  return (
    <S.SeriesContainer>
      <S.SeriesListBox>
        <div>
          <S.RegisterText>
            대표 이미지<span>*</span>
          </S.RegisterText>

          <input
            type="file"
            id="fileUpload"
            accept="image/png, image/jpg"
            style={{ display: "none" }}
            onChange={onChangeFiles}
          />

          {!seriesid && attr.imgSrc === "" ? (
            <S.SeriesImgUploadBox className="card">
              <label htmlFor="fileUpload" ref={dragRef}>
                <S.ImgPreview
                  alt="preview"
                  src={Tooni}
                  style={{
                    width: "227px",
                    height: "254px",
                  }}
                />
              </label>
            </S.SeriesImgUploadBox>
          ) : (
            <S.SeriesImgUploadBox
              onMouseEnter={() => setHoverIndex(true)}
              onMouseLeave={() => setHoverIndex(false)}
            >
              <S.ImgPreview
                alt="preview"
                src={
                  attr.imgSrc ||
                  modifyImgUrl ||
                  modifySeriesData.titleMaker.backgroundImage
                }
                style={{
                  width: "363px",
                  height: "484px",
                  position: "relative",
                }}
              />

              {hoverIndex && (
                <S.ImgHoverAddBox>
                  <label htmlFor="fileUpload" ref={dragRef}>
                    <S.ImgPreview
                      alt="preview"
                      src={Tooni}
                      style={{
                        width: "227px",
                        height: "254px",
                      }}
                    />
                  </label>
                </S.ImgHoverAddBox>
              )}
            </S.SeriesImgUploadBox>
          )}
        </div>
        <S.SeriesInputBox>
          <S.InputList>
            <S.RegisterText>
              작품명<span>*</span>
            </S.RegisterText>
            <S.TextArea
              isSeriesId={seriesid}
              id="content"
              name="content"
              isHeight={50}
              placeholder="작품명을 입력해 주세요."
              rows={3}
              onChange={(event) =>
                attr.handleChangeFontSize(event.target.value)
              }
              maxLength={MAX_LENGTH}
              onKeyDown={(e) => handleKeyDown(e.target as HTMLTextAreaElement)}
              value={
                seriesid ? modifySeriesData.title : attr.titleMakerData.title
              }
              disabled={seriesid ? true : false}
            />
          </S.InputList>
          <S.InputList>
            <S.RegisterText>
              작품 한 줄 소개<span>*</span>
            </S.RegisterText>
            <S.TextArea
              isSeriesId={seriesid}
              style={{ color: " black" }}
              isHeight={200}
              placeholder="작품을 한 줄로 소개해 주세요."
              onChange={
                seriesid ? onChangeModifyContent : attr.handleSeriesInfoChange
              }
              id="description"
              name="description"
              value={
                seriesid
                  ? modifySeriesData.description
                  : attr.titleMakerData.description
              }
            />
          </S.InputList>
        </S.SeriesInputBox>
      </S.SeriesListBox>
      <S.SeriesRegisterButton>
        <RegisterButton
          width={21}
          height={44}
          radius={8}
          fontsize={16}
          positions={true}
          buttonType="agree"
          click={() =>
            seriesid ? onBackGroundImgEdit() : attr.onSeriesThumbnailUpload()
          }
        >
          다음
        </RegisterButton>
      </S.SeriesRegisterButton>
    </S.SeriesContainer>
  );
};

export default SeriesInfo;
