import {
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import ShortRepositoryImpl from "repositories/ComicUpload/Shorts/short.repositoryImpl";
import { ShortListType, ShortMyListType } from "types/Short/short.Type";
import { ShortId } from "repositories/ComicUpload/Shorts/short.repository";
import { QUERY_KEYS } from "queries/queryKey";
import { AxiosError } from "axios";
import {
  ShortUploadParam,
  ShortModifyParam,
} from "repositories/ComicUpload/Shorts/short.repository";

export const useGetShortMyList = (
  page: number,
  options?: UseQueryOptions<
    ShortMyListType,
    AxiosError,
    ShortMyListType,
    (string | number)[]
  >
) =>
  useQuery(
    QUERY_KEYS.short.shortMyList(page),
    () => ShortRepositoryImpl.getShortMyList(page),
    {
      enabled: !!page,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      ...options,
    }
  );

export const useGetShortList = (
  { shortsId }: ShortId,
  options?: UseQueryOptions<
    ShortListType,
    AxiosError,
    ShortListType,
    (string | number)[]
  >
): UseQueryResult<ShortListType> =>
  useQuery(
    QUERY_KEYS.short.shortList(shortsId),
    () => ShortRepositoryImpl.getShortList({ shortsId }),
    { staleTime: 1000 * 60 * 60, cacheTime: 1000 * 60 * 60, ...options }
  );

export const useDeleteShortList = () => {
  const mutation = useMutation(({ shortsId }: ShortId) =>
    ShortRepositoryImpl.deleteShortList({ shortsId })
  );
  return mutation;
};

export const usePostShortCreateMutation = () => {
  const shortcreatemutation = useMutation((data: ShortUploadParam) =>
    ShortRepositoryImpl.postShortCreate(data)
  );
  return shortcreatemutation;
};

export const usePatchShort = () => {
  const mutation = useMutation(
    (params: { PatchShortData: ShortModifyParam; shortsId: number }) =>
      ShortRepositoryImpl.patchShort(params.PatchShortData, params.shortsId)
  );
  return mutation;
};
