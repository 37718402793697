import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const SeriesImgContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`;
export const SeriesImgBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SeriesImgUploadBox = styled.div`
  display: flex;

  width: 364px;
  height: 484px;

  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;

  width: 364px;
  height: 484px;

  border: 1px solid ${Palette.Neutral.neutral2};
  border-radius: 10px;

  img {
    margin-bottom: 24px;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
`;

export const RegisterText = styled.p`
  margin-bottom: 7px;
  margin-left: 5px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${Palette.Neutral.neutral9};

  span {
    color: ${Palette.error};
  }
`;

export const SeriesRegisterButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  margin-top: 79px;

  gap: 10px;
`;

export const SeriesFontBox = styled.div`
  display: grid;

  gap: 10px;
  grid-template-columns: 1fr 1fr;

  margin-bottom: 45px;
`;

export const FontButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 50px;

  border-radius: 10px;
  border: 1px solid ${Palette.Neutral.neutral2};
  background-color: white;
`;

export const ColorBox = styled.div`
  display: flex;
  justify-content: center;

  margin-left: -14px;
  margin-bottom: 45px;

  gap: 10px;
`;

export const ColorButton = styled.button`
  display: flex;

  width: 50px;
  height: 50px;

  background-color: ${Palette.backgound1};

  border-radius: 10px;
  border: 0;

  gap: 12px;
`;

export const SeriesColorBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Gradient = styled.div`
  width: 363px;
  height: 484px;

  position: absolute;
  border-radius: 10px;
  /* width: 364px;
  height: 484px;

  position: absolute;

  border-radius: 10px; */
`;

export const TitleChangeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;

  width: 100%;
  height: 100%;

  padding: 22px;

  /* margin-top: -20px; */

  /* display: flex;
  justify-content: center;

  position: absolute; */

  /* height: 40px; */

  /* width: 300px; */
`;

export const Title = styled.div`
  display: flex;
  /* text-align: center; */
  /* margin-top: -20px; */
  /* align-items: center; */

  word-break: break-all;
  -webkit-box-orient: vertical;

  /* text-overflow: ellipsis; */

  white-space: pre-line;

  /* padding: 14px; */

  /* height: 400px; */
  /* height: 100vh; */

  /* background-color: red; */
`;

export const FontImg = styled.img`
  height: 19px;

  object-fit: fill;
`;

export const ThumbnailImg = styled.img`
  width: 363px;
  height: 484px;

  border-radius: 10px;

  object-fit: cover;
  /* width: 363px;
  height: 484px;xw

  border-radius: 10px;

  margin-top: 24px;

  object-fit: cover; */
`;

export const SeriesImgList = styled.div`
  display: flex;

  width: 751px;

  gap: 25px;
`;
