import * as S from "./style";
import { useGetEpisode } from "queries/ComicUpload/Episode/episode.query";
import MoveButton from "components/common/Button/Move";
import SeriesList from "./SeriesList/SeriesList";
import EpisodeRegisterModal from "components/Episode/ComicEpisodeRegister";
import usePopupModal from "utils/Modal/usePopupModal";
import { ReactComponent as Arrow } from "assets/img/Upload/ikon.svg";
import Palette from "libs/Palette/palette";
import { Params, useNavigate, useParams } from "react-router-dom";
import SeriesRegisterModal from "../ComicSeriesRegister";
import SeriesDeleteModal from "./SeriesDeleteModal";

const SeriesManagement = () => {
  let hexToHsl = require("hex-to-hsl");
  const navigate = useNavigate();

  const { seriesid }: Readonly<Params<"seriesid">> = useParams();
  const { data: getSeriesEpisode } = useGetEpisode({
    id: String(seriesid),
  });

  const { ...popup } = usePopupModal();

  const backgroundHsl = getSeriesEpisode?.titleMaker.color
    ? hexToHsl(getSeriesEpisode.titleMaker.color)
    : [0, 0, 0];

  return (
    <>
      <S.SeriesManagementContainer>
        <div style={{ display: "flex", height: "100%" }}>
          <S.SeriesInfoBox>
            <S.TitleBox>
              <S.Title fontColor={true} onClick={() => navigate("/series")}>
                연재 웹툰 관리
              </S.Title>
              <Arrow fill={Palette.Neutral.neutral9} />
              <S.Title fontColor={false}>{getSeriesEpisode?.title}</S.Title>
            </S.TitleBox>

            <div style={{ display: "flex" }}>
              <div>
                <S.ThumbnailPreviewBox>
                  <S.SerialListBox>
                    <S.BackGroundImg
                      alt="backgound"
                      src={getSeriesEpisode?.titleMaker.backgroundImage}
                    />
                    <S.Gradient
                      style={{
                        background: `linear-gradient(#00000000 150px,${getSeriesEpisode?.titleMaker.color})`,
                      }}
                    />
                    <S.TitleImgBox>
                      <S.TitleImg
                        alt="title"
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          getSeriesEpisode?.titleMaker?.titleSvg || ""
                        )}`}
                      />
                    </S.TitleImgBox>
                  </S.SerialListBox>
                  <S.SeriesInfo>
                    <S.SeriesTitle>{getSeriesEpisode?.title}</S.SeriesTitle>
                    <S.SeriesContents>
                      {getSeriesEpisode?.description}
                    </S.SeriesContents>
                    <S.SeriesTagWrap>
                      {getSeriesEpisode?.genre.map((genre) => (
                        <S.SeriesTag>{genre}</S.SeriesTag>
                      ))}
                    </S.SeriesTagWrap>
                  </S.SeriesInfo>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <MoveButton
                      fontcolor={`${getSeriesEpisode?.titleMaker.color}`}
                      backroundcolor={`hsl(${backgroundHsl[0]}, ${
                        backgroundHsl[1]
                      }%, ${(backgroundHsl[2] = 90)}%)`}
                      click={() => popup.toggleSeriesDeleteModal()}
                      width={155}
                      height={41}
                    >
                      작품 삭제
                    </MoveButton>
                    <MoveButton
                      fontcolor="white"
                      backroundcolor={`hsl(${backgroundHsl[0]}, ${
                        backgroundHsl[1]
                      }%, ${(backgroundHsl[2] = 30)}%)`}
                      click={() => popup.toggleSeriesAddModal()}
                      width={155}
                      height={41}
                    >
                      작품 수정
                    </MoveButton>
                  </div>
                </S.ThumbnailPreviewBox>
                <S.SeriesDate>
                  <div>{getSeriesEpisode?.createDate.slice(0, 10)}</div>
                </S.SeriesDate>
              </div>
              <div>
                <S.SeriesListContainer>
                  <S.SeriesListHeader
                    style={{
                      backgroundColor: `hsl(${backgroundHsl[0]}, ${
                        backgroundHsl[1]
                      }%, ${(backgroundHsl[2] = 90)}%)`,
                    }}
                  >
                    <S.HeaderList>
                      <S.SeriesListHeaderText margin="55px">
                        회차
                      </S.SeriesListHeaderText>

                      <S.SeriesListHeaderText margin="155px">
                        썸네일
                      </S.SeriesListHeaderText>

                      <S.SeriesListHeaderText margin="315px">
                        제목
                      </S.SeriesListHeaderText>
                      <S.SeriesListHeaderText margin="460px">
                        <S.SeriesListInfoHeader>
                          <div>조회수</div>
                          <div>좋아요</div>
                          <div>댓글</div>
                        </S.SeriesListInfoHeader>
                      </S.SeriesListHeaderText>
                      <S.SeriesListHeaderText margin="860px">
                        업로드 날짜
                      </S.SeriesListHeaderText>
                      <S.SeriesListHeaderText margin="1035px">
                        관리
                      </S.SeriesListHeaderText>
                    </S.HeaderList>
                  </S.SeriesListHeader>

                  {getSeriesEpisode &&
                  getSeriesEpisode?.episodeList.length > 0 ? (
                    <SeriesList />
                  ) : (
                    <S.Info>회차를 업로드해 주세요!</S.Info>
                  )}
                </S.SeriesListContainer>
              </div>
            </div>
          </S.SeriesInfoBox>
        </div>
        <SeriesDeleteModal
          seriesDeleteModal={popup.seriesDeleteModal}
          toggleSeriesDeleteModal={popup.toggleSeriesDeleteModal}
        />
        <EpisodeRegisterModal
          episodeAddModal={popup.episodeAddModal}
          toggleEpisodAddModal={popup.toggleEpisodAddModal}
        />
        <SeriesRegisterModal
          seriesAddModal={popup.seriesAddModal}
          toggleSeriesAddModal={popup.toggleSeriesAddModal}
        />
      </S.SeriesManagementContainer>
    </>
  );
};

export default SeriesManagement;
