import { atom } from "recoil";
import { EpisodeUploadParam } from "repositories/ComicUpload/Episode/episode.repository";

export const EpisdoeCreateAtom = atom<EpisodeUploadParam>({
  key: "EpisdoeCreateAtom",
  default: {
    title: "",
    thumbnail: "",
    uploadDate: "",
  },
});

export const EpisodeIdAtom = atom<number>({
  key: "EpisodeIdAtom",
  default: 0,
});

export const EpisodeModifyAtom = atom<EpisodeUploadParam>({
  key: "EpisodeModifyAtom",
  default: {
    title: "",
    thumbnail: "",
    uploadDate: "",
  },
});
