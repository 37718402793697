import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const SeriesContainer = styled.div`
  width: 100%;
`;
export const SeriesListBox = styled.div`
  display: flex;

  width: 752px;

  gap: 25px;
`;

export const SeriesInputBox = styled.div`
  width: 100%;
`;
export const InputList = styled.div`
  margin-bottom: 31px;
`;
export const RegisterText = styled.p`
  margin-bottom: 7px;
  margin-left: 5px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${Palette.Neutral.neutral9};

  span {
    color: ${Palette.error};
  }
`;

export const CompletedCheckBox = styled.input`
  width: 100%;

  height: 50px;

  border-radius: 10px;
  border: 1px solid ${Palette.error};

  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  padding-left: 15px;

  color: ${Palette.Neutral.neutral4};
  font-size: 18px;

  outline: none;
  resize: none;

  margin-bottom: 31px;

  &::placeholder {
    color: ${Palette.Neutral.neutral4};
  }

  &:focus {
    border: 1px solid ${Palette.Primary.primary5};
  }
`;

export const SeriesRegisterButton = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;

  margin-top: 61px;

  gap: 10px;
`;

export const SeriesImgUploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 377px;
  height: 502px;

  border: 1px solid ${Palette.Neutral.neutral2};
  border-radius: 10px;

  img {
    width: 134px;
    height: 150px;

    margin-bottom: 24px;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
  }
`;
export const ImgPreview = styled.img`
  border-radius: 10px;
  margin-top: 24px;
`;

export const StatusBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  gap: 10px;
`;

export const PeriodButton = styled.button`
  background-color: white;

  width: 110px;
  height: 50px;

  border-radius: 10px;
  border: 1px solid ${Palette.Neutral.neutral2};

  margin-bottom: 40px;

  font-size: 18px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  color: ${Palette.Neutral.neutral4};

  cursor: pointer;
  &:hover {
    border: 2px solid ${Palette.Primary.primary3};
    color: #000000;
  }
`;

export const TitlePreview = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;

  margin-bottom: 42px;

  border-radius: 10px;
  border: 1px solid ${Palette.Neutral.neutral2};

  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  padding-left: 20px;

  color: ${Palette.Neutral.neutral4};

  user-select: none;
`;
