import * as S from "./style";

interface ProgressBarProps {
  active: boolean;
  width: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ active, width }) => {
  return (
    <div>
      <S.ProgressBar active={active} width={width}></S.ProgressBar>
    </div>
  );
};

export default ProgressBar;
