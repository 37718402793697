import { atom } from "recoil";
import {
  ShortModifyParam,
  ShortUploadParam,
} from "repositories/ComicUpload/Shorts/short.repository";

export const ShortCreateAtom = atom<ShortUploadParam>({
  key: "ShortCreateAtom",
  default: { title: "", genre: [], thumbnailUrl: "", episodeId: 0 },
});

export const ModifyShortAtom = atom<ShortModifyParam>({
  key: "ModifyShortAtom",
  default: { title: "", genre: [], thumbnail: "" },
});

export const ShortGenreAtom = atom<string[]>({
  key: "ShortGenreAtom",
  default: [],
});
