export const SIGNUP_SECTION_NAME = [
  {
    title: "email",
  },
  {
    title: "emailverify",
  },
  {
    title: "id",
  },
  {
    title: "password",
  },
] as const;
