import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  GenreAtom,
  ImageAverageColorAtom,
  SelectFontAtom,
} from "stores/Series/series.store";
import ColorThief from "color-thief-ts";
import customAxios from "libs/Axios/customAxios";
import { PageStateAtom, SectionAtom } from "stores/Common/common.store";
import { ImgAtom } from "stores/Series/series.store";
import showAlert from "libs/Toast/toast";
import { SeriesCreateAtom } from "stores/Series/series.store";
import { SeriesParam } from "repositories/ComicUpload/Series/series.repository";
import { usePostSeriesCreateMutation } from "queries/ComicUpload/Series/series.query";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "queries/queryKey";

const useSeriesUpload = () => {
  const [titleMakerData, setTitleMakerData] =
    useRecoilState<SeriesParam>(SeriesCreateAtom);

  console.log(titleMakerData);
  const queryClient = useQueryClient();
  const seriesCreate = usePostSeriesCreateMutation();
  const [section, setSection] = useRecoilState(SectionAtom);
  const imgRef = useRef<HTMLLabelElement | null>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState<number>(60);
  const [, setAverageColor] = useRecoilState<string | any>(
    ImageAverageColorAtom
  );
  const [backGroundFile, setBackgoundFile] = useState(new FormData());
  const [imgSrc, setImgSrc] = useRecoilState<string | any>(ImgAtom);
  const [selectfont, setSelectFont] = useRecoilState<string>(SelectFontAtom);
  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const [selectSeriesGenre, setSelectSeriesGenre] =
    useRecoilState<string[]>(GenreAtom);
  const hexToHsl = require("hex-to-hsl");

  const handleSeriesInfoChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setTitleMakerData((prev) => ({ ...prev, [name]: value }));
    },
    [setTitleMakerData]
  );

  const handleGenreSelection = (genre: string) => {
    const selectedGenreCount = selectSeriesGenre.length;

    if (selectedGenreCount < 3) {
      if (!selectSeriesGenre.includes(genre)) {
        setSelectSeriesGenre((prev) => [...prev, genre]);
      }
    } else {
      showAlert("장르는 최대 3개까지 선택 가능합니다", "INFO");
    }
  };

  const handleGenreDeseLection = (genre: string) => {
    setSelectSeriesGenre((prev) =>
      prev.filter((selectGenre) => selectGenre !== genre)
    );
  };

  const onModalClose = (toggleSeriesAddModal: () => void) => {
    setTitleMakerData({
      title: "",
      description: "",
      publishStatus: "ON_GOING",
      cycle: "",
      dayOfWeek: "",
      genre: [],
      titleMaker: {
        color: "#eeeeee",
        font: "",
        backgroundImage: "",
      },
    });

    setBackgoundFile(new FormData());
    backGroundFile.delete("file");
    setImgSrc("");
    toggleSeriesAddModal();
    setPage(0);
    setSection("info");
    setAverageColor("");
    setSelectSeriesGenre([]);
  };

  //자동 fontsize 조절
  const handleChangeFontSize = useCallback(
    (value: string) => {
      const charCount = value.replace(/\n/g, "").length;

      if (charCount >= 16) {
        const textSize = Math.ceil(fontSize / 1.1);
        fontSize > 30 && setFontSize(textSize);
      } else {
        const textSize = Math.ceil(fontSize * 1.1);
        fontSize < 55 && setFontSize(textSize);
      }

      setTitleMakerData((prev) => ({
        ...prev,
        title: value,
      }));
    },
    [fontSize]
  );

  const handleColor = (color: string) => {
    setTitleMakerData((prev) => ({
      ...prev,
      titleMaker: { ...prev.titleMaker, color: color },
    }));
  };

  let hex = require("hsl-to-hex");

  //이미지 컬러 뽑기
  const handleImageColor = () => {
    const image = new Image();
    image.src = imgSrc;

    image.onload = () => {
      const fac = new ColorThief();
      const Color = fac.getPalette(image, 6);

      const hslColors = Color.map((hex) => {
        const hsl = hexToHsl(hex);
        hsl[2] = 15;
        return `(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
      });

      let numbersOnly = hslColors[0].replace(/[()%]/g, "");

      let numbersArray = numbersOnly.split(",");

      let h = parseInt(numbersArray[0]);
      let s = parseInt(numbersArray[1]);
      let l = parseInt(numbersArray[2]);

      handleColor(hex(h, s, l));
      setAverageColor(Color);
    };
  };

  useEffect(() => {
    if (imgSrc) {
      handleImageColor();
    }
  }, [imgSrc]);

  const onThumbailRouter = () => {
    if (selectfont === "") {
      showAlert("폰트를 설정해주세요", "INFO");
      return;
    }

    setSection("setting");
    setPage((prev) => prev + 1);
  };

  const onSeriesThumbnailUpload = async () => {
    const { title, description } = titleMakerData;

    if (title === "" || description === "") {
      showAlert("작품을 입력해주세요", "INFO");
      return;
    }

    const backgroundImgRes = await customAxios.post(
      "comic/image/upload",
      backGroundFile
    );

    setSection("thumbnail");
    setPage((prev) => prev + 1);
    setTitleMakerData((prev) => ({
      ...prev,
      titleMaker: {
        ...prev.titleMaker,
        backgroundImage: backgroundImgRes.data,
      },
    }));
  };

  const onSubmitSeriesUpload = async (toggleRegisterModal: () => void) => {
    const { cycle, dayOfWeek } = titleMakerData;
    const { color, backgroundImage } = titleMakerData.titleMaker;

    if (cycle == "" || dayOfWeek == "" || selectSeriesGenre === null) {
      showAlert("모든 설정을 선택해주세요", "INFO");
      return;
    }

    const SeriesData: SeriesParam = {
      ...titleMakerData,
      genre: selectSeriesGenre,
      titleMaker: {
        font: selectfont,
        color: color,
        backgroundImage: backgroundImage,
      },
    };

    seriesCreate.mutate(SeriesData, {
      onSuccess: () => {
        showAlert("업로드 성공", "SUCCESS");
        queryClient.invalidateQueries(QUERY_KEYS.series.seriesList(1));
        onModalClose(toggleRegisterModal);
      },
      onError: (error) => {
        showAlert("업로드 실패 다시 시도해주세요", "ERROR");
        onModalClose(toggleRegisterModal);
      },
    });
  };

  return {
    imgSrc,
    handleImageColor,
    handleChangeFontSize,
    setTitleMakerData,
    titleMakerData,
    handleColor,
    cardRef,
    onSubmitSeriesUpload,
    imgRef,
    fontSize,
    setSelectFont,
    selectfont,
    setSection,
    section,
    handleSeriesInfoChange,
    backGroundFile,
    onSeriesThumbnailUpload,
    setImgSrc,
    seriesCreate,
    handleGenreSelection,
    handleGenreDeseLection,
    selectSeriesGenre,
    onModalClose,
    onThumbailRouter,
  };
};

export default useSeriesUpload;
