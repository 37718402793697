import { useState } from "react";

type ToggleHookType = [boolean, () => void];

function useBooleanToggle(initialValue = true): ToggleHookType {
  const [state, setState] = useState<boolean>(initialValue);

  const toggleStatus = () => setState((prevStatus) => !prevStatus);

  return [state, toggleStatus];
}

export default useBooleanToggle;
