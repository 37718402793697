import { useQueryClient } from "@tanstack/react-query";
import customAxios from "libs/Axios/customAxios";
import showAlert from "libs/Toast/toast";
import {
  useGetShortList,
  usePatchShort,
} from "queries/ComicUpload/Short/short.query";
import { QUERY_KEYS } from "queries/queryKey";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { ShortModifyParam } from "repositories/ComicUpload/Shorts/short.repository";
import { PageStateAtom, SectionAtom } from "stores/Common/common.store";
import { ModifyShortAtom } from "stores/Shorts/short.store";

interface ShortModifyProps {
  shortId: number;
}

const useShortModify = ({ shortId }: ShortModifyProps) => {
  const { data: getShortList } = useGetShortList({
    shortsId: shortId ? shortId : Number(sessionStorage.getItem("ShortId")),
  });

  const queryClient = useQueryClient();
  const patchShortMutation = usePatchShort();
  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const [, setShortSection] = useRecoilState(SectionAtom);
  const thumbnailModifyImgRef = useRef<HTMLLabelElement | null>(null);
  const [thumbnaiModifylUrl, setThumbnailModifyUrl] = useState<string | any>();
  const [selectModifyShortGenre, setSelectModifShortGenre] = useState<string[]>(
    []
  );
  const [thumbnailModifyForm, setThumbnailModifyForm] = useState(
    new FormData()
  );
  const [modifyShortData, setModifyShortData] = useRecoilState(ModifyShortAtom);
  const [episodeImgList, setEpisodeImgList] = useState<string[]>([]);

  useEffect(() => {
    if (shortId && getShortList) {
      const { title, thumbnail, genre, imageURL } = getShortList;

      setModifyShortData({
        title,
        genre,
        thumbnail,
      });
      setSelectModifShortGenre(genre);
      setEpisodeImgList(imageURL);
    }
  }, [getShortList, shortId]);

  const modifyShortGenreSelection = (genre: string) => {
    const selectedGenreCount = selectModifyShortGenre.length;

    if (selectedGenreCount < 3) {
      if (!selectModifyShortGenre.includes(genre)) {
        setSelectModifShortGenre((prev) => [...prev, genre]);
      }
    } else {
      showAlert("장르는 최대 3개까지 선택 가능합니다", "INFO");
    }
  };

  const modifyShortGenreDesc = (genre: string) => {
    setSelectModifShortGenre((prev) =>
      prev.filter((selectGenre) => selectGenre !== genre)
    );
  };

  const handleShortModifyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      setModifyShortData((prev) => ({ ...prev, [name]: value }));
    },
    [setModifyShortData]
  );

  const onShortModifyInfo = () => {
    const { title, genre } = modifyShortData;
    if (title === "" || genre === null) {
      showAlert("제목 또는 장르가 선택되지 않았습니다.", "INFO");
      return;
    }
    setShortSection("thumbnail");
    setPage((prev) => prev + 1);
  };

  const onShortModifyThumbnailUpload = async () => {
    if (thumbnaiModifylUrl !== undefined) {
      const thumbnailResponse = await customAxios.post(
        "comic/image/upload",
        thumbnailModifyForm
      );

      setModifyShortData((prev) => ({
        ...prev,
        thumbnail: thumbnailResponse.data,
      }));

      setShortSection("upload");
    } else {
      setShortSection("upload");
    }
  };

  const onSubmitModifyShort = (toggleShortModal: () => void) => {
    const shortModifyDataList: ShortModifyParam = {
      ...modifyShortData,
      genre: selectModifyShortGenre,
    };

    patchShortMutation.mutate(
      {
        PatchShortData: shortModifyDataList,
        shortsId: shortId,
      },
      {
        onSuccess: () => {
          showAlert("단편웹툰 수정 성공", "SUCCESS");
          queryClient.invalidateQueries(QUERY_KEYS.short.shortList(shortId));
          queryClient.invalidateQueries(QUERY_KEYS.short.shortMyList(1));
          toggleShortModal();
          setPage(0);
          setShortSection("info");
        },
        onError: () => {
          showAlert("단편웹툰 수정 실패", "ERROR");
        },
      }
    );
  };

  return {
    modifyShortData,
    handleShortModifyChange,
    onShortModifyInfo,
    thumbnailModifyForm,
    setThumbnailModifyForm,
    thumbnailModifyImgRef,
    setThumbnailModifyUrl,
    thumbnaiModifylUrl,
    onShortModifyThumbnailUpload,
    modifyShortGenreSelection,
    modifyShortGenreDesc,
    selectModifyShortGenre,
    onSubmitModifyShort,
    episodeImgList,
  };
};

export default useShortModify;
