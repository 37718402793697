import { CommentType } from "types/Comment/comment.type";
import { CommentDataParam, CommentRepository } from "./comment.repository";
import customAxios from "libs/Axios/customAxios";

class CommentRepositoryImpl implements CommentRepository {
  public async postComment(
    episodeId: number,
    commentData: CommentDataParam
  ): Promise<void> {
    const { data } = await customAxios.post(
      `/comment/upload/${episodeId}`,
      commentData
    );
    return data;
  }
  public async getComment(episodeId: number): Promise<CommentType> {
    const { data } = await customAxios.get(`/comment/${episodeId}`);
    return data;
  }
}

export default new CommentRepositoryImpl();
