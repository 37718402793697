import styled from "styled-components";

import Palette from "libs/Palette/palette";

export const ManagementContainer = styled.div`
  width: 100%;
  height: 100vh;

  background-color: #f7f7f7;
`;

export const ManagementBox = styled.div`
  margin-top: 70px;

  margin-left: 265px;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;

  height: 76px;
  margin-left: 16px;

  gap: 13px;
`;

export const Title = styled.p<{ fontColor: boolean }>`
  font-size: 22px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${(props) =>
    props.fontColor
      ? `${Palette.Neutral.neutral4}`
      : `${Palette.Neutral.neutral9}`};
`;

export const ShortThumbnail = styled.img`
  width: 320px;
  height: 200px;

  margin-top: 20px;
  margin-bottom: 16px;

  border-radius: 10px;
`;

export const ShortInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 360px;
  height: 370px;

  border-radius: 10px;

  background-color: #ffffff;
  box-shadow: 1px 1px 10px 0px rgba(196, 196, 196, 0.2);

  margin-bottom: 20px;
`;

export const ShortTitlebox = styled.div`
  width: 300px;
  height: 50px;

  margin-bottom: 16px;
`;

export const ShortTitle = styled.p`
  margin-bottom: 10px;

  font-size: 18px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;
`;

export const ShortContainer = styled.div`
  display: flex;

  gap: 20px;
`;

export const ShortPreviewBox = styled.div`
  border-radius: 10px;

  width: 800px;

  height: 903px;

  overflow: auto;

  background-color: white;
`;

export const ShortPreviewImg = styled.img`
  object-fit: fill;
`;

export const PerformanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 87px;

  width: 360px;
  height: 88px;

  border-radius: 10px;

  background-color: #ffffff;
  box-shadow: 1px 1px 10px 0px rgba(196, 196, 196, 0.2);

  margin-bottom: 15px;
`;

export const PerformanceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
`;

export const Icon = styled.img`
  margin-bottom: 2px;
`;

export const ShortBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Date = styled.p`
  font-size: 16px;
  color: #7c8188;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  user-select: none;
`;

export const GenreBox = styled.div`
  display: flex;

  gap: 5px;
`;
export const ShortGenre = styled.div`
  font-size: 14px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
  color: ${Palette.Neutral.neutral7};

  padding: 5px 10px 5px 10px;

  border-radius: 6px;

  background-color: ${Palette.Neutral.neutral1};
`;
