import React, { MutableRefObject, useCallback, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { ShortUploadParam } from "repositories/ComicUpload/Shorts/short.repository";
import customAxios from "libs/Axios/customAxios";
import { PageStateAtom, SectionAtom } from "stores/Common/common.store";
import showAlert from "libs/Toast/toast";
import { ShortCreateAtom, ShortGenreAtom } from "stores/Shorts/short.store";
import { usePostShortCreateMutation } from "queries/ComicUpload/Short/short.query";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "queries/queryKey";

const useShortUpload = () => {
  const shortCreate = usePostShortCreateMutation();
  const queryClient = useQueryClient();
  const [shortSection, setShortSection] = useRecoilState(SectionAtom);
  const [imgUrl, setImgUrl] = useState<string[]>([]);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | any>();
  const [fileform, setFileForm] = useState(new FormData());
  const [thumbnailform, setThumbnailForm] = useState(new FormData());
  const [filename, setFilename] = useState<string[]>([]);
  const thumbnailImgRef = useRef<HTMLLabelElement | null>(null);
  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const [shortUpload, setShortUpload] = useRecoilState(ShortCreateAtom);
  const [selectShortGenre, setSelectShortGenre] =
    useRecoilState(ShortGenreAtom);
  //원고 파일 style 변경 위한
  const scriptImgRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const onClickImg = () => {
    if (scriptImgRef.current) {
      scriptImgRef.current.click();
    }
  };

  const handleShortGereSelection = (genre: string) => {
    const selectedGenreCount = selectShortGenre.length;

    if (selectedGenreCount < 3) {
      if (!selectShortGenre.includes(genre)) {
        setSelectShortGenre((prev) => [...prev, genre]);
      }
    } else {
      showAlert("장르는 최대 3개까지 선택 가능합니다", "INFO");
    }
  };

  const handleShortGenreDesc = (genre: string) => {
    setSelectShortGenre((prev) =>
      prev.filter((selectGenre) => selectGenre !== genre)
    );
  };

  const onShortModalClose = (toggleShortAddModal: () => void) => {
    setShortUpload({
      title: "",
      genre: [],
      thumbnailUrl: "",
      episodeId: 0,
    });

    toggleShortAddModal();
    setPage(0);
    setShortSection("info");
    setFileForm(new FormData());
    setThumbnailForm(new FormData());
    setImgUrl([]);
    setThumbnailUrl([]);
    setSelectShortGenre([]);
  };

  //원고 업로드
  const onShortEpisodeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files!!;
    let imageUrlList = [...imgUrl];

    Array.from(fileList).forEach((file) => {
      fileform.append("fileList", file);
      filename.push(file.name);
      const ImageUrls = URL.createObjectURL(file);
      imageUrlList.push(ImageUrls);
    });

    setImgUrl(imageUrlList);
  };

  const onClickthumbnailImg = () => {
    if (thumbnailImgRef.current) {
      thumbnailImgRef.current.click();
    }
  };

  //원고 삭제
  const handleShortDeleteImage = (id: number) => {
    const newImageUrlList = imgUrl.filter((_, index) => index !== id);
    const newFileNameList = filename.filter((_, index) => index !== id);
    setImgUrl(newImageUrlList);
    setFilename(newFileNameList);
  };

  const handleShortInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      setShortUpload((prev) => ({ ...prev, [name]: value }));
    },
    [setShortUpload]
  );

  const onShortInfoUpload = () => {
    const { title } = shortUpload;
    if (title === "") {
      showAlert("제목을 입력해주세요.", "INFO");
      return;
    }
    setShortSection("thumbnail");
    setPage((prev) => prev + 1);
  };

  const onShortThumbnailUpload = async () => {
    const thumbnailResponse = await customAxios.post(
      "comic/image/upload",
      thumbnailform
    );

    setShortUpload((prev) => ({
      ...prev,
      thumbnailUrl: thumbnailResponse.data,
    }));

    setPage((prev) => prev + 1);
    setShortSection("upload");
  };

  const onSubmitShortUpload = async (toggleShortModal: () => void) => {
    const episodeResponse = await customAxios.post(
      "comic/episode/upload",
      fileform
    );

    const shortData: ShortUploadParam = {
      ...shortUpload,
      genre: selectShortGenre,
      episodeId: episodeResponse.data,
    };

    shortCreate.mutate(shortData, {
      onSuccess: () => {
        showAlert("업로드 성공", "SUCCESS");
        queryClient.invalidateQueries(QUERY_KEYS.short.shortMyList(1));
        onShortModalClose(toggleShortModal);
      },
      onError: () => {
        showAlert("업로드 실패", "ERROR");
        onShortModalClose(toggleShortModal);
      },
    });
  };

  return {
    imgUrl,
    thumbnailform,
    setThumbnailUrl,
    onClickImg,
    scriptImgRef,
    thumbnailImgRef,
    onShortEpisodeUpload,
    handleShortDeleteImage,
    onClickthumbnailImg,
    handleShortInputChange,
    onSubmitShortUpload,
    setShortUpload,
    shortUpload,
    filename,
    thumbnailUrl,
    setShortSection,
    shortSection,
    onShortInfoUpload,
    onShortThumbnailUpload,
    setFilename,
    setImgUrl,
    shortCreate,
    handleShortGereSelection,
    handleShortGenreDesc,
    selectShortGenre,
    onShortModalClose,
  };
};

export default useShortUpload;
