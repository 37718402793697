import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import seriesRepositoryImpl from "repositories/ComicUpload/Series/series.repositoryImpl";
import { SeriesParam } from "repositories/ComicUpload/Series/series.repository";
import { SeriesId, SeriesListType } from "types/Series/series.type";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "queries/queryKey";

export const usePostSeriesCreateMutation = () => {
  const seriescreatemutation = useMutation((data: SeriesParam) =>
    seriesRepositoryImpl.postSeriesCreate(data)
  );
  return seriescreatemutation;
};

export const useGetSeriesList = (
  page: number,
  options?: UseQueryOptions<
    SeriesListType,
    AxiosError,
    SeriesListType,
    (string | number)[]
  >
) =>
  useQuery(
    QUERY_KEYS.series.seriesList(page),
    () => seriesRepositoryImpl.getSeriesMyList(page),
    {
      enabled: !!page,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      ...options,
    }
  );

export const useDeleteSeriesList = () => {
  const mutation = useMutation(({ seriesId }: SeriesId) =>
    seriesRepositoryImpl.deleteSeriesList({ seriesId })
  );
  return mutation;
};

export const usePatchSeries = () => {
  const mutation = useMutation(
    (params: { PatchData: SeriesParam; seriesId: number }) =>
      seriesRepositoryImpl.patchSeries(params.PatchData, params.seriesId)
  );
  return mutation;
};

export const useGetTitleMaker = (
  title: string,
  font: string,
  alignLeft: boolean
) =>
  useQuery(
    QUERY_KEYS.series.titlemaker(title, font, alignLeft),
    () => seriesRepositoryImpl.getTitleMaker(title, font, alignLeft),
    {}
  );
