//연재요일
export const SeriesDate = [
  {
    day: "월요일",
    value: "MONDAY",
  },
  {
    day: "화요일",
    value: "TUESDAY",
  },
  {
    day: "수요일",
    value: "WEDNESDAY",
  },
  {
    day: "목요일",
    value: "THURSDAY",
  },
  {
    day: "금요일",
    value: "FRIDAY",
  },

  {
    day: "토요일",
    value: "SATURDAY",
  },
  {
    day: "일요일",
    value: "SUNDAY",
  },
];

//연재방식
export const SeriesPeriod = [
  {
    period: "주간연재",
    value: "WEEKLY",
  },
  {
    period: "격주연재",
    value: "BIWEEKLY",
  },
  {
    period: "월간연재",
    value: "MONTH",
  },
];

export const SeriesStatus = [
  {
    status: "연재",
    value: "ON_GOING",
  },
  {
    status: "휴재",
    value: "ON_HIATUS",
  },
  {
    status: "완결",
    value: "COMPLETED",
  },
];
