import RegisterButton from "components/common/Button/Register/index";
import * as S from "./style";
import TextField from "components/common/TextField";
import { SignupType } from "types/Auth/signup.type";
import { Dispatch, SetStateAction } from "react";

interface EmailVerifyProps extends SignupType {
  formattedTime: string;
  onAuthNumber: () => void;
  onEmailOverlap: () => void;
  setSection: Dispatch<SetStateAction<string>>;
}

const EmailVerify = ({
  handleSignupChange,
  signupData,
  formattedTime,
  onAuthNumber,
  onEmailOverlap,
  setSection,
}: EmailVerifyProps) => {
  return (
    <>
      <S.EmailInputBox>
        <TextField
          onchange={handleSignupChange}
          id="email"
          name="email"
          functions="code"
          value={signupData.email}
        >
          이메일 주소
        </TextField>
      </S.EmailInputBox>
      <S.EmailInputBox>
        <TextField
          onchange={handleSignupChange}
          id="code"
          name="code"
          functions={onAuthNumber}
        >
          인증번호
        </TextField>
      </S.EmailInputBox>

      <S.TimerBox>
        <S.Time>{formattedTime}</S.Time>
      </S.TimerBox>

      <S.ReCertificationBox>
        <S.ReCertification onClick={() => onEmailOverlap()}>
          인증 번호 재전송
        </S.ReCertification>
      </S.ReCertificationBox>

      <S.EmailButtonBox>
        <RegisterButton
          width={30}
          height={53}
          radius={8}
          fontsize={18}
          click={() => {
            setSection("email");
          }}
          positions={true}
          buttonType="cencel"
        >
          이전
        </RegisterButton>
        <RegisterButton
          width={100}
          height={53}
          radius={8}
          fontsize={18}
          click={() => onAuthNumber()}
          positions={true}
          buttonType="agree"
          disabled={
            signupData.email.length === 0 || signupData.code.length === 0
          }
        >
          다음 단계로 넘어가기
        </RegisterButton>
      </S.EmailButtonBox>
    </>
  );
};

export default EmailVerify;
