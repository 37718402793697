import { useGetEpisode } from "queries/ComicUpload/Episode/episode.query";
import * as S from "./style";
import { Params, useNavigate, useParams } from "react-router-dom";
import EpisodeRegisterModal from "components/Episode/ComicEpisodeRegister";
import usePopupModal from "utils/Modal/usePopupModal";

const SeriesList = () => {
  const { seriesid }: Readonly<Params<"seriesid">> = useParams();

  const { data: getEpisodeList } = useGetEpisode({
    id: String(seriesid),
  });
  let hexToHsl = require("hex-to-hsl");
  const navigate = useNavigate();

  const backgroundHsl = getEpisodeList?.titleMaker.color
    ? hexToHsl(getEpisodeList.titleMaker.color)
    : [0, 0, 0];

  const { ...popup } = usePopupModal();

  return (
    <>
      {getEpisodeList?.episodeList.map((episode, index) => {
        let date = episode.createdDate.toString().substring(0, 10);

        return (
          <S.SeriesContainer>
            <S.SeriesBox>
              <S.SeiresContent margin="31px">
                <S.EpisodeNumber
                  style={{
                    color: `hsl(${backgroundHsl[0]}, ${
                      backgroundHsl[1]
                    }%, ${(backgroundHsl[2] = 30)}%)`,
                  }}
                >
                  {episode.episodeNumber + 1}
                </S.EpisodeNumber>
              </S.SeiresContent>
              <S.SeiresContent
                margin="85px"
                onClick={() => {
                  sessionStorage.setItem(
                    "EpisodeNumber",
                    `${episode.episodeNumber}`
                  );

                  navigate(
                    `/series/manage/${seriesid}/${episode.episodeNumber}`
                  );
                }}
              >
                <S.Thumbnail alt="thumbnail" src={episode.thumbnail} />
              </S.SeiresContent>

              <div
                style={{
                  width: "235px",
                  display: "flex",
                  marginLeft: "180px",
                  justifyContent: "center",
                }}
              >
                <div>{episode.title}</div>
              </div>

              <S.SeiresContent margin="428px">
                <S.SeriesListInfo>
                  <p>145회</p>
                  <p>{episode.likeCount}개</p>
                  <p>{episode.commentCount}개</p>
                </S.SeriesListInfo>
              </S.SeiresContent>
              <S.SeiresContent margin="822px">
                <div>{date}</div>
              </S.SeiresContent>

              <S.SeiresContent margin="995px">
                <S.SeriesEditButton
                  color="white"
                  backgroundColor={`hsl(${backgroundHsl[0]}, ${
                    backgroundHsl[1]
                  }%, ${(backgroundHsl[2] = 30)}%)`}
                  onClick={() => popup.toggleEpisodAddModal()}
                >
                  수정
                </S.SeriesEditButton>
              </S.SeiresContent>
            </S.SeriesBox>
          </S.SeriesContainer>
        );
      })}
      <EpisodeRegisterModal
        episodeAddModal={popup.episodeAddModal}
        toggleEpisodAddModal={popup.toggleEpisodAddModal}
      />
    </>
  );
};

export default SeriesList;
