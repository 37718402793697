import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const SeriesContainer = styled.div`
  width: 100%;
`;
export const SeriesListBox = styled.div`
  display: flex;

  width: 751px;

  gap: 25px;
`;

export const SeriesInputBox = styled.div`
  width: 100%;
`;
export const InputList = styled.div`
  margin-bottom: 31px;
`;
export const RegisterText = styled.p`
  margin-bottom: 7px;
  margin-left: 5px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${Palette.Neutral.neutral9};

  span {
    color: ${Palette.error};
  }
`;

export const SeriesRegisterButton = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;

  margin-top: 79px;
`;

export const SeriesImgUploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 363px;
  height: 484px;

  border: 1px solid ${Palette.Neutral.neutral2};
  border-radius: 10px;

  img {
    width: 134px;
    height: 150px;

    margin-bottom: 24px;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
  }
`;
export const ImgPreview = styled.img`
  border-radius: 10px;
  margin-top: 24px;

  object-fit: cover;

  cursor: pointer;
`;

export const ImgHoverAddBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  background-color: rgba(255, 255, 255, 0.65);

  width: 377px;
  height: 502px;

  border-radius: 10px;
`;

export const TextArea = styled.textarea<{
  isHeight: number;
  isSeriesId: any;
}>`
  width: 100%;

  height: ${(props) => props.isHeight}px;

  border-radius: 10px;
  border: 1px solid #ccced1;

  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  padding-left: 15px;
  padding-top: 14px;

  color: ${(props) =>
    props.isSeriesId ? `#979BA0` : `${Palette.Neutral.neutral9}`};

  outline: none;
  resize: none;

  &::placeholder {
    color: ${Palette.Neutral.neutral4};
  }

  &:focus {
    border: 1px solid ${Palette.Primary.primary5};
  }
`;
