import TextField from "components/common/TextField";
import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import {
  PasswordCheckParam,
  PasswordParm,
} from "repositories/Auth/Signup/signup.repository";
import { SignupType } from "types/Auth/signup.type";
import { Dispatch, SetStateAction } from "react";
import eye1 from "assets/img/Auth/eye1.svg";
import eye2 from "assets/img/Auth/eye2.svg";

interface PasswordProps extends SignupType {
  handlePasswordCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSignup: () => void;
  passwordType: PasswordParm;
  handlePasswordType: () => void;
  passwordCheck: PasswordCheckParam;
  setSection: Dispatch<SetStateAction<string>>;
}

const Password = ({
  handleSignupChange,
  handlePasswordCheck,
  onSignup,
  passwordType,
  handlePasswordType,
  signupData,
  passwordCheck,
  setSection,
}: PasswordProps) => {
  return (
    <>
      <S.EmailInputBox>
        <TextField
          onchange={handleSignupChange}
          id="password"
          name="password"
          functions="passwords"
          message="8자 이상의 영문(필수)과 숫자(필수)"
          type="password"
        >
          비밀번호
        </TextField>
      </S.EmailInputBox>
      <S.EmailInputBox>
        <TextField
          onchange={handlePasswordCheck}
          id="passwords"
          name="passwords"
          functions={onSignup}
          type={passwordType.type}
        >
          비밀번호 확인
        </TextField>
      </S.EmailInputBox>

      <S.TimerBox>
        <S.Time onClick={() => handlePasswordType()}>
          {passwordType.visible ? (
            <img alt="eye2" src={eye2} />
          ) : (
            <img alt="eye1" src={eye1} />
          )}
        </S.Time>
      </S.TimerBox>

      <S.EmailButtonBox>
        <RegisterButton
          width={30}
          height={53}
          radius={8}
          fontsize={18}
          click={() => {
            setSection("id");
          }}
          positions={true}
          buttonType="cencel"
        >
          이전
        </RegisterButton>
        <RegisterButton
          width={100}
          height={53}
          radius={8}
          fontsize={18}
          click={() => onSignup()}
          positions={true}
          buttonType="agree"
          disabled={
            signupData.password.length === 0 ||
            passwordCheck.passwords.length === 0
          }
        >
          툰게더 가입하기
        </RegisterButton>
      </S.EmailButtonBox>
    </>
  );
};

export default Password;
