import { REGISTER_SECTION_NAME } from "constants/Series/register.constants";
import { ReactNode, useEffect, useState } from "react";
import SeriesInfo from "./SeriesInfo";
import Thumbnail from "./Thumbnail";
import useSeriesUpload from "hooks/Series/useSeriesUpload";
import useTitleMaker from "hooks/Series/useTitleMaker";
import SeriesSetting from "./SeriesSetting";
import useGenre from "hooks/common/Genre/useGenre";
import SeriesModify from "./SeriesModify";
import { Params, useParams } from "react-router-dom";
import useSeriesModify from "hooks/Series/useSeriesModify";

interface SeriesRegisterProps {
  toggleRegisterModal: () => void;
}

const SeriesRegister = ({ toggleRegisterModal }: SeriesRegisterProps) => {
  const {
    setSection,
    section,
    titleMakerData,
    fontSize,
    setTitleMakerData,
    onSubmitSeriesUpload,
    seriesCreate,
    setSelectFont,
    selectSeriesGenre,
    onThumbailRouter,
  } = useSeriesUpload();

  const { seriesid }: Readonly<Params<"seriesid">> = useParams();

  const { ...modify } = useSeriesModify({ seriesId: Number(seriesid) });

  const { Genre, setGenreChange, onKeyDown, genreChange, handleAddGenre } =
    useGenre();
  const { handleKeyDown } = useTitleMaker();
  const [, setSeriesPrevSection] = useState(section);

  const SeriesComponents: ReactNode[] = [
    <SeriesInfo
      setSection={setSection}
      handleKeyDown={handleKeyDown}
      backGroundModifyFil={modify.backGroundModifyFil}
      setModifyImgSrc={modify.setModifyImgSrc}
      imgModifyRef={modify.imgModifyRef}
      modifyImgUrl={modify.modifyImgUrl}
      modifySeriesData={modify.modifySeriesData}
      onBackGroundImgEdit={modify.onBackGroundImgEdit}
      onChangeModifyContent={modify.onChangeModifyContent}
    />,
    <Thumbnail
      onThumbailRouter={onThumbailRouter}
      modifySeriesData={modify.modifySeriesData}
      seriesid={seriesid}
      setModifySeriesData={modify.setModifySeriesData}
      setSection={setSection}
      titleMakerData={titleMakerData}
      fontSize={fontSize}
      setSelectFont={setSelectFont}
    />,
    <SeriesSetting
      handleAddGenre={handleAddGenre}
      selectModifySeriesGenre={modify.selectModifySeriesGenre}
      seriesid={seriesid}
      setModifySeriesData={modify.setModifySeriesData}
      modifySeriesData={modify.modifySeriesData}
      modifySeriesGenreDesc={modify.modifySeriesGenreDesc}
      modifySeriesGenreSelection={modify.modifySeriesGenreSelection}
      onModifyPageRouter={modify.onModifyPageRouter}
      selectSeriesGenre={selectSeriesGenre}
      seriesCreate={seriesCreate}
      onTitleMakerUpload={() => onSubmitSeriesUpload(toggleRegisterModal)}
      setSection={setSection}
      setGenreChange={setGenreChange}
      Genre={Genre}
      onKeyDown={onKeyDown}
      genreChange={genreChange}
      setTitleMakerData={setTitleMakerData}
      titleMakerData={titleMakerData}
      toggleRegisterModal={toggleRegisterModal}
    />,
    <SeriesModify
      setSection={setSection}
      modifyImgUrl={modify.modifyImgUrl}
      modifySeriesData={modify.modifySeriesData}
      setModifySeriesData={modify.setModifySeriesData}
      handlePublishStatusCheck={modify.handlePublishStatusCheck}
      onSubmitModifySeries={() =>
        modify.onSubmitModifySeries(toggleRegisterModal)
      }
    />,
  ];

  useEffect(() => {
    setSeriesPrevSection(section);
  }, [section]);
  return (
    <>
      {SeriesComponents.map((component, idx) => {
        return section === REGISTER_SECTION_NAME[idx].title && component;
      })}
    </>
  );
};

export default SeriesRegister;
