import { styled } from "styled-components";
import Palette from "libs/Palette/palette";

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .today {
    background-color: ${Palette.Primary.primary1};

    color: ${Palette.Primary.primary5};
  }

  .saturday {
    color: ${Palette.Primary.primary3};
  }
  .sunday {
    color: #ff8484;
  }
`;

export const DateTitle = styled.span`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  width: 147px;
`;

export const CalendarTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 38px;
`;

export const Date = styled.button`
  gap: 30px;

  margin-right: 15.5px;
  margin-bottom: 10px;

  color: ${Palette.Neutral.neutral5};

  background-color: white;
  border: none;

  border-radius: 40px;

  font-size: 18px;

  text-align: center;

  cursor: pointer;

  &:hover {
    color: ${Palette.Primary.primary5};
  }
`;

export const CalendarBox = styled.div`
  display: flex;
  flex-direction: column;

  height: 272px;
`;

export const DayTitle = styled.div`
  display: flex;
  justify-content: center;

  font-size: 18px;

  height: 30px;

  gap: 40px;
`;

export const DayBox = styled.div`
  margin-left: 15px;
  margin-bottom: 20px;
`;

export const DayList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 40px;
`;
