import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const EmailInputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 180px;
`;

export const SignupStateBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 20px 40px 50px 40px;
`;

export const SignupState = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  input {
    width: 20px;
    height: 20px;

    margin-right: 10px;

    border: 2px solid ${Palette.Neutral.neutral5};
    border-radius: 3px;
  }

  p {
    font-size: 16px;
    color: ${Palette.Neutral.neutral5};
  }
`;

export const LoginButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 111px 40px 0px 40px;

  gap: 10px;
`;

export const LoginInfoBox = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  color: ${Palette.Neutral.neutral5};

  width: 100%;
  padding: 0px 40px 0px 40px;
`;

export const LoginFindBox = styled.div`
  display: flex;
  align-items: center;

  img {
    display: flex;
    align-items: center;

    margin-top: -2px;
  }
`;
