import customAxios from "libs/Axios/customAxios";
import { ShortListType, ShortMyListType } from "types/Short/short.Type";
import {
  ShortId,
  ShortModifyParam,
  ShortRepository,
  ShortUploadParam,
} from "./short.repository";

class ShortRepositoryImpl implements ShortRepository {
  public async getShortMyList(page: number): Promise<ShortMyListType> {
    const { data } = await customAxios.get(
      `/comic/shorts/my?page=${page}&limit=${20}`
    );
    return data;
  }

  public async deleteShortList({ shortsId }: ShortId): Promise<void> {
    const { data } = await customAxios.delete(
      `comic/shorts/delete/${shortsId}`
    );
    return data;
  }
  public async postShortCreate(shortData: ShortUploadParam): Promise<void> {
    const { data } = await customAxios.post("comic/shorts/create", shortData);
    return data;
  }

  public async getShortList({ shortsId }: ShortId): Promise<ShortListType> {
    const { data } = await customAxios.get(`/comic/shorts/${shortsId}`);
    return data;
  }
  public async patchShort(
    shortModifyData: ShortModifyParam,
    shortsId: number
  ): Promise<void> {
    await customAxios.put(`/comic/shorts/update/${shortsId}`, shortModifyData);
  }
}

export default new ShortRepositoryImpl();
