import styled from "styled-components";

export const ShortContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;

  margin-left: 240px;

  background-color: #f7f7f7;
`;

export const ShortHeader = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 76px;

  margin-top: 70px;

  background-color: #f7f7f7;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  font-size: 22px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  height: 76px;

  margin-left: 41px;

  user-select: none;
`;
