import * as S from "./style";
import ErrorBoundary from "components/common/ErrorBoundary";
import React, { Suspense } from "react";
import SkeletonComponent from "components/common/Skeleton";

const ComicSeries = () => {
  const SeriesItem = React.lazy(() => import("./SeriesItem"));
  return (
    <S.SeriesContainer>
      <S.SeriesHeader>
        <S.Title>연재 웹툰 관리</S.Title>
      </S.SeriesHeader>
      <ErrorBoundary fallback={<>에러</>}>
        <Suspense fallback={<SkeletonComponent height={588} top={true} />}>
          <SeriesItem />
        </Suspense>
      </ErrorBoundary>
    </S.SeriesContainer>
  );
};

export default ComicSeries;
