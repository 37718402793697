import styled, { css } from "styled-components";
import Palette from "libs/Palette/palette";

export const SidebarContainer = styled.div`
  display: flex;
  position: fixed;
`;

export const SidebarBox = styled.div`
  display: flex;
  flex-direction: column;

  width: 240px;
  height: 100vh;

  background-color: white;

  box-shadow: 1px 1px 10px 0px rgba(196, 196, 196, 0.2);
`;

export const Name = styled.div`
  text-align: center;
  font-size: 14px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  margin-bottom: 40px;

  user-select: none;
`;

export const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SidebarListBox = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 105px;

  img {
    width: 100px;
    height: 100px;

    margin-bottom: 15px;
  }
`;

export const SidebarList = styled.div<{
  isBefore: boolean;
  isSelect?: boolean;
}>`
  display: flex;
  align-items: center;

  width: 218px;
  height: 50px;

  margin-bottom: 10px;
  margin-left: 10px;
  padding-left: 24px;

  font-size: 14px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  color: ${(props) =>
    props.isSelect
      ? `${Palette.Neutral.neutral6}`
      : `${Palette.Neutral.neutral3}`};

  cursor: pointer;

  .icon {
    width: 15.98px;
    margin-right: 10px;
  }

  span {
    font-size: 10px;
    margin-top: 4px;
    padding-left: 5px;
  }

  ${({ isSelect, isBefore }) =>
    !isSelect &&
    isBefore &&
    css`
      &:hover {
        background-color: #ebefff;
        transform: scale(0.98);
        border-radius: 10px;
        color: ${Palette.Primary.primary5};
        .icon {
          fill: ${Palette.Primary.primary5};
        }
      }
      &:active {
        background-color: #dddddd;
      }
    `};

  ${({ isSelect }) =>
    isSelect &&
    css`
      &:hover {
        background-color: #ebefff;
        transform: scale(0.98);
        border-radius: 10px;
        color: ${Palette.Primary.primary5};
        .icon {
          fill: ${Palette.Primary.primary5};
        }
      }
      &:active {
        background-color: #dddddd;
      }
    `};
`;
