import { ChangeEvent, useCallback, useState } from "react";
import {
  PasswordCheckParam,
  PasswordParm,
  SignupParam,
} from "repositories/Auth/Signup/signup.repository";
import { useEmailMutation } from "queries/Signup/signup.query";
import customAxios from "libs/Axios/customAxios";
import { useRecoilState } from "recoil";
import { ErrorStateAtom } from "stores/Common/common.store";
import useSignup from "./useSignup";
import showAlert from "libs/Toast/toast";
import signupRepositoryImpl from "repositories/Auth/Signup/signup.repositoryImpl";
import patternCheck from "utils/Check/patternCheck";

export const useSignupUpload = () => {
  const [section, setSection] = useState("email");
  const EmailMutation = useEmailMutation();
  const { startTimer, timeRemaining } = useSignup();
  const [passwordCheck, setPasswordCheck] = useState<PasswordCheckParam>({
    passwords: "",
  });

  const [errorState, setErrorState] =
    useRecoilState<Record<string, string>>(ErrorStateAtom);

  const [passwordType, setPasswordType] = useState<PasswordParm>({
    type: "password",
    visible: false,
  });

  const [signupData, setSignupData] = useState<SignupParam>({
    userId: "",
    password: "",
    name: "",
    email: "",
    code: "",
  });

  //비밀번호 보기
  const handlePasswordType = () => {
    setPasswordType(() => {
      if (!passwordType.visible) {
        return { type: "text", visible: true };
      }
      return { type: "password", visible: false };
    });
  };

  const handleSignupChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      setSignupData((prev) => ({ ...prev, [name]: value }));
    },
    [setSignupData]
  );

  const handlePasswordCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordCheck((prev) => ({ ...prev, [name]: value }));
  };

  //아이디 중복 확인
  const onUserIdOverlap = async () => {
    const { userId, name } = signupData;

    if (!patternCheck.nameCheck(name)) {
      setErrorState({
        ...errorState,
        name: "1자 이상 15자 이하의 문자",
      });
      return;
    }

    if (!patternCheck.useridCheck(userId)) {
      setErrorState({
        ...errorState,
        userId: "1자 이상 15자 이하의 영문(필수)과 숫자(선택)",
      });
      return;
    }

    const { data } = await customAxios.get(`/user/validate?userId=${userId}`);

    if (data === true) {
      setErrorState({ ...errorState, name: "", userId: "" });
      setSection("password");
      showAlert("사용가능한 아이디입니다", "SUCCESS");
    } else {
      setErrorState({
        ...errorState,
        userId: "1자 이상 15자 이하의 영문(필수)과 숫자(선택)",
      });
    }
  };

  //이메일 중복 확인
  const onEmailOverlap = async () => {
    const { email } = signupData;

    if (!patternCheck.emailCheck(email)) {
      setErrorState({
        ...errorState,
        email: "이메일 주소를 다시 확인해주세요!",
      });
      return;
    }

    const { data } = await customAxios.get(`/email/validate?email=${email}`);

    if (data === true) {
      onEmailCertified();
      setErrorState({ ...errorState, email: "" });
    } else {
      setErrorState({ ...errorState, email: "이메일이 중복되었습니다" });
    }
  };

  //인증번호
  const onEmailCertified = () => {
    const { email } = signupData;
    EmailMutation.mutate(
      {
        email,
      },
      {
        onSuccess: () => {
          showAlert("인증번호 전송이 완료되었습니다", "SUCCESS");
          startTimer();
          setSection("emailverify");
        },
        onError: () => {
          setErrorState({ ...errorState, email: "이메일이 잘못되었습니다" });
        },
      }
    );
  };

  //이메일 인증
  const onAuthNumber = async () => {
    const { code, email } = signupData;

    const { data } = await customAxios.get(
      `email/check?email=${email}&code=${code}`
    );
    if (data === true) {
      showAlert("이메일 인증이 완료되었습니다", "SUCCESS");
      setSection("id");
    } else {
      showAlert("이메일 인증에 실패하였습니다", "ERROR");
    }
  };

  const onSubmitSignup = useCallback(async () => {
    const { password } = signupData;
    const { passwords } = passwordCheck;

    if (password !== passwords) {
      setErrorState({ ...errorState, passwords: "비밀번호가 맞지 않습니다" });
      return;
    }

    try {
      await signupRepositoryImpl.postSignup(signupData);
      showAlert("회원가입 성공", "SUCCESS");
      window.location.reload();
    } catch (e) {
      showAlert("회원가입 실패", "ERROR");
    }
  }, [signupData, passwordCheck]);

  const formattedTime = new Date(timeRemaining * 1000)
    .toISOString()
    .substr(14, 5);

  return {
    onEmailCertified,
    onEmailOverlap,
    onAuthNumber,
    onSubmitSignup,
    onUserIdOverlap,
    signupData,
    formattedTime,
    handleSignupChange,
    handlePasswordCheck,
    section,
    setSection,
    passwordType,
    handlePasswordType,
    passwordCheck,
    EmailMutation,
  };
};
