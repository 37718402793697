import React from "react";
import * as S from "./style";
import { useRecoilState } from "recoil";
import { ErrorStateAtom } from "stores/Common/common.store";
import Palette from "libs/Palette/palette";
interface TextFieldProps {
  id: string;
  name: string;
  children: React.ReactNode;
  functions: (() => void) | string;
  message?: string;
  type?: string;
  onchange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
}

const TextField: React.FC<TextFieldProps> = ({
  children,
  onchange,
  id,
  name,
  type,
  functions,
  value,
  message,
}) => {
  const [errorState] = useRecoilState<Record<string, string>>(ErrorStateAtom);
  const error = errorState[id as keyof typeof errorState];
  console.log(error);

  return (
    <>
      <S.TextField error={error} message={message}>
        <S.TextFieldInput
          required
          onChange={onchange}
          style={{
            borderBottom: error && "1px solid red",
          }}
          id={id}
          name={name}
          type={type}
          value={value}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (typeof functions === "function") {
                functions();
              } else {
                const elementId = functions;
                document.getElementById(elementId)?.focus();
              }
            }
          }}
        />

        <S.PatternMessage
          style={{
            display: error ? "none" : "",
          }}
        >
          {message}
        </S.PatternMessage>

        <label
          style={{
            fontFamily:
              "Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic",
          }}
        >
          {children}
        </label>
      </S.TextField>
      {typeof error === "string" && (
        <S.ErrorText style={{ color: `${Palette.error}` }}>
          <div>{error}</div>
        </S.ErrorText>
      )}
    </>
  );
};

export default TextField;
