import TextField from "components/common/TextField";
import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import { SignupType } from "types/Auth/signup.type";
import { Dispatch, SetStateAction } from "react";

interface IdProps extends SignupType {
  onUserIdOverlap: () => void;
  setSection: Dispatch<SetStateAction<string>>;
}

const Id = ({
  handleSignupChange,
  onUserIdOverlap,
  signupData,
  setSection,
}: IdProps) => {
  return (
    <S.TestT>
      <S.IdInputBox>
        <TextField
          onchange={handleSignupChange}
          id="name"
          name="name"
          functions="userId"
          message="1자 이상 15자 이하의 문자"
        >
          닉네임
        </TextField>
      </S.IdInputBox>
      <S.IdInputBox>
        <TextField
          onchange={handleSignupChange}
          id="userId"
          name="userId"
          functions={onUserIdOverlap}
          message="1자 이상 15자 이하의 영문(필수)과 숫자(선택)"
        >
          아이디
        </TextField>
      </S.IdInputBox>

      <S.IdButtonBox>
        <RegisterButton
          width={30}
          height={53}
          radius={8}
          fontsize={18}
          click={() => {
            setSection("emailverify");
          }}
          positions={true}
          buttonType="cencel"
        >
          이전
        </RegisterButton>
        <RegisterButton
          width={100}
          height={53}
          radius={8}
          fontsize={18}
          click={() => onUserIdOverlap()}
          positions={true}
          buttonType="agree"
          disabled={
            signupData.userId.length === 0 || signupData.name.length === 0
          }
        >
          다음 단계로 넘어가기
        </RegisterButton>
      </S.IdButtonBox>
    </S.TestT>
  );
};

export default Id;
