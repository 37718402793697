import * as S from "./style";
import Modal from "components/common/Modal";
import close from "assets/img/Upload/close.svg";
import { useGetShortList } from "queries/ComicUpload/Short/short.query";
import Eyes from "assets/img/Episode/Episodeeyes.svg";
import Communication from "assets/img/Episode/Communication.svg";
import Health from "assets/img/Episode/Health.svg";
import { useShortDelete } from "hooks/Shorts/useShortDelete";

interface ShortDeleteProps {
  shortDeleteModal: boolean;
  toggleShortDeleteModal: () => void;
}

const ShortDeleteModal = ({
  shortDeleteModal,
  toggleShortDeleteModal,
}: ShortDeleteProps) => {
  const { data: getShortList } = useGetShortList({
    shortsId: Number(sessionStorage.getItem("ShortId")),
  });

  const { handleShortDeleteCheck, deleteShortCheck, onSubmitShortDelete } =
    useShortDelete(toggleShortDeleteModal);
  return (
    <Modal isOpen={shortDeleteModal}>
      <S.ModalContainer>
        <S.ModalBox>
          <S.IconBox>
            <img
              alt="close"
              src={close}
              onClick={() => toggleShortDeleteModal()}
            />
          </S.IconBox>
          <S.TitleBox>
            <S.Title isColor={true}>{getShortList?.title}</S.Title>
            <S.Title isColor={false}>
              "{getShortList?.title}" 를 삭제하시겠어요?
            </S.Title>
          </S.TitleBox>
          <S.PerformanceContainer>
            <S.PerformanceBoc>
              <S.Icon alt="eyes" src={Eyes} />
              <div>{getShortList?.views}회</div>
            </S.PerformanceBoc>
            <S.PerformanceBoc>
              <S.Icon alt="health" src={Health} />
              <div>{getShortList?.likeCount}개</div>
            </S.PerformanceBoc>
            <S.PerformanceBoc>
              <S.Icon alt="communication" src={Communication} />
              <div>{getShortList?.commentCount}개</div>
            </S.PerformanceBoc>
          </S.PerformanceContainer>
          <S.DeleteInfoBox>
            <p>
              삭제를 하기 위해서,"{getShortList?.title}" 박스 안에 입력해주세요
              <span>*</span>
            </p>
            <S.DeleteInput onChange={handleShortDeleteCheck} />
          </S.DeleteInfoBox>

          <S.DeleteButtonBox>
            <S.DeleteButton
              disabled={deleteShortCheck !== getShortList?.title}
              onClick={() =>
                onSubmitShortDelete(
                  Number(getShortList?.id),
                  getShortList?.title,
                  deleteShortCheck
                )
              }
            >
              삭제
            </S.DeleteButton>
          </S.DeleteButtonBox>
        </S.ModalBox>
      </S.ModalContainer>
    </Modal>
  );
};

export default ShortDeleteModal;
