import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import { useRecoilState } from "recoil";
import { PageStateAtom } from "stores/Common/common.store";
import useDrag from "hooks/common/Drag/useDrag";
import useShortUpload from "hooks/Shorts/useShortUpload";
import Delete from "assets/img/Upload/Delete.svg";
import Loading from "components/common/Loading";
import showAlert from "libs/Toast/toast";
import { Dispatch, SetStateAction } from "react";

interface ShortUploadProps {
  toggleRegisterModal: () => void;
  shortid: string | undefined;
  onSubmitModifyShort: () => void;
  episodeImgList: string[];
  setShortSection: Dispatch<SetStateAction<string>>;
}

const ShortUpload = ({
  toggleRegisterModal,
  shortid,
  onSubmitModifyShort,
  episodeImgList,
  setShortSection,
}: ShortUploadProps) => {
  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const { ...attr } = useShortUpload();

  const { dragEnter, dragStart, drop } = useDrag({
    setFileName: attr.setFilename,
    setImgUrl: attr.setImgUrl,
    imgUrl: attr.imgUrl,
    filename: attr.filename,
  });

  return (
    <>
      {attr.shortCreate.isLoading ? (
        <Loading>단편웹툰 등록 중...</Loading>
      ) : (
        <>
          <S.EpisodeBox>
            <S.EpisodeListBox>
              <S.RegisterText>
                원고 목록<span>*</span>
              </S.RegisterText>
              <S.EpisodeAddBox>
                <S.EpisodeList>
                  {attr.imgUrl.map((image, id) => {
                    const currentFileName = attr.filename[id];

                    return (
                      <S.ScriptList
                        key={id}
                        draggable
                        onDragStart={(e) => dragStart(e, id)}
                        onDragEnter={(e) => dragEnter(e, id)}
                        onDragEnd={drop}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <S.Episode>{currentFileName}</S.Episode>
                        <img
                          alt="delete"
                          src={Delete}
                          onClick={() => attr.handleShortDeleteImage(id)}
                          style={{ width: "20", height: "20px" }}
                        />
                      </S.ScriptList>
                    );
                  })}
                </S.EpisodeList>

                <input
                  type="file"
                  ref={attr.scriptImgRef}
                  style={{ display: "none" }}
                  accept="image/png, image/jpg"
                  multiple
                  onChange={(e) => attr.onShortEpisodeUpload(e)}
                />
                <S.EpisodeAddButton
                  onClick={() =>
                    shortid
                      ? showAlert("수정이 불가합니다", "INFO")
                      : attr.onClickImg()
                  }
                >
                  원고 추가
                </S.EpisodeAddButton>
              </S.EpisodeAddBox>
            </S.EpisodeListBox>
            <S.EpisodeListBox>
              <S.RegisterText>미리보기</S.RegisterText>
              <S.Preview>
                {shortid
                  ? episodeImgList.map((image, id) => (
                      <div key={id}>
                        <img
                          alt="preview"
                          src={image}
                          style={{ width: "354px" }}
                        />
                      </div>
                    ))
                  : attr.imgUrl.map((image, id) => (
                      <div key={id}>
                        <img
                          alt="preview"
                          src={image}
                          style={{ width: "354px" }}
                        />
                      </div>
                    ))}
              </S.Preview>
            </S.EpisodeListBox>
          </S.EpisodeBox>
          <S.EpisodeRegisterButton>
            <RegisterButton
              width={12}
              height={44}
              radius={8}
              fontsize={16}
              positions={true}
              buttonType="cencel"
              click={() => {
                setPage((prev) => prev - 1);
                setShortSection("thumbnail");
              }}
            >
              이전
            </RegisterButton>
            <RegisterButton
              width={21}
              height={44}
              radius={8}
              fontsize={16}
              positions={true}
              buttonType="agree"
              click={() =>
                shortid
                  ? onSubmitModifyShort()
                  : attr.onSubmitShortUpload(toggleRegisterModal)
              }
            >
              완료
            </RegisterButton>
          </S.EpisodeRegisterButton>
        </>
      )}
    </>
  );
};

export default ShortUpload;
