import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Email from "./Email";
import EmailVerify from "./EmailVerify";
import Id from "./Id";
import Password from "./Password";
import { SIGNUP_SECTION_NAME } from "constants/Signup/signup.constants";
import { useSignupUpload } from "hooks/Auth/Signup/useSignupUpload";

interface SignupProps {
  setIsLogin: Dispatch<SetStateAction<boolean>>;
}

const Signup = ({ setIsLogin }: SignupProps) => {
  const {
    section,
    setSection,
    handleSignupChange,
    onEmailOverlap,
    signupData,
    formattedTime,
    onAuthNumber,
    onUserIdOverlap,
    handlePasswordCheck,
    onSubmitSignup,
    passwordType,
    handlePasswordType,
    passwordCheck,
    EmailMutation,
  } = useSignupUpload();

  const [, setPrevSection] = useState(section);
  const AuthComponents: ReactNode[] = [
    <Email
      setIsLogin={setIsLogin}
      setSection={setSection}
      handleSignupChange={handleSignupChange}
      onEmailOverlap={onEmailOverlap}
      signupData={signupData}
      EmailMutation={EmailMutation}
    />,
    <EmailVerify
      setSection={setSection}
      handleSignupChange={handleSignupChange}
      signupData={signupData}
      formattedTime={formattedTime}
      onAuthNumber={onAuthNumber}
      onEmailOverlap={onEmailOverlap}
    />,
    <Id
      setSection={setSection}
      handleSignupChange={handleSignupChange}
      onUserIdOverlap={onUserIdOverlap}
      signupData={signupData}
    />,
    <Password
      setSection={setSection}
      handleSignupChange={handleSignupChange}
      handlePasswordCheck={handlePasswordCheck}
      onSignup={onSubmitSignup}
      passwordType={passwordType}
      handlePasswordType={handlePasswordType}
      passwordCheck={passwordCheck}
      signupData={signupData}
    />,
  ];

  useEffect(() => {
    setPrevSection(section);
  }, [section]);

  return (
    <>
      {AuthComponents.map((component, idx) => {
        return section === SIGNUP_SECTION_NAME[idx].title && component;
      })}
    </>
  );
};

export default Signup;
