import { useDeleteSeriesList } from "queries/ComicUpload/Series/series.query";
import { ChangeEvent, useCallback, useState } from "react";
import showAlert from "libs/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "queries/queryKey";
import { useNavigate } from "react-router-dom";

export function useSeriesDelete(toggleSeriesDeleteModal: () => void) {
  const deleteSeriesListMutation = useDeleteSeriesList();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [deleteSeriesCheck, setDeleteSeriesCheck] = useState<string>("");

  const handleSeriesDeleteCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setDeleteSeriesCheck(e.target.value);
  };

  const onSubmitSeriesDelete = useCallback(
    (seriesId: number, title: string | undefined, check: string) => {
      if (title === check) {
        deleteSeriesListMutation.mutate(
          {
            seriesId: seriesId,
          },
          {
            onSuccess: () => {
              showAlert("삭제 성공", "SUCCESS");
              queryClient.invalidateQueries(QUERY_KEYS.series.seriesList(1));
              toggleSeriesDeleteModal();

              navigate("/series");
            },
            onError: () => {
              showAlert("장편웹툰 삭제 실패", "ERROR");
            },
          }
        );
      }
    },
    []
  );

  return { onSubmitSeriesDelete, deleteSeriesCheck, handleSeriesDeleteCheck };
}
