import { styled } from "styled-components";
import Palette from "libs/Palette/palette";

export const LoginInputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 85px;
`;

export const LoginStateBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 20px 40px 50px 40px;
`;

export const LoginState = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`;

export const LoginButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 40px 16px 40px;
`;

export const LoginInfoBox = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  color: ${Palette.Neutral.neutral5};

  width: 100%;
  padding: 0px 40px 0px 40px;

  cursor: pointer;
  p {
    font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
    &:hover {
      color: ${Palette.Primary.primary5};
    }
  }
`;

export const LoginFindBox = styled.div`
  display: flex;
  align-items: center;

  img {
    display: flex;
    align-items: center;

    margin-top: -2px;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;

  cursor: pointer;

  margin-top: 5px;

  p {
    font-size: 16px;
    color: ${Palette.Neutral.neutral5};
    font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

    padding-left: 10px;
  }
`;
