import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const ProgressBarBox = styled.div``;
export const ProgressBar = styled.div<{ active: boolean; width: number }>`
  width: ${(props) => props.width}px;

  height: 5px;

  border-radius: 10px;

  background-color: ${({ active }) =>
    active ? Palette.Primary.primary5 : Palette.Neutral.neutral1};
`;
