import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const ShortContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const RegisterText = styled.p`
  margin-bottom: 8px;
  margin-left: 5px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${Palette.Neutral.neutral7};

  span {
    color: ${Palette.error};
  }
`;

export const ShortGenreBox = styled.div`
  width: 752px;
`;

export const ShortGenre = styled.div`
  margin-bottom: 45px;

  padding-left: 6px;
`;

export const GenreListContainer = styled.div`
  display: flex;

  gap: 10px;
  flex-wrap: wrap;

  width: 100%;
`;

export const Genre = styled.div`
  height: 52px;
`;

export const Genres = styled.div<{ isSelected: any; isModifyShortGenre: any }>`
  display: flex;
  box-shadow: 0 0 0 1px ${Palette.Neutral.neutral2};
  border-radius: 10px;
  box-sizing: border-box;

  color: ${({ isSelected, isModifyShortGenre }) =>
    isSelected || isModifyShortGenre
      ? Palette.Neutral.neutral9
      : Palette.Neutral.neutral4};
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  padding: 14px 14px 14px 14px;
  cursor: pointer;

  &:hover {
    ${({ isSelected, isModifyShortGenre }) =>
      !(isSelected || isModifyShortGenre) &&
      `
      box-shadow: 0 0 0 2px ${Palette.Primary.primary3};
      color: ${Palette.Primary.primary3};
    `}
  }

  ${({ isSelected, isModifyShortGenre }) =>
    (isSelected || isModifyShortGenre) &&
    `
    box-shadow: 0 0 0 2px ${Palette.Primary.primary5};
  `}
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;

  border-radius: 10px;
  border: 1px solid ${Palette.Neutral.neutral2};

  font-size: 18px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
  padding-left: 15px;

  color: ${Palette.Neutral.neutral9};
  outline: none;

  margin-bottom: 45px;

  &::placeholder {
    color: ${Palette.Neutral.neutral4};
  }

  &:focus {
    border: 1px solid ${Palette.Primary.primary5};
  }
`;

export const ShortRegisterButton = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;

  gap: 10px;
`;

export const ShortBox = styled.div`
  width: 100%;
`;

export const GenreAddBox = styled.div`
  margin-bottom: 165px;
`;
