import { useState } from "react";

const useGenre = () => {
  const [genreChange, setGenreChange] = useState<string>("");

  const [Genre, setGenre] = useState([
    "드라마",
    "판타지",
    "액션",
    "로맨스",
    "일상",
    "개그",
    "스포츠",
    "스릴러",
    "무협",
    "학원",
    "스토리",
    "공포",
    "순정",
    "미스터리",
  ]);

  const handleAddGenre = (event: any) => {
    const inputVal = event.target.value;

    if (event.key === "Enter" && inputVal !== "" && !Genre.includes(inputVal)) {
      setGenre([...Genre, inputVal]);
      event.target.value = "";
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddGenre(e);
      setGenreChange("");
    }
  };

  return { Genre, setGenreChange, onKeyDown, genreChange, handleAddGenre };
};

export default useGenre;
