import styled from "styled-components";
import { HoverAnimation } from "styles/common.style";
import Palette from "libs/Palette/palette";

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;

  width: 89px;
  height: 151px;

  border-radius: 10px;

  background-color: white;

  z-index: 999;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  margin-top: 80px;
  margin-right: 30px;

  cursor: pointer;
`;

export const ModalWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 80px;
`;

export const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
`;

export const Title = styled.div`
  font-size: 14px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  ${HoverAnimation}

  width: 60px;
  height: 30px;
`;

export const ListBox = styled.div`
  margin-bottom: 10px;

  margin-top: 8px;
`;

export const Line = styled.div`
  width: 59px;

  border-bottom: 1px solid ${Palette.Neutral.neutral1};
`;
