export const SHORT_SECTION_NAME = [
  {
    title: "info",
  },
  {
    title: "thumbnail",
  },
  {
    title: "upload",
  },
];
