import * as S from "./style";
import { useGetShortList } from "queries/ComicUpload/Short/short.query";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "assets/img/Upload/ikon.svg";
import Palette from "libs/Palette/palette";
import MoveButton from "components/common/Button/Move";
import Eyes from "assets/img/Episode/Episodeeyes.svg";
import { ReactComponent as Communication } from "assets/img/Episode/Communication.svg";
import Health from "assets/img/Episode/Health.svg";
import usePopupModal from "utils/Modal/usePopupModal";
import ShortDeleteModal from "./ShortDeleteModal";
import { useState } from "react";
import ShortComment from "./ShortComment";
import ShortRegisterModal from "../ComicShortRegister";

const ComicShortManagement = () => {
  const { data: getShortList } = useGetShortList({
    shortsId: Number(sessionStorage.getItem("ShortId")),
  });
  const [commentState, setCommentState] = useState<boolean>(false);

  const { ...popup } = usePopupModal();
  const navigate = useNavigate();
  return (
    <S.ManagementContainer>
      <S.ManagementBox>
        <S.TitleBox>
          <S.Title fontColor={true}>연재 웹툰 관리</S.Title>
          <Arrow fill={Palette.Neutral.neutral9} />
          <S.Title fontColor={false}>{getShortList?.title}</S.Title>
        </S.TitleBox>

        <S.ShortContainer>
          <S.ShortBox>
            <S.ShortInfoBox>
              <S.ShortThumbnail src={getShortList?.thumbnail} />
              <S.ShortTitlebox>
                <S.ShortTitle>{getShortList?.title}</S.ShortTitle>
                <S.GenreBox>
                  {getShortList?.genre.map((genre) => (
                    <S.ShortGenre>{genre}</S.ShortGenre>
                  ))}
                </S.GenreBox>
              </S.ShortTitlebox>

              <div style={{ display: "flex", gap: "10px" }}>
                <MoveButton
                  fontcolor={"#0853C9"}
                  backroundcolor={`${Palette.Primary.primary1}`}
                  click={() => popup.toggleShortDeleteModal()}
                  width={155}
                  height={41}
                >
                  작품 삭제
                </MoveButton>
                <MoveButton
                  fontcolor={"white"}
                  backroundcolor={`${Palette.Primary.primary6}`}
                  click={() => popup.toggleShortAddModal()}
                  width={155}
                  height={41}
                >
                  작품 수정
                </MoveButton>
              </div>
            </S.ShortInfoBox>
            <S.PerformanceContainer>
              <S.PerformanceBox>
                <S.Icon alt="eyes" src={Eyes} />
                <div>{getShortList?.views}회</div>
              </S.PerformanceBox>
              <S.PerformanceBox>
                <S.Icon alt="health" src={Health} />
                <div>{getShortList?.likeCount}개</div>
              </S.PerformanceBox>
              <S.PerformanceBox onClick={() => setCommentState(!commentState)}>
                <Communication fill={`${commentState ? "#703D8F" : "black"}`} />
                <div
                  style={{
                    color: commentState ? "#703D8F" : "black",
                    userSelect: "none",
                  }}
                >
                  {getShortList?.commentCount}개
                </div>
              </S.PerformanceBox>
            </S.PerformanceContainer>
            <S.Date>{getShortList?.createdDate.slice(0, 10)}</S.Date>
          </S.ShortBox>

          {commentState ? (
            <ShortComment shortId={Number(getShortList?.id)} />
          ) : (
            <S.ShortPreviewBox>
              {getShortList?.imageURL.map((key, index) => {
                return <S.ShortPreviewImg alt="preview" src={key} />;
              })}
            </S.ShortPreviewBox>
          )}
        </S.ShortContainer>
      </S.ManagementBox>
      <ShortDeleteModal
        shortDeleteModal={popup.shortDeleteModal}
        toggleShortDeleteModal={popup.toggleShortDeleteModal}
      />
      <ShortRegisterModal
        shortAddModal={popup.shortAddModal}
        toggleShortAddModal={popup.toggleShortAddModal}
      />
    </S.ManagementContainer>
  );
};

export default ComicShortManagement;
