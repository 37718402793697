import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import useEpisodeUpload from "hooks/Episode/useEpisodeUpload";
import Delete from "assets/img/Upload/Delete.svg";
import { Dispatch, SetStateAction } from "react";
import { useRecoilState } from "recoil";
import { PageStateAtom } from "stores/Common/common.store";
import useDrag from "hooks/common/Drag/useDrag";
import showAlert from "libs/Toast/toast";

interface EpisodeProps {
  setSection: Dispatch<SetStateAction<string>>;
  episodeid: string | undefined;
  episodeImgList: string[];
}

const EpisodeUpload = ({
  setSection,
  episodeid,
  episodeImgList,
}: EpisodeProps) => {
  const { ...attr } = useEpisodeUpload();
  const [, setPage] = useRecoilState<number>(PageStateAtom);

  const { dragEnter, dragStart, drop } = useDrag({
    setFileName: attr.setFileName,
    setImgUrl: attr.setRoundImgUrl,
    imgUrl: attr.roundImgUrl,
    filename: attr.filename,
  });

  return (
    <div>
      <S.EpisodeBox>
        <S.EpisodeListBox>
          <S.RegisterText>
            원고 목록<span>*</span>
          </S.RegisterText>
          <S.EpisodeAddBox>
            <S.EpisodeList>
              {attr.roundImgUrl.map((image, id) => {
                const currentFileName = attr.filename[id];

                return (
                  <S.ScriptList
                    key={id}
                    draggable
                    onDragStart={(e) => dragStart(e, id)}
                    onDragEnter={(e) => dragEnter(e, id)}
                    onDragEnd={drop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <S.Episode>{currentFileName}</S.Episode>
                    <img
                      alt="delete"
                      src={Delete}
                      onClick={() => attr.handleEpisodeListDelete(id)}
                      style={{ width: "20", height: "20px" }}
                    />
                  </S.ScriptList>
                );
              })}
            </S.EpisodeList>

            <input
              type="file"
              ref={attr.episodeImgRef}
              accept="image/png, image/jpg"
              style={{ display: "none" }}
              multiple
              onChange={(e) => attr.onEpisodeListUpload(e)}
            />
            <S.EpisodeAddButton
              onClick={() =>
                episodeid
                  ? showAlert("수정이 불가합니다", "INFO")
                  : attr.handleEpisodeAdd()
              }
            >
              원고 추가
            </S.EpisodeAddButton>
          </S.EpisodeAddBox>
        </S.EpisodeListBox>
        <S.EpisodeListBox>
          <S.RegisterText>미리보기</S.RegisterText>
          <S.Preview>
            {episodeid
              ? episodeImgList.map((image, id) => (
                  <div key={id}>
                    <img alt="preview" src={image} style={{ width: "352px" }} />
                  </div>
                ))
              : attr.roundImgUrl.map((image, id) => (
                  <div key={id}>
                    <img alt="preview" src={image} style={{ width: "352px" }} />
                  </div>
                ))}
          </S.Preview>
        </S.EpisodeListBox>
      </S.EpisodeBox>

      <S.EpisodeRegisterButton>
        <RegisterButton
          width={12}
          height={44}
          radius={8}
          fontsize={16}
          positions={true}
          buttonType="cencel"
          click={() => {
            setSection("info");
            setPage((prev) => prev - 1);
          }}
        >
          이전
        </RegisterButton>
        <RegisterButton
          width={20}
          height={44}
          radius={8}
          fontsize={16}
          positions={true}
          buttonType="agree"
          click={() => {
            setSection("setting");
            setPage((prev) => prev + 1);
          }}
        >
          다음
        </RegisterButton>
      </S.EpisodeRegisterButton>
    </div>
  );
};

export default EpisodeUpload;
