import * as S from "./style";
import Lottie from "react-lottie-player";
import ToongetherLogo from "json/ToongetherMaker_Light_Lottie.json";
import Profile from "assets/img/Sideber/임시.svg";
import Add from "assets/img/Header/add.svg";
import { useLocation, useNavigate } from "react-router-dom";
import SeriesRegisterModal from "components/Series/ComicSeriesRegister";
import usePopupModal from "utils/Modal/usePopupModal";
import EpisodeRegisterModal from "components/Episode/ComicEpisodeRegister";
import ShortRegisterModal from "components/Shorts/ComicShortRegister";

interface HeaderProps {
  toggleuUserPopup: () => void;
}

const Header = ({ toggleuUserPopup }: HeaderProps) => {
  const navigate = useNavigate();

  const {
    episodeAddModal,
    toggleEpisodAddModal,
    toggleSeriesAddModal,
    seriesAddModal,
    toggleShortAddModal,
    shortAddModal,
  } = usePopupModal();
  const { pathname } = useLocation();

  const ModalOpen = () => {
    if (pathname === "/series") {
      toggleSeriesAddModal();
    } else if (
      pathname === `/series/manage/${sessionStorage.getItem("SeriesId")}`
    ) {
      toggleEpisodAddModal();
    } else if (pathname === `/shorts`) {
      toggleShortAddModal();
    }
  };

  return (
    <S.HeaderContainer>
      <S.HeaderBox>
        <S.HeaderLogo onClick={() => navigate("/series")}>
          <Lottie
            loop
            animationData={ToongetherLogo}
            play
            className="Lottie"
            style={{ width: 160 }}
          />
        </S.HeaderLogo>
        <S.HeaderIconBox>
          <S.SeriesRegisterContainer>
            {pathname ===
              `/series/manage/${sessionStorage.getItem("SeriesId")}` ||
            pathname === "/series" ||
            pathname === "/shorts" ? (
              <S.SeriesRegisterBox
                onClick={() => {
                  ModalOpen();
                }}
              >
                <S.SeriesRegisterTitle>
                  <img alt="add" src={Add} />
                  <div>
                    {pathname ===
                    `/series/manage/${sessionStorage.getItem("SeriesId")}`
                      ? "회차 추가"
                      : "작품 추가"}
                  </div>
                </S.SeriesRegisterTitle>
              </S.SeriesRegisterBox>
            ) : (
              ""
            )}
          </S.SeriesRegisterContainer>
          <S.IconBox onClick={toggleuUserPopup}>
            <img alt="profile" src={Profile} />
          </S.IconBox>
        </S.HeaderIconBox>
      </S.HeaderBox>
      <SeriesRegisterModal
        seriesAddModal={seriesAddModal}
        toggleSeriesAddModal={toggleSeriesAddModal}
      />
      <EpisodeRegisterModal
        episodeAddModal={episodeAddModal}
        toggleEpisodAddModal={toggleEpisodAddModal}
      />
      <ShortRegisterModal
        shortAddModal={shortAddModal}
        toggleShortAddModal={toggleShortAddModal}
      />
    </S.HeaderContainer>
  );
};
export default Header;
