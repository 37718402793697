import ReactDom from "react-dom";

interface PortalProps {
  children: React.ReactNode;
  selector: string;
}

export const Portal = ({ children, selector }: PortalProps) => {
  const selectedDOM = document.querySelector(selector);
  return !!selectedDOM ? ReactDom.createPortal(children, selectedDOM) : null;
};
