const colorPalette = {
  backgound1: "#eeeeee",
  backgound2: "#F7F7F7",
  basics: "#0F69F5",
  error: "#ED3B3B",

  Neutral: {
    neutral9: "#1B1C1D",
    neutral8: "#333538",
    neutral7: "#4B4E53",
    neutral6: "#64686E",
    neutral5: "#7C8188",
    neutral4: "#979BA0",
    neutral3: "#B2B4B9",
    neutral2: "#CCCED1",
    neutral1: "#E7E8E9",
  },

  Primary: {
    primary9: "#021636",
    primary8: "#042B67",
    primary7: "#063F98",
    primary6: "#0853C9",
    primary5: "#0F69F5",
    primary4: "#3E86F7",
    primary3: "#71A6F9",
    primary2: "#A2C5FB",
    primary1: "#EBEFFF",
  },
};

export default colorPalette;
