import { useQueryClient } from "@tanstack/react-query";
import showAlert from "libs/Toast/toast";
import { usePostComment } from "queries/Comment/comment.query";
import { QUERY_KEYS } from "queries/queryKey";
import { ChangeEvent, useState } from "react";

const useShortComment = (shortId: number) => {
  const postCommentMutatation = usePostComment();
  const [comment, setComment] = useState("");
  const queryClient = useQueryClient();

  const handleEpisodeCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const onEpisodeSubmitComment = () => {
    postCommentMutatation.mutate(
      {
        episodeId: shortId,
        commentData: {
          body: comment,
          parentCommentId: null,
        },
      },
      {
        onSuccess: () => {
          showAlert("댓글 등록 성공", "SUCCESS");
          queryClient.invalidateQueries(QUERY_KEYS.comment.comment(shortId));
          setComment("");
        },
        onError: () => {
          showAlert("댓글 등록 실패", "ERROR");
        },
      }
    );
  };
  return { handleEpisodeCommentChange, onEpisodeSubmitComment, comment };
};

export default useShortComment;
