import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "constants/Token/token.constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useTokenCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem(ACCESS_TOKEN_KEY) === null ||
      localStorage.getItem(REFRESH_TOKEN_KEY) === null
    ) {
      navigate("/");
    }
  }, []);
};

export default useTokenCheck;
