import { ReactNode, useEffect, useState } from "react";
import ShortInfo from "./ShortInfo";
import ShortThumbail from "./ShortThumbnail";
import ShortUpload from "./ShortUpload";
import { SHORT_SECTION_NAME } from "constants/Shorts/shorts.constants";
import useShortUpload from "hooks/Shorts/useShortUpload";
import useGenre from "hooks/common/Genre/useGenre";
import { Params, useParams } from "react-router-dom";
import useShortModify from "hooks/Shorts/useShortModify";

interface ShortRegisterProps {
  toggleShortAddModal: () => void;
}

const ShortRegister = ({ toggleShortAddModal }: ShortRegisterProps) => {
  const { shortid }: Readonly<Params<"shortid">> = useParams();
  const { shortSection } = useShortUpload();
  const { ...shorts } = useShortModify({ shortId: Number(shortid) });

  const [, setShortPrevSection] = useState(shortSection);
  const { setGenreChange, Genre, onKeyDown, genreChange } = useGenre();
  const {
    handleShortInputChange,
    shortUpload,
    onShortInfoUpload,
    setShortSection,
    handleShortGenreDesc,
    handleShortGereSelection,
    selectShortGenre,
  } = useShortUpload();

  const ShortComponents: ReactNode[] = [
    <ShortInfo
      selectModifyShortGenre={shorts.selectModifyShortGenre}
      modifyShortGenreDesc={shorts.modifyShortGenreDesc}
      modifyShortGenreSelection={shorts.modifyShortGenreSelection}
      handleModifyInfo={shorts.onShortModifyInfo}
      modifyShortData={shorts.modifyShortData}
      handleShortModifyChange={shorts.handleShortModifyChange}
      shortUpload={shortUpload}
      selectShortGenre={selectShortGenre}
      handleShortGenreDesc={handleShortGenreDesc}
      handleShortGereSelection={handleShortGereSelection}
      Genre={Genre}
      setGenreChange={setGenreChange}
      onKeyDown={onKeyDown}
      genreChange={genreChange}
      handleInputChange={handleShortInputChange}
      handleInfoUpload={onShortInfoUpload}
    />,
    <ShortThumbail
      shortid={shortid}
      thumbnaiModifylUrl={shorts.thumbnaiModifylUrl}
      setThumbnailModifyUrl={shorts.setThumbnailModifyUrl}
      thumbnailModifyForm={shorts.thumbnailModifyForm}
      thumbnailModifyImgRef={shorts.thumbnailModifyForm}
      handleModifyThumbnailUpload={shorts.onShortModifyThumbnailUpload}
      modifyShortData={shorts.modifyShortData}
      setShortSection={setShortSection}
    />,
    <ShortUpload
      setShortSection={setShortSection}
      episodeImgList={shorts.episodeImgList}
      onSubmitModifyShort={() =>
        shorts.onSubmitModifyShort(toggleShortAddModal)
      }
      toggleRegisterModal={toggleShortAddModal}
      shortid={shortid}
    />,
  ];

  useEffect(() => {
    setShortPrevSection(shortSection);
  }, [shortSection]);

  return (
    <>
      {ShortComponents.map((component, idx) => {
        return shortSection === SHORT_SECTION_NAME[idx].title && component;
      })}
    </>
  );
};

export default ShortRegister;
