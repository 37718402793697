import customAxios from "libs/Axios/customAxios";
import { FontRepository, FontType } from "./font.repository";

class FontRepositoryImpl implements FontRepository {
  public async getFont(): Promise<FontType> {
    const { data } = await customAxios.get("/comic/get/fonts");
    return data;
  }
}

export default new FontRepositoryImpl();
