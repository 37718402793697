import styled, { css } from "styled-components";
import Palette from "libs/Palette/palette";
import { buttonType } from "types/Button/button.type";

export const ReturnButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 28px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  height: 100px;

  cursor: pointer;
`;

export const RegisterButton = styled.button<{
  isPosition: boolean;
  buttonType: buttonType;
}>`
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  opacity: ${(props) => props.disabled && "0.8"};
  cursor: pointer;
  ${({ buttonType }) => getColor[buttonType]}

  width: 100%;
`;

const getColor: Record<buttonType, any> = {
  agree: css`
    background-color: ${Palette.basics};
    color: white;

    &:hover {
      background-color: ${Palette.Primary.primary3};
      transition: all 0.1s ease-in-out;
    }

    &:active {
      background-color: ${Palette.Primary.primary6};
      transition: all 0.1s ease-in-out;
    }
  `,
  cencel: css`
    background-color: ${Palette.Primary.primary1};
    color: ${Palette.Primary.primary5};
  `,
  completed: css`
    background-color: ${Palette.error};
    color: white;
  `,
};

export const MoveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;

  font-size: 14px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  cursor: pointer;

  user-select: none;
`;
