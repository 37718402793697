import styled from "styled-components";
import { HoverAnimation } from "styles/common.style";
import Palette from "libs/Palette/palette";
export const RegisterModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1;
`;

export const RegisterModalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 842px;
  height: 807px;

  background-color: white;
  border-radius: 20px;
`;

export const RegisterCloseButton = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 806px;

  padding-top: 12px;
  padding-bottom: 10px;
`;

export const IconBox = styled.div`
  width: 38px;
  height: 38px;

  ${HoverAnimation}
`;

export const ProgressBar = styled.div`
  margin-bottom: 45px;
`;

export const ProgressBarBox = styled.div`
  display: flex;
  justify-content: center;

  gap: 10px;

  margin-bottom: 45px;
`;
export const ProgressBarBoxList = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;

  margin-bottom: 5px;
  width: 244px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${({ active }) =>
    active ? Palette.Neutral.neutral9 : Palette.Neutral.neutral4};
`;

export const StepWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 16px;
`;

export const StepBox = styled.div`
  width: 245px;
  display: flex;
  flex-direction: column;
`;

export const StepBoxBar = styled.div<{ active: boolean }>`
  width: 100%;
  height: 5px;
  margin-top: 5px;
  border-radius: 10px;
  background-color: ${({ active }) =>
    active ? Palette.Primary.primary5 : Palette.Neutral.neutral1};
`;

export const StepContents = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ active }) =>
    active ? Palette.Neutral.neutral9 : Palette.Neutral.neutral4};
  font-size: 16px;
  font-weight: bold;
`;
