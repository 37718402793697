class PatternCheck {
  public useridCheck(value: string): boolean {
    if (/^[a-zA-Z](?:[a-zA-Z\d]{0,14})?$/.test(value)) {
      return true;
    }
    return false;
  }

  public passwordCheck(value: string): boolean {
    if (/^(?=.*[A-Za-z])(?=.*\d)([A-Za-z\d@#$%^&+=!?*~]+){8,}$/.test(value)) {
      return true;
    }
    return false;
  }

  public nameCheck(value: string): boolean {
    if (/^[^ ]{1,15}$/.test(value)) {
      return true;
    }
    return false;
  }
  public emailCheck(value: string): boolean {
    if (/^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)) {
      return true;
    }

    return false;
  }
}

export default new PatternCheck();
