import * as S from "./style";
import ToonieLottie from "json/ToonieFinal.json";
import Lottie from "react-lottie-player";

interface LottieProps {
  children: React.ReactNode;
}

const Loading = ({ children }: LottieProps) => {
  return (
    <S.LoadingContainer>
      <Lottie
        loop
        animationData={ToonieLottie}
        play
        style={{ width: 340, height: 340 }}
      />
      <S.LoadingText>{children}</S.LoadingText>
    </S.LoadingContainer>
  );
};

export default Loading;
