import { useNavigate } from "react-router-dom";
import * as S from "./style";
import useHideSidebar from "hooks/common/Sidebar/useHideSidebar";

const NotFound = () => {
  const navigate = useNavigate();
  useHideSidebar();
  return (
    <>
      <S.NotFoundContainer>
        <S.NotFoundBox>
          <S.NotFoundText>404</S.NotFoundText>
          <S.NotFoundPage>페이지를 찾을 수 없음</S.NotFoundPage>
          <S.HomeButton onClick={() => navigate("/series")}>
            홈으로
          </S.HomeButton>
        </S.NotFoundBox>
      </S.NotFoundContainer>
    </>
  );
};

export default NotFound;
