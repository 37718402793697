import { customAxios } from "libs/Axios/customAxios";
import {
  AuthNumberParam,
  EmailParam,
  SignupParam,
  SignupRepository,
} from "./signup.repository";

class SignUpRepositoryImpl implements SignupRepository {
  public async postEmail(emailData: EmailParam): Promise<void> {
    const { data } = await customAxios.post("/email/send", emailData);
    return data;
  }

  public async postAuthNumber(AuthData: AuthNumberParam): Promise<void> {
    const { data } = await customAxios.post("/email/check", AuthData);
    return data;
  }

  public async postSignup(SignupData: SignupParam): Promise<void> {
    const { data } = await customAxios.post("/user/signup", SignupData);
    return data;
  }
}

export default new SignUpRepositoryImpl();
