import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const SeriesContainer = styled.div`
  width: 1120px;
  height: 75px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${Palette.Neutral.neutral9};
  font-size: 16px;
  font-weight: 600;

  margin-top: 45px;

  padding-bottom: 44px;
`;

export const SubTitle = styled.div`
  color: ${Palette.Neutral.neutral7};
  font-family: Pretendard-Regular, Apple SD Gothic Neo, Malgun Gothic;
  font-size: 14px;

  margin-bottom: 5px;
`;

export const SeiresContent = styled.div<{ margin: string }>`
  display: flex;
  align-items: center;

  margin-left: ${({ margin }) => margin};
  position: absolute;

  p {
    font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  }
`;

export const Thumbnail = styled.img`
  width: 120px;
  height: 75px;

  background-color: gray;

  margin-right: 15px;

  cursor: pointer;
`;

export const SeriesListInfo = styled.div`
  display: flex;
  justify-content: space-between;

  width: 302px;

  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
`;

export const SeriesEditButton = styled.button<{
  backgroundColor: string;
  color: string;
}>`
  border: none;
  border-radius: 6px;

  box-shadow: none;

  padding: 6px 12px 5px 12px;

  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};

  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  cursor: pointer;
`;

export const EpisodeNumber = styled.p`
  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;
`;

export const SeiresListLine = styled.div`
  width: 100%;

  border-bottom: 1px solid black;
  margin-top: 120px;
`;

export const SeriesBox = styled.div`
  display: flex;
  align-items: center;

  width: 1060px;
  height: 120px;

  border-top: 1px solid ${Palette.Neutral.neutral1};

  user-select: none;
`;
