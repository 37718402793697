import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const ManagementContainer = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: #f7f7f7;
`;

export const ManagementBox = styled.div`
  margin-top: 70px;

  margin-left: 265px;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;

  height: 76px;
  margin-left: 16px;

  gap: 13px;
`;

export const Title = styled.p<{ fontColor: boolean }>`
  font-size: 22px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${(props) =>
    props.fontColor
      ? `${Palette.Neutral.neutral4}`
      : `${Palette.Neutral.neutral9}`};

  cursor: pointer;
`;

export const EpisodeThumbnail = styled.img`
  width: 320px;
  height: 200px;

  margin-top: 20px;
  margin-bottom: 15px;

  border-radius: 10px;
`;

export const EpisodeInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 360px;
  height: 362px;

  border-radius: 10px;

  background-color: #ffffff;
  box-shadow: 1px 1px 10px 0px rgba(196, 196, 196, 0.2);

  margin-bottom: 20px;
`;

export const EpisodeTitlebox = styled.div`
  width: 300px;
  height: 50px;

  margin-bottom: 15px;
`;

export const SeriesTitle = styled.p`
  margin-bottom: 10px;

  font-size: 18px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;
`;

export const EpisodeTitle = styled.p`
  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
  color: #4b4e53;
`;

export const EpisodeContainer = styled.div`
  display: flex;

  gap: 20px;
`;

export const EpisodePreviewBox = styled.div`
  border-radius: 10px;

  width: 820px;
  height: 903px;

  @media screen and (max-height: 1054px) {
    height: 860px;
  }
  @media screen and (max-height: 990px) {
    height: 800px;
  }

  overflow: auto;

  background-color: white;
`;

export const EpisodePreviewImg = styled.img`
  object-fit: fill;
`;

export const PerformanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 87px;

  width: 360px;
  height: 88px;

  border-radius: 10px;

  background-color: #ffffff;
  box-shadow: 1px 1px 10px 0px rgba(196, 196, 196, 0.2);

  margin-bottom: 15px;
`;

export const PerformanceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  user-select: none;
`;

export const EpisodeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Date = styled.p`
  font-size: 16px;
  color: #7c8188;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
`;
