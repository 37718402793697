import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "queries/queryKey";
import userRepositoryImpl from "repositories/User/user.repositoryImpl";
import { UserInfoType } from "types/User/user.type";

export const useGetUserInfo = (
  options?: UseQueryOptions<UserInfoType, AxiosError, UserInfoType, [string]>
): UseQueryResult<UserInfoType> =>
  useQuery([QUERY_KEYS.user.userInfo], () => userRepositoryImpl.getUserInfo(), {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    ...options,
  });
