import styled from "styled-components";
import { SkeletonAnimtaion } from "styles/common.style";

export const SkeletonContainer = styled.div<{ top: boolean }>`
  width: 100%;

  margin-top: ${(props) => (props.top ? "25px" : " ")};

  background-color: #f7f7f7;
`;

export const SkeletonBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  margin-left: 25px;
`;

export const SkeletonItem = styled.div<{ height: number }>`
  width: 360px;
  height: ${({ height }) => height}px;

  border-radius: 10px;

  margin-bottom: 20px;
  margin-right: 20px;

  ${SkeletonAnimtaion}
`;

export const Skeleteon = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;
