import {
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "queries/queryKey";
import episodeRepositoryImpl from "repositories/ComicUpload/Episode/episode.repositoryImpl";
import { EpisodeUploadParam } from "repositories/ComicUpload/Episode/episode.repository";
import { EpisodeId } from "types/Serial/serial.type";
import {
  SeriesEpisodeType,
  SeriseEpisodeId,
  EpisodeListId,
  EpisodeListType,
} from "types/Series/series.type";

export const useGetEpisode = (
  { id }: SeriseEpisodeId,
  options?: UseQueryOptions<
    SeriesEpisodeType,
    AxiosError,
    SeriesEpisodeType,
    (string | null)[]
  >
): UseQueryResult<SeriesEpisodeType> =>
  useQuery(
    QUERY_KEYS.episode.episodeMyList(id),
    () => episodeRepositoryImpl.getSeriesEpisodeMyList({ id }),
    {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      ...options,
    }
  );

export const useGetEpisodeList = (
  { seriesId, episodeNumber }: EpisodeListId,
  options?: UseQueryOptions<
    EpisodeListType,
    AxiosError,
    EpisodeListType,
    (string | number)[]
  >
): UseQueryResult<EpisodeListType> =>
  useQuery(
    QUERY_KEYS.episode.episodeList(seriesId, episodeNumber),
    () => episodeRepositoryImpl.getEpisodeList({ seriesId, episodeNumber }),
    {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      ...options,
    }
  );

export const usePosetEpisodeCreateMutation = () => {
  const episodecreatemutation = useMutation(
    (params: {
      episodeData: EpisodeUploadParam;
      seriesId: number;
      episodeId: number;
    }) =>
      episodeRepositoryImpl.postEpisodeCreate(
        params.seriesId,
        params.episodeId,
        params.episodeData
      )
  );
  return episodecreatemutation;
};

export const useDeleteEpisode = () => {
  const mutation = useMutation(({ episodeId, seriesId }: EpisodeId) =>
    episodeRepositoryImpl.deleteEpisode({ seriesId, episodeId })
  );
  return mutation;
};

export const usePatchEpisode = () => {
  const mutation = useMutation(
    (params: {
      episodeData: EpisodeUploadParam;
      seriesId: number;
      episodeId: number;
    }) =>
      episodeRepositoryImpl.patchEpisode(
        params.seriesId,
        params.episodeId,
        params.episodeData
      )
  );

  return mutation;
};
