import * as S from "./style";
import { useGetComment } from "queries/Comment/comment.query";
import Profile from "assets/img/Sideber/임시.svg";
import DeleteButton from "assets/img/Comment/button.svg";
import { ReactComponent as Arrow } from "assets/img/Comment/ArrowBt.svg";
import { ReactComponent as Communication } from "assets/img/Episode/Communication.svg";
import { ReactComponent as Health } from "assets/img/Episode/Health.svg";
import React, { useState } from "react";
import useSeriesComment from "hooks/Episode/useEpisodeComment";

interface CommentProps {
  episodeId: number;
}

const EpisodeComment = ({ episodeId }: CommentProps) => {
  const { data: getComment } = useGetComment(episodeId);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [parentStates, setParentStates] = useState<boolean[]>([]);
  const { handleCommentChange, onSubmitComment, comment } =
    useSeriesComment(episodeId);

  const toggleParentState = (index: number) => {
    const newParentStates = [...parentStates];
    newParentStates[index] = !newParentStates[index];
    setParentStates(newParentStates);
  };

  return (
    <S.EpisodeCommentContainer>
      <S.EpisodeCommentBox>
        <div>
          {getComment && getComment?.commentResponses.length > 0 ? (
            <>
              {getComment?.commentResponses.map((comment, index) => {
                const isHovered = hoverIndex === index;

                return (
                  <React.Fragment key={index}>
                    <S.CommentContainer
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(null)}
                    >
                      <S.CommentList>
                        <S.Profile src={Profile} alt="profile" />
                        <S.CommentBox>
                          <S.CommentInfo>
                            {comment.author}
                            <p>{comment.createdDate}</p>
                          </S.CommentInfo>
                          <S.CommentContent>{comment.body}</S.CommentContent>
                          <S.ParentCommentContainer>
                            <S.ParentCommentBox>
                              <Health width={15} fill={"#703D8F"} />
                              <p>160</p>
                            </S.ParentCommentBox>
                            <S.ParentCommentBox
                              onClick={() => toggleParentState(index)}
                            >
                              <Communication width={12} fill={"#703D8F"} />
                              <p>{comment.parentCommentResponses.length}</p>
                            </S.ParentCommentBox>
                          </S.ParentCommentContainer>
                        </S.CommentBox>
                      </S.CommentList>
                      {isHovered && (
                        <S.DeleteButton alt="delete" src={DeleteButton} />
                      )}
                    </S.CommentContainer>
                    {parentStates[index] &&
                      comment.parentCommentResponses.map((parents, idx) => {
                        return (
                          <S.CommentContainer
                            key={idx}
                            style={{ marginTop: "-15px" }}
                            onMouseEnter={() => setHoverIndex(index)}
                            onMouseLeave={() => setHoverIndex(null)}
                          >
                            <S.ParentCommentList>
                              <S.Profile src={Profile} alt="profile" />
                              <S.CommentBox>
                                <S.CommentInfo>
                                  {parents.author}
                                  <p>{parents.createdDate}</p>
                                </S.CommentInfo>
                                <S.CommentContent>
                                  {parents.body}
                                </S.CommentContent>
                              </S.CommentBox>
                            </S.ParentCommentList>
                          </S.CommentContainer>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <S.Info>댓글이 없습니다!</S.Info>
          )}
        </div>
      </S.EpisodeCommentBox>
      <S.InputBox>
        <S.Profile src={Profile} alt="profile" />
        <div>
          <S.CommentInput
            placeholder="독자와 함께 이야기를 나눠보세요 !!"
            value={comment}
            onChange={handleCommentChange}
          />
          <Arrow
            fill="black"
            className="button"
            onClick={() => onSubmitComment()}
          />
        </div>
      </S.InputBox>
    </S.EpisodeCommentContainer>
  );
};

export default EpisodeComment;
