import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const TextField = styled.div<{ error: string; message?: string }>`
  width: 100%;

  padding: 40px 40px 0px 40px;

  position: relative;

  label {
    user-select: none;
    position: absolute;

    left: 40px;
    top: ${(props) => (props.message ? "70%" : "79%")};

    font-size: 18px;

    transform: translateY(-90%);
    transition: all 0.2s ease;

    pointer-events: none;

    color: ${Palette.Neutral.neutral4};
  }

  input:is(:focus, :valid) ~ label {
    font-size: 12px;

    transform: ${(props) =>
      props.error && props.message ? "translateY(-220%)" : "translateY(-310%)"};

    color: ${(props) =>
      props.error ? Palette.error : Palette.Primary.primary5};
  }
`;
export const TextFieldInput = styled.input`
  width: 100%;
  height: 45px;

  color: ${Palette.Neutral.neutral9};

  border: none;
  outline: none;

  border-bottom: 1px solid #e7e8e9;

  font-size: 18px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  margin-bottom: 5px;
  &:focus {
    border: 0;
    border-bottom: 1px solid ${Palette.Primary.primary5};
  }
`;

export const ErrorText = styled.div`
  display: flex;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  padding: 0px 40px 0px 40px;

  font-size: 12px;

  margin-top: 2px;
`;

export const PatternMessage = styled.div`
  font-size: 12px;

  color: ${Palette.Neutral.neutral4};
`;
