import styled from "styled-components";
import { HoverAnimation } from "styles/common.style";
import Palette from "libs/Palette/palette";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 70px;

  white-space: nowrap;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 3;

  background-color: white;
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  box-shadow: 1px 1px 10px 0px rgba(196, 196, 196, 0.2);
`;
export const HeaderLogo = styled.div`
  display: flex;

  padding-left: 20px;

  cursor: pointer;
`;
export const HeaderIconBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding-right: 20px;

  gap: 30px;
`;
export const IconBox = styled.div`
  width: 43px;
  height: 43px;

  ${HoverAnimation}
  img {
    width: 33px;
    height: 33px;
  }
`;

export const SeriesRegisterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 14px;

  width: 107px;
  height: 41px;

  background-color: ${Palette.Primary.primary5};

  border-radius: 8px;
`;
export const SeriesRegisterTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  cursor: pointer;

  user-select: none;
  img {
    padding-right: 5px;
  }
`;

export const SeriesRegisterContainer = styled.div`
  width: 123px;
  height: 53px;
  ${HoverAnimation}
`;
