import { useMutation } from "@tanstack/react-query";
import {
  AuthNumberParam,
  EmailParam,
  SignupParam,
} from "repositories/Auth/Signup/signup.repository";
import signupRepositoryImpl from "repositories/Auth/Signup/signup.repositoryImpl";

export const useEmailMutation = () => {
  const emailmutation = useMutation((data: EmailParam) =>
    signupRepositoryImpl.postEmail(data)
  );
  return emailmutation;
};

export const useAuthNumberMutation = () => {
  const authnumbermutation = useMutation((data: AuthNumberParam) =>
    signupRepositoryImpl.postAuthNumber(data)
  );
  return authnumbermutation;
};

export const useSignupMutation = () => {
  const signupmutation = useMutation((data: SignupParam) =>
    signupRepositoryImpl.postSignup(data)
  );
  return signupmutation;
};
