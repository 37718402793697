import customAxios from "libs/Axios/customAxios";
import { UserInfoType } from "types/User/user.type";
import { UserRepository } from "./user.repository";

class UserRepositoryImpl implements UserRepository {
  public async getUserInfo(): Promise<UserInfoType> {
    const { data } = await customAxios.get("/user/info");
    return data;
  }
}

export default new UserRepositoryImpl();
