import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import Tooni from "assets/img/Tooni.svg";
import useDragFileUpload from "hooks/common/Drag/useDragFileUpload";
import useShortUpload from "hooks/Shorts/useShortUpload";
import { useRecoilState } from "recoil";
import { PageStateAtom } from "stores/Common/common.store";
import { Dispatch, SetStateAction, useState } from "react";
import { ShortModifyParam } from "repositories/ComicUpload/Shorts/short.repository";

interface ShortThumbnailProps {
  setShortSection: Dispatch<SetStateAction<string>>;
  shortid: string | undefined;
  thumbnailModifyForm: any;
  setThumbnailModifyUrl: Dispatch<SetStateAction<string | any>>;
  thumbnailModifyImgRef: any;
  handleModifyThumbnailUpload: () => void;
  thumbnaiModifylUrl: string | any;
  modifyShortData: ShortModifyParam;
}

const ShortThumbnail = ({
  setShortSection,
  thumbnailModifyForm,
  shortid,
  thumbnailModifyImgRef,
  setThumbnailModifyUrl,
  handleModifyThumbnailUpload,
  thumbnaiModifylUrl,
  modifyShortData,
}: ShortThumbnailProps) => {
  const { ...attr } = useShortUpload();
  const [hoverIndex, setHoverIndex] = useState<boolean>(false);
  const [, setPage] = useRecoilState<number>(PageStateAtom);

  const { onChangeFiles, dragRef } = useDragFileUpload({
    File: shortid ? thumbnailModifyForm : attr.thumbnailform,
    setFile: shortid ? setThumbnailModifyUrl : attr.setThumbnailUrl,
    dragRef: shortid ? thumbnailModifyImgRef : attr.thumbnailImgRef,
  });

  return (
    <S.ShortContainer>
      <S.ShortBox>
        <S.RegisterText>
          대표 이미지<span>*</span>
        </S.RegisterText>

        <input
          type="file"
          id="fileUpload"
          accept="image/png, image/jpg"
          style={{ display: "none" }}
          onChange={onChangeFiles}
        />

        {!shortid && attr.thumbnailUrl === undefined ? (
          <S.ShortImgUploadBox className="card">
            <label htmlFor="fileUpload" ref={dragRef}>
              <S.ImgPreview
                alt="preview"
                src={Tooni}
                style={{
                  width: "227px",
                  height: "241px",
                }}
              />
            </label>
          </S.ShortImgUploadBox>
        ) : (
          <S.ShortImgUploadBox
            className="card"
            onMouseEnter={() => setHoverIndex(true)}
            onMouseLeave={() => setHoverIndex(false)}
          >
            <label htmlFor="fileUpload" ref={dragRef}>
              <S.ImgPreview
                alt="preview"
                src={
                  thumbnaiModifylUrl ||
                  modifyShortData.thumbnail ||
                  attr.thumbnailUrl ||
                  Tooni
                }
                style={{
                  width:
                    attr.thumbnailUrl || modifyShortData.thumbnail
                      ? "752px"
                      : "227px",
                  height:
                    attr.thumbnailUrl || modifyShortData.thumbnail
                      ? "468px"
                      : "241px",
                }}
              />
            </label>

            {hoverIndex && (
              <S.ImgHoverAddBox>
                <label htmlFor="fileUpload" ref={dragRef}>
                  <S.ImgPreview
                    alt="preview"
                    src={Tooni}
                    style={{
                      width: "227px",
                      height: "241px",
                    }}
                  />
                </label>
              </S.ImgHoverAddBox>
            )}
          </S.ShortImgUploadBox>
        )}

        <S.ShortRegisterButton>
          <RegisterButton
            width={12}
            height={44}
            radius={8}
            fontsize={16}
            positions={true}
            buttonType="cencel"
            click={() => {
              setPage((prev) => prev - 1);
              setShortSection("info");
            }}
          >
            이전
          </RegisterButton>
          <RegisterButton
            width={21}
            height={44}
            radius={8}
            fontsize={16}
            positions={true}
            buttonType="agree"
            click={() =>
              shortid
                ? handleModifyThumbnailUpload()
                : attr.onShortThumbnailUpload()
            }
          >
            다음
          </RegisterButton>
        </S.ShortRegisterButton>
      </S.ShortBox>
    </S.ShortContainer>
  );
};

export default ShortThumbnail;
