import { EPISODE_SECTION_NAME } from "constants/Episode/episode.constants";
import useEpisodeUpload from "hooks/Episode/useEpisodeUpload";
import { ReactNode, useEffect, useState } from "react";
import EpisodeInfo from "./EpisodeInfo";
import EpisodeSetting from "./EpisodeSetting";
import EpisodeUpload from "./EpisodeUpload";
import { Params, useParams } from "react-router-dom";
import useEpisodeModify from "hooks/Episode/useEpisodeModify";

interface EpisdoeRegisterProps {
  toggleRegisterModal: () => void;
}

const EpisodeRegister = ({ toggleRegisterModal }: EpisdoeRegisterProps) => {
  const {
    episodeSection,
    setEpisodeSection,
    uploadDate,
    setUploadDate,
    onSubmitEpisodUpload,
    episodeCreate,
  } = useEpisodeUpload();
  const { episodeid }: Readonly<Params<"episodeid">> = useParams();
  const { seriesid }: Readonly<Params<"seriesid">> = useParams();
  const { ...modify } = useEpisodeModify({
    seriesId: String(seriesid),
    episodeId: String(episodeid),
  });
  const [, setEpisodePrevSection] = useState(episodeSection);

  const EpisodeComponents: ReactNode[] = [
    <EpisodeInfo />,
    <EpisodeUpload
      setSection={setEpisodeSection}
      episodeid={episodeid}
      episodeImgList={modify.episodeImgList}
    />,
    <EpisodeSetting
      onSubmitModifyEpisode={() =>
        modify.onSubmitModifyEpisode(toggleRegisterModal)
      }
      episodeid={episodeid}
      setModifyUploadDate={modify.setModifyUploadDate}
      modifyUploadDate={modify.modifyUploadDate}
      episodeCreate={episodeCreate}
      setSection={setEpisodeSection}
      setUploadDate={setUploadDate}
      uploadDate={uploadDate}
      handleSeriesUpload={() => onSubmitEpisodUpload(toggleRegisterModal)}
      toggleRegisterModal={toggleRegisterModal}
    />,
  ];

  useEffect(() => {
    setEpisodePrevSection(episodeSection);
  }, [episodeSection]);

  return (
    <>
      {EpisodeComponents.map((component, idx) => {
        return episodeSection === EPISODE_SECTION_NAME[idx].title && component;
      })}
    </>
  );
};

export default EpisodeRegister;
