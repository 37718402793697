import * as S from "./style";
import Modal from "../../common/Modal/index";
import close from "assets/img/Upload/close.svg";
import SeriesRegister from "./SeriesRegisterModal";
import ProgressBar from "components/common/ProgressBar";
import { useRecoilState } from "recoil";
import { PageStateAtom, SectionAtom } from "stores/Common/common.store";
import { Params, useParams } from "react-router-dom";
import { useEffect } from "react";
import useSeriesUpload from "hooks/Series/useSeriesUpload";

interface SeriesRegisterProps {
  seriesAddModal: boolean;
  toggleSeriesAddModal: () => void;
}

const SeriesRegisterModal = ({
  seriesAddModal,
  toggleSeriesAddModal,
}: SeriesRegisterProps) => {
  const [, setSection] = useRecoilState(SectionAtom);
  const [page, setPage] = useRecoilState<number>(PageStateAtom);
  const { seriesid }: Readonly<Params<"seriesid">> = useParams();
  const { onModalClose } = useSeriesUpload();

  useEffect(() => {
    if (seriesAddModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [seriesAddModal]);

  return (
    <Modal isOpen={seriesAddModal}>
      <S.RegisterModalContainer>
        <S.RegisterModalBox>
          <S.RegisterCloseButton>
            <S.IconBox onClick={() => onModalClose(toggleSeriesAddModal)}>
              <img alt="close" src={close} />
            </S.IconBox>
          </S.RegisterCloseButton>

          <S.ProgressBarBox>
            <div style={{ flexDirection: "column", width: "100%" }}>
              <S.ProgressBarBoxList
                active={page >= 0}
                style={{ width: seriesid ? "180px" : "244px" }}
              >
                <div>1</div>
                <div>작품 정보 입력</div>
              </S.ProgressBarBoxList>
              <ProgressBar active={page >= 0} width={seriesid ? 180 : 244} />
            </div>
            <div style={{ flexDirection: "column", width: "100%" }}>
              <S.ProgressBarBoxList
                active={page >= 1}
                style={{ width: seriesid ? "180px" : "244px" }}
              >
                <div>2</div>
                <div>썸네일 설정</div>
              </S.ProgressBarBoxList>
              <ProgressBar active={page >= 1} width={seriesid ? 180 : 244} />
            </div>
            <div style={{ flexDirection: "column", width: "100%" }}>
              <S.ProgressBarBoxList
                active={page >= 2}
                style={{ width: seriesid ? "180px" : "244px" }}
              >
                <div>3</div>
                <div>연재 주기 설정</div>
              </S.ProgressBarBoxList>
              <ProgressBar active={page >= 2} width={seriesid ? 180 : 244} />
            </div>
            {seriesid && (
              <div style={{ flexDirection: "column", width: "100%" }}>
                <S.ProgressBarBoxList
                  active={page >= 3}
                  style={{ width: seriesid ? "180px" : "244px" }}
                >
                  <div>4</div>
                  <div>연재 상태 설정</div>
                </S.ProgressBarBoxList>
                <ProgressBar active={page >= 3} width={seriesid ? 180 : 244} />
              </div>
            )}
          </S.ProgressBarBox>

          <div style={{ overflow: "auto", justifyContent: "center" }}>
            <SeriesRegister toggleRegisterModal={toggleSeriesAddModal} />
          </div>
        </S.RegisterModalBox>
      </S.RegisterModalContainer>
    </Modal>
  );
};

export default SeriesRegisterModal;
