import { Route, Routes } from "react-router-dom";
import AuthPage from "pages/AuthPage/AuthPage";
import NotFound from "components/common/NotFound";
import SeriesManagementPage from "pages/SeriesManagementPage/SeriesManagementPage";
import useRouteChangeTracker from "hooks/common/Route/useRouteChangeTracker";
import SeriesToonPage from "pages/SeriesToonPage/SeriesToonPage";
import { ShorToonPage } from "pages/ShorToonPage/ShorToonPage";
import { EpisodeManagementPage } from "pages/EpisodeManagementPage/EpisodeManagementPage";
import { ShortManagementPage } from "pages/ShortManagementPage/ShortManagementPage";
import useTokenCheck from "hooks/Auth/Login/useTokenCheck";
import { MyPage } from "pages/MyPage/MyPage";

export default function Router() {
  useRouteChangeTracker();
  useTokenCheck();

  return (
    <Routes>
      <Route path="/" element={<AuthPage />} />
      <Route path="/shorts" element={<ShorToonPage />} />
      <Route path="/series" element={<SeriesToonPage />} />
      <Route path="/short/manage/:shortid" element={<ShortManagementPage />} />
      <Route
        path="/series/manage/:seriesid"
        element={<SeriesManagementPage />}
      />
      <Route
        path="/series/manage/:seriesid/:episodeid"
        element={<EpisodeManagementPage />}
      />
      <Route path="/my" element={<MyPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
