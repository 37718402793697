import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import { useRecoilState } from "recoil";
import {
  ImageAverageColorAtom,
  SelectFontAtom,
} from "stores/Series/series.store";
import Palette from "libs/Palette/palette";
import { Dispatch, SetStateAction, useState } from "react";
import { HuePicker } from "react-color";
import { SeriesParam } from "repositories/ComicUpload/Series/series.repository";
import useSeriesUpload from "hooks/Series/useSeriesUpload";
import { ImgAtom } from "stores/Series/series.store";
import { useGetFont } from "queries/common/Font/font.query";
import { PageStateAtom } from "stores/Common/common.store";
import { useGetTitleMaker } from "queries/ComicUpload/Series/series.query";

interface ThumbnailProps {
  fontSize: number;
  titleMakerData: SeriesParam;
  setSection: Dispatch<SetStateAction<string>>;
  setSelectFont: Dispatch<SetStateAction<string>>;
  modifySeriesData: SeriesParam;
  seriesid: string | undefined;
  setModifySeriesData: Dispatch<SetStateAction<SeriesParam>>;
  onThumbailRouter: () => void;
}

const Thumbnail = ({
  titleMakerData,
  fontSize,
  setSelectFont,
  setSection,
  modifySeriesData,
  seriesid,
  setModifySeriesData,
  onThumbailRouter,
}: ThumbnailProps) => {
  const { data: getFontData } = useGetFont();
  const { handleColor, cardRef } = useSeriesUpload();
  const [averageColor] = useRecoilState<string[]>(ImageAverageColorAtom);
  const [selectfont] = useRecoilState<string>(SelectFontAtom);
  const [imgSrc] = useRecoilState<string | undefined>(ImgAtom);
  const [, setPage] = useRecoilState<number>(PageStateAtom);

  const { data: getTitleMaker } = useGetTitleMaker(
    titleMakerData.title,
    selectfont,
    false
  );

  let hexToHsl = require("hex-to-hsl");
  let hsl = require("hsl-to-hex");
  let [selected, setSelected] = useState("");

  const handleChangeComplete = (newColor: any) => {
    if (titleMakerData) {
      let hslColor = hexToHsl(newColor.hex);

      hslColor[2] = 15;
      const adjustedColor = hsl(hslColor[0], hslColor[1], hslColor[2]);
      handleColor(adjustedColor);
    }
  };

  return (
    <>
      <S.SeriesImgContainer>
        <S.SeriesImgList>
          <S.SeriesImgBox className="card">
            <S.RegisterText>썸네일 미리보기</S.RegisterText>

            {/* <S.SeriesImgUploadBox>
              <S.ThumbnailImg
                alt="thumbnail"
                src={imgSrc || modifySeriesData.titleMaker.backgroundImage}
              />

              <S.Gradient
                style={{
                  background: `linear-gradient(#00000000 150px , ${
                    seriesid
                      ? modifySeriesData.titleMaker.color
                      : titleMakerData.titleMaker.color
                  })`,
                }}
              ></S.Gradient>
              <S.TitleChangeBox>
                <S.Title ref={cardRef}>
                  <img
                    style={{
                      width: "100%",
                      padding: "14px",
                    }}
                    alt="title"
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      getTitleMaker || ""
                    )}`}
                  />
                </S.Title>
              </S.TitleChangeBox>
            </S.SeriesImgUploadBox> */}

            <S.SeriesImgUploadBox>
              <S.ThumbnailImg
                alt="thumbnail"
                src={imgSrc || modifySeriesData.titleMaker.backgroundImage}
              />

              <S.Gradient
                style={{
                  background: `linear-gradient(#00000000 150px , ${
                    seriesid
                      ? modifySeriesData.titleMaker.color
                      : titleMakerData.titleMaker.color
                  })`,
                }}
              ></S.Gradient>
              <S.TitleChangeBox>
                <S.Title ref={cardRef}>
                  <img
                    style={{
                      width: "100%",
                      padding: "14px",
                      // backgroundColor: "red",
                    }}
                    alt="title"
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      getTitleMaker || ""
                    )}`}
                  />
                </S.Title>
              </S.TitleChangeBox>
            </S.SeriesImgUploadBox>
          </S.SeriesImgBox>
          <div>
            <S.RegisterText>
              폰트<span>*</span>
            </S.RegisterText>

            <S.SeriesFontBox>
              {getFontData?.titleMakerFonts.map((item, index) => {
                return (
                  <S.FontButton
                    key={index}
                    onClick={() => {
                      seriesid
                        ? setModifySeriesData((prev) => ({
                            ...prev,
                            titleMaker: {
                              ...prev.titleMaker,
                              font: item.font,
                            },
                          }))
                        : setSelectFont(item.font);
                    }}
                    style={{
                      fontFamily: item.font,
                      border: seriesid
                        ? modifySeriesData.titleMaker.font == item.font
                          ? `2px solid ${Palette.Primary.primary5}`
                          : ``
                        : selectfont == item.font
                        ? `2px solid ${Palette.Primary.primary5}`
                        : ``,

                      color: seriesid
                        ? modifySeriesData.titleMaker.font == item.font
                          ? `${Palette.Neutral.neutral9}`
                          : `${Palette.Neutral.neutral4}`
                        : selectfont == item.font
                        ? `${Palette.Neutral.neutral9}`
                        : `${Palette.Neutral.neutral4}`,
                    }}
                  >
                    <S.FontImg
                      alt="font"
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        item.preview.replaceAll("white", "black")
                      )}`}
                    />
                  </S.FontButton>
                );
              })}
            </S.SeriesFontBox>

            <S.RegisterText>
              배경 컬러<span>*</span>
            </S.RegisterText>
            <S.ColorBox>
              {Array.from({ length: 6 }).map((_, index) => {
                const color = averageColor[index] || "";
                let hslColor = color ? hexToHsl(color) : "";

                if (color) {
                  hslColor[2] = 15;
                }

                const isSelected = selected === color;

                return (
                  <S.ColorButton
                    key={index}
                    style={{
                      backgroundColor: color
                        ? `hsl(${hslColor[0]}, ${hslColor[1]}%, ${hslColor[2]}%)`
                        : `${Palette.backgound1}`,
                      border: isSelected ? `3px solid ${Palette.basics}` : "",
                    }}
                    onClick={() => {
                      if (color) {
                        setSelected(color);
                        if (seriesid) {
                          setModifySeriesData((prev) => ({
                            ...prev,
                            titleMaker: {
                              ...prev.titleMaker,
                              color: hsl(hslColor[0], hslColor[1], hslColor[2]),
                            },
                          }));
                        } else {
                          handleColor(
                            hsl(hslColor[0], hslColor[1], hslColor[2])
                          );
                        }
                      }
                    }}
                  ></S.ColorButton>
                );
              })}
            </S.ColorBox>

            <S.RegisterText>
              원하시는 컬러가 없나요?<span>*</span>
            </S.RegisterText>

            <HuePicker
              color={titleMakerData.titleMaker.color}
              onChangeComplete={handleChangeComplete}
              width="100%"
              height="24px"
            />
          </div>
        </S.SeriesImgList>
      </S.SeriesImgContainer>
      <S.SeriesRegisterButton>
        <RegisterButton
          width={12}
          height={44}
          radius={8}
          fontsize={16}
          positions={true}
          buttonType="cencel"
          click={() => {
            setPage((prev) => prev - 1);
            setSection("info");
          }}
        >
          이전
        </RegisterButton>
        <RegisterButton
          width={21}
          height={44}
          radius={8}
          fontsize={16}
          positions={true}
          buttonType="agree"
          click={() => onThumbailRouter()}
        >
          다음
        </RegisterButton>
      </S.SeriesRegisterButton>
    </>
  );
};

export default Thumbnail;
