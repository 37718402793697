import showAlert from "libs/Toast/toast";
import * as S from "./style";
import { SeriesDate, SeriesPeriod } from "utils/TitleMaker/seriesData";
import { useGetEpisode } from "queries/ComicUpload/Episode/episode.query";
import Palette from "libs/Palette/palette";
import Calendars from "components/common/Calendar";
import { Dispatch, FormEvent, SetStateAction } from "react";
import RegisterButton from "components/common/Button/Register";
import { useRecoilState } from "recoil";
import { PageStateAtom } from "stores/Common/common.store";
import Loading from "components/common/Loading";

interface EpisodeProps {
  setSection: Dispatch<SetStateAction<string>>;
  uploadDate: string;
  setUploadDate: Dispatch<SetStateAction<string>>;
  handleSeriesUpload: (e: FormEvent) => void;
  toggleRegisterModal: () => void;
  episodeCreate: any;
  episodeid: string | undefined;
  setModifyUploadDate: Dispatch<SetStateAction<string>>;
  modifyUploadDate: string;
  onSubmitModifyEpisode: () => void;
}

const EpisodeSetting = ({
  setSection,
  uploadDate,
  setUploadDate,
  handleSeriesUpload,
  episodeCreate,
  episodeid,
  setModifyUploadDate,
  modifyUploadDate,
  onSubmitModifyEpisode,
}: EpisodeProps) => {
  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const { data: getEpisodeList } = useGetEpisode({
    id: sessionStorage.getItem("SeriesId"),
  });

  return (
    <S.EpisodeUploadContainer>
      {episodeCreate.isLoding ? (
        <Loading>회차 등록 중...</Loading>
      ) : (
        <S.EpisodeListBox>
          <S.RegisterText>정해진 연재 주기</S.RegisterText>
          <S.PeriodBox>
            {SeriesPeriod.map((episode) => (
              <S.PeriodButton
                onClick={() => showAlert("수정이 불가합니다.", "INFO")}
                style={{
                  border:
                    getEpisodeList && getEpisodeList.cycle == episode.value
                      ? `2px solid ${Palette.basics}`
                      : ``,
                  color:
                    getEpisodeList && getEpisodeList.cycle == episode.value
                      ? `${Palette.Neutral.neutral9}`
                      : `${Palette.Neutral.neutral4}`,
                }}
              >
                {episode.period}
              </S.PeriodButton>
            ))}
          </S.PeriodBox>
          <S.RegisterText>권장 연재 요일</S.RegisterText>
          <S.DateBox>
            {SeriesDate.map((date) => (
              <S.DateButton
                onClick={() => showAlert("수정이 불가합니다.", "INFO")}
                style={{
                  border:
                    getEpisodeList && getEpisodeList.dayOfWeek == date.value
                      ? `2px solid ${Palette.Primary.primary5}`
                      : ``,
                  color:
                    getEpisodeList && getEpisodeList.dayOfWeek == date.value
                      ? `${Palette.Neutral.neutral9}`
                      : `${Palette.Neutral.neutral4}`,
                }}
              >
                {date.day}
              </S.DateButton>
            ))}
          </S.DateBox>
          <S.Text>
            권장 연재 날짜
            <S.Icon />
          </S.Text>

          <S.CalendarBox>
            <Calendars
              setUploadDate={episodeid ? setModifyUploadDate : setUploadDate}
              uploadDate={episodeid ? modifyUploadDate : uploadDate}
            />
          </S.CalendarBox>
          <S.EpisodeRegisterButton>
            <RegisterButton
              width={12}
              height={44}
              radius={8}
              fontsize={16}
              positions={true}
              buttonType="cencel"
              click={() => {
                setSection("webtoon");
                setPage((prev) => prev - 1);
              }}
            >
              이전
            </RegisterButton>
            <RegisterButton
              width={20}
              height={44}
              radius={8}
              fontsize={16}
              positions={true}
              buttonType="agree"
              click={(e) =>
                episodeid ? onSubmitModifyEpisode() : handleSeriesUpload(e)
              }
            >
              다음
            </RegisterButton>
          </S.EpisodeRegisterButton>
        </S.EpisodeListBox>
      )}
    </S.EpisodeUploadContainer>
  );
};

export default EpisodeSetting;
