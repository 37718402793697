import { styled } from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 50vh;
`;
export const LoadingText = styled.div`
  font-size: 24px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  margin-top: 15px;
`;
