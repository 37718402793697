import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const MyContainer = styled.div`
  display: flex;
  justify-content: center;

  background-color: ${Palette.backgound2};

  width: 100%;
  height: 100vh;

  padding: 147px 0px 0px 220px;
`;

export const MyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 820px;
  height: 535px;

  border-radius: 10px;

  background-color: white;
`;

export const ProfileBox = styled.div``;
export const ProfileImg = styled.img`
  width: 180px;

  padding-top: 30px;
  margin-bottom: 40px;
`;

export const Input = styled.input<{ width: boolean }>`
  width: ${(props) => (props.width ? "366px" : "752px")};
  height: 50px;

  border-radius: 10px;
  border: 1px solid #ccced1;

  font-size: 18px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  padding-left: 20px;

  &:focus {
    outline: none;
    border: 1px solid ${Palette.Primary.primary5};
  }
`;
export const Title = styled.p`
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;
  font-size: 16px;

  margin-bottom: 7px;

  padding-left: 7px;
`;

export const UserInfoBox = styled.div`
  display: flex;

  gap: 20px;

  margin-bottom: 30px;
`;

export const ButtonContainer = styled.div`
  width: 752px;

  display: flex;
  justify-content: flex-end;
`;

export const EmailBox = styled.div`
  margin-bottom: 30px;
`;

export const Icon = styled.img`
  margin-left: 5px;

  cursor: pointer;
`;
