import { atom } from "recoil";

export const ErrorStateAtom = atom<Record<string, string>>({
  key: "ErrorStateAtom",
  default: {},
});

export const HideHeader = atom<boolean>({
  key: "HideHeader",
  default: false,
});

export const HideSidebar = atom<boolean>({
  key: "HideSidebar",
  default: false,
});

export const PageStateAtom = atom<number>({
  key: "PageStateAtom",
  default: 0,
});

export const SectionAtom = atom<string>({
  key: "SectionAtom",
  default: "info",
});
