import Modal from "components/common/Modal";
import * as S from "./style";
import { useGetEpisodeList } from "queries/ComicUpload/Episode/episode.query";
import Eyes from "assets/img/Episode/Episodeeyes.svg";
import Communication from "assets/img/Episode/Communication.svg";
import { ReactComponent as Health } from "assets/img/Episode/Health.svg";
import close from "assets/img/Upload/close.svg";
import { useEpisodeDelete } from "hooks/Episode/useEpisodeDelete";

interface EpisodeDeleteProps {
  episodeDeleteModal: boolean;
  toggleEpisodeDeleteModal: () => void;
}

const EpisodeDeleteModal = ({
  episodeDeleteModal,
  toggleEpisodeDeleteModal,
}: EpisodeDeleteProps) => {
  const { data: getEpisodeList } = useGetEpisodeList({
    seriesId: Number(sessionStorage.getItem("SeriesId")),
    episodeNumber: Number(sessionStorage.getItem("EpisodeNumber")),
  });

  const {
    onSubmitEpisodeDelete,
    handleEpisodeDeleteCheck,
    deleteEpisodeCheck,
  } = useEpisodeDelete(toggleEpisodeDeleteModal);

  return (
    <Modal isOpen={episodeDeleteModal}>
      <S.ModalContainer>
        <S.ModalBox>
          <S.IconBox>
            <img
              alt="close"
              src={close}
              onClick={() => toggleEpisodeDeleteModal()}
            />
          </S.IconBox>
          <S.TitleBox>
            <S.Title isColor={true}>{getEpisodeList?.seriesTitle}</S.Title>
            <S.Title isColor={false}>
              "{getEpisodeList?.episodeTitle}" 를 삭제하시겠어요?
            </S.Title>
          </S.TitleBox>
          <S.PerformanceContainer>
            <S.PerformanceBoc>
              <S.Icon alt="eyes" src={Eyes} />
              <div>{getEpisodeList?.views}회</div>
            </S.PerformanceBoc>
            <S.PerformanceBoc>
              <Health fill="black" />
              <div>{getEpisodeList?.likeCount}개</div>
            </S.PerformanceBoc>
            <S.PerformanceBoc>
              <S.Icon alt="communication" src={Communication} />
              <div>{getEpisodeList?.commentCount}개</div>
            </S.PerformanceBoc>
          </S.PerformanceContainer>
          <S.DeleteInfoBox>
            <p>
              삭제를 하기 위해서,"{getEpisodeList?.episodeTitle}" 박스 안에
              입력해주세요<span>*</span>
            </p>
            <S.DeleteInput onChange={handleEpisodeDeleteCheck} />
          </S.DeleteInfoBox>

          <S.DeleteButtonBox>
            <S.DeleteButton
              disabled={deleteEpisodeCheck !== getEpisodeList?.episodeTitle}
              onClick={() =>
                onSubmitEpisodeDelete(
                  sessionStorage.getItem("SeriesId"),
                  Number(sessionStorage.getItem("EpisodeNumber")),
                  getEpisodeList?.episodeTitle,
                  deleteEpisodeCheck
                )
              }
            >
              삭제
            </S.DeleteButton>
          </S.DeleteButtonBox>
        </S.ModalBox>
      </S.ModalContainer>
    </Modal>
  );
};

export default EpisodeDeleteModal;
