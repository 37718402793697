import * as S from "./style";
import React, { Suspense } from "react";
import ErrorBoundary from "components/common/ErrorBoundary";
import SkeletonComponent from "components/common/Skeleton";

const ComicShorts = () => {
  const ShortItem = React.lazy(() => import("./ShortItem"));

  return (
    <S.ShortContainer>
      <S.ShortHeader>
        <S.Title>단편 웹툰 관리</S.Title>
      </S.ShortHeader>
      <ErrorBoundary fallback={<>에러</>}>
        <Suspense fallback={<SkeletonComponent height={310} top={false} />}>
          <ShortItem />
        </Suspense>
      </ErrorBoundary>
    </S.ShortContainer>
  );
};

export default ComicShorts;
