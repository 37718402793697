export const QUERY_KEYS = {
  user: {
    userInfo: "user/info",
  },
  short: {
    shortMyList: (page: number) => ["/comic/shorts/list", page],
    shortList: (shortsId: number) => ["/comic/shorts/", shortsId],
  },
  series: {
    seriesList: (page: number) => ["/comic/series/list", page],
    titlemaker: (title: string, font: string, alignLeft: boolean) => [
      "/comic/titlemaker",
      title,
      font,
      alignLeft,
    ],
  },
  episode: {
    episodeMyList: (id: string | null) => ["/comic/series/episode/my/", id],
    episodeList: (seriesId: number, episodeNumber: number) => [
      "/comic/series/episode",
      seriesId,
      episodeNumber,
    ],
  },
  comment: {
    comment: (episodeId: number) => ["/comment", episodeId],
  },
};
