import {
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "queries/queryKey";
import { CommentDataParam } from "repositories/Comment/comment.repository";
import commentRepositoryImpl from "repositories/Comment/comment.repositoryImpl";
import { CommentType } from "types/Comment/comment.type";

export const usePostComment = () => {
  const commentcreatemutation = useMutation(
    (data: { commentData: CommentDataParam; episodeId: number }) =>
      commentRepositoryImpl.postComment(data.episodeId, data.commentData)
  );
  return commentcreatemutation;
};

export const useGetComment = (
  episodeId: number,
  options?: UseQueryOptions<
    CommentType,
    AxiosError,
    CommentType,
    (string | number)[]
  >
): UseQueryResult<CommentType> =>
  useQuery(
    QUERY_KEYS.comment.comment(episodeId),
    () => commentRepositoryImpl.getComment(episodeId),
    {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      ...options,
    }
  );
