import { MutableRefObject, useCallback, useRef } from "react";

const useTitleMaker = () => {
  const imgRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  //파일 style 변경 위한
  const onClickImg = () => {
    if (imgRef.current) {
      imgRef.current.click();
    }
  };

  //제목 3줄 제한
  const handleKeyDown = useCallback((value: HTMLTextAreaElement) => {
    let row = value.value.split("\n").length - 1;
    if (row > 2) {
      alert("3줄까지만 작성 가능합니다");
    }
  }, []);

  return {
    imgRef,
    onClickImg,
    handleKeyDown,
  };
};

export default useTitleMaker;
