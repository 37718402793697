import { styled } from "styled-components";
import Palette from "libs/Palette/palette";

const ThumbnailWidth: string = "320px";

export const SeriesManagementContainer = styled.div`
  width: 100%;
  height: 100vh;

  margin-left: 240px;
`;

export const ThumbnailPreviewBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;

  width: 360px;
  height: 630px;

  border-radius: 10px;
`;
export const SeriesThumbnail = styled.div`
  width: ${ThumbnailWidth};

  min-height: 425px;

  background-color: gray;

  border-radius: 10px;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;

  height: 76px;
  margin-left: 16px;

  gap: 13px;
`;

export const Title = styled.p<{ fontColor: boolean }>`
  font-size: 22px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${(props) =>
    props.fontColor
      ? `${Palette.Neutral.neutral4}`
      : `${Palette.Neutral.neutral9}`};

  cursor: pointer;
`;

export const SeriesInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${Palette.backgound2};

  width: 100%;

  margin-top: 70px;
  padding-left: 25px;
`;

export const SeriesTitle = styled.p`
  font-size: 18px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  margin-top: 15px;
  max-width: ${ThumbnailWidth};
`;

export const SeriesContents = styled.p`
  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
  color: ${Palette.Neutral.neutral7};

  margin-top: 10px;

  height: 38px;

  max-width: ${ThumbnailWidth};
`;

export const SeriesTagWrap = styled.div`
  max-width: ${ThumbnailWidth};
`;

export const SeriesTag = styled.div`
  display: inline-block;

  font-size: 14px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  background-color: ${Palette.Neutral.neutral1};
  color: ${Palette.Neutral.neutral7};

  border-radius: 6px;

  margin-right: 5px;
  margin-bottom: 16px;
  padding: 5px 10px 5px 10px;
`;

export const SeriesListContainer = styled.div`
  margin-left: 25px;

  width: 1120px;

  background-color: white;

  border-radius: 10px;
`;

export const SeriesListInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;

  width: 300px;

  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
`;

export const SeriesListHeaderText = styled.div<{ margin: string }>`
  margin-left: ${({ margin }) => margin};
  position: absolute;

  font-size: 14px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  color: ${Palette.Neutral.neutral6};
`;

export const SeriesListHeader = styled.div`
  display: flex;

  width: 1120px;
  height: 55px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const SeiresListLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Palette.Neutral.neutral2};
`;

export const SeriesDate = styled.div`
  display: flex;
  justify-content: center;

  font-size: 16px;
  color: #7c8188;
  font-family: Pretendard-Regular, Apple SD Gothic Neo, Malgun Gothic;

  margin-top: 17px;
`;

export const SeriesAddButton = styled.button<{
  backgroundColor: string;
  color: string;
}>`
  padding: 16px 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  box-shadow: none;

  width: 123px;
  height: 41px;
`;

export const SeriesAddContext = styled.p`
  font-size: 14px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  margin-left: 7px;
`;

export const SeriesAddContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 0px;
`;

export const BackGroundImg = styled.img`
  height: 426px;
  width: 320px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
`;

export const Gradient = styled.div`
  position: absolute;

  height: 427px;
  width: 320px;

  border-radius: 10px;
`;

export const TitleImgBox = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100px;
`;

export const TitleImg = styled.img`
  display: flex;
  align-items: center;
  width: 206.25px;
  height: 80px;
  object-fit: contain;
`;

export const SerialListBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  position: relative;

  margin-top: 20px;
`;

export const SeriesInfo = styled.div`
  width: 300px;
`;

export const HeaderList = styled.div`
  display: flex;
  align-items: center;

  user-select: none;
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100px;

  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
`;
