import {
  EpisodeListId,
  EpisodeListType,
  SeriesEpisodeType,
  SeriseEpisodeId,
} from "types/Series/series.type";
import { EpisodeRepository } from "./episode.repository";
import customAxios from "libs/Axios/customAxios";
import { EpisodeUploadParam } from "./episode.repository";
import { EpisodeId } from "types/Serial/serial.type";

class EpisodeRepositoryImpl implements EpisodeRepository {
  public async getSeriesEpisodeMyList({
    id,
  }: SeriseEpisodeId): Promise<SeriesEpisodeType> {
    const { data } = await customAxios.get(`/comic/series/episode/my/${id}`);
    return data;
  }
  public async getEpisodeList({
    seriesId,
    episodeNumber,
  }: EpisodeListId): Promise<EpisodeListType> {
    const { data } = await customAxios.get(
      `/comic/series/episode/${seriesId}/${episodeNumber}`
    );
    return data;
  }

  public async postEpisodeCreate(
    seriesId: number,
    episodeId: number,
    episodeData: EpisodeUploadParam
  ): Promise<void> {
    const { data } = await customAxios.post(
      `/comic/series/episode/create/${seriesId}/${episodeId}`,
      episodeData
    );
    return data;
  }

  public async deleteEpisode({
    episodeId,
    seriesId,
  }: EpisodeId): Promise<void> {
    const { data } = await customAxios.delete(
      `comic/series/delete/${seriesId}/${episodeId}`
    );
    return data;
  }

  public async patchEpisode(
    seriesId: number,
    episodeId: number,
    episodeData: EpisodeUploadParam
  ): Promise<void> {
    await customAxios.put(
      `/comic/series/episode/update/${seriesId}/${episodeId}`,
      episodeData
    );
  }
}

export default new EpisodeRepositoryImpl();
