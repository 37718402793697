import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const ShortContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ShortBox = styled.div`
  width: 100%;
`;

export const RegisterText = styled.p`
  margin-bottom: 8px;
  margin-left: 5px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${Palette.Neutral.neutral7};

  span {
    color: ${Palette.error};
  }
`;

export const ShortRegisterButton = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;

  gap: 10px;

  margin-top: 94px;
`;

export const ShortImgUploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 752px;
  height: 468px;

  border: 1px solid ${Palette.Neutral.neutral2};
  border-radius: 10px;

  margin-bottom: 91px;

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const ImgPreview = styled.img`
  border-radius: 10px;
`;

export const ImgHoverAddBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  background-color: rgba(255, 255, 255, 0.65);

  width: 752px;
  height: 468px;

  border-radius: 10px;
`;
