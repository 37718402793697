import TextField from "components/common/TextField";
import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import { SignupType } from "types/Auth/signup.type";
import { Dispatch, SetStateAction } from "react";
import RunningLoading from "components/common/Loading/Running";

interface AuthEmailProps extends SignupType {
  onEmailOverlap: () => void;
  setIsLogin: Dispatch<SetStateAction<boolean>>;
  EmailMutation: any;
}

const Email = ({
  handleSignupChange,
  onEmailOverlap,
  signupData,
  setIsLogin,
  EmailMutation,
}: AuthEmailProps) => {
  return (
    <>
      {EmailMutation.isLoading ? (
        <RunningLoading />
      ) : (
        <>
          <S.EmailInputBox>
            <TextField
              onchange={handleSignupChange}
              id="email"
              name="email"
              functions={onEmailOverlap}
              value={signupData.email}
            >
              이메일 주소
            </TextField>
          </S.EmailInputBox>

          <S.LoginButtonBox>
            <RegisterButton
              width={30}
              height={53}
              radius={8}
              fontsize={18}
              click={() => {
                setIsLogin(true);
              }}
              positions={true}
              buttonType="cencel"
            >
              이전
            </RegisterButton>
            <RegisterButton
              width={100}
              height={53}
              radius={8}
              fontsize={18}
              click={() => {
                onEmailOverlap();
              }}
              positions={true}
              buttonType="agree"
              disabled={signupData.email.length === 0}
            >
              이메일 인증하기
            </RegisterButton>
          </S.LoginButtonBox>
        </>
      )}
    </>
  );
};

export default Email;
