import axios from "axios";
import CONFIG from "config/config.json";
import { LoginType } from "types/Auth/login.type";
import { LoginParam, LoginRepository } from "./login.repository";
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "constants/Token/token.constants";

class LoginRepositoryImpl implements LoginRepository {
  public async postLogin(LoginData: LoginParam): Promise<LoginType> {
    const { data } = await axios.post(`${CONFIG.TEST}/user/login`, LoginData);

    localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken);
    return data;
  }
}

export default new LoginRepositoryImpl();
