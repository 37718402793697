import * as S from "./style";
import { useGetEpisode } from "queries/ComicUpload/Episode/episode.query";
import useEpisodeUpload from "hooks/Episode/useEpisodeUpload";
import useDragFileUpload from "hooks/common/Drag/useDragFileUpload";
import Tooni from "assets/img/Tooni.svg";
import RegisterButton from "components/common/Button/Register";
import showAlert from "libs/Toast/toast";
import { Params, useParams } from "react-router-dom";
import useEpisodeModify from "hooks/Episode/useEpisodeModify";
import { useState } from "react";

const EpisodeInfo = () => {
  const { episodeid }: Readonly<Params<"episodeid">> = useParams();
  const { seriesid }: Readonly<Params<"seriesid">> = useParams();

  const { data: getEpisodeList } = useGetEpisode({
    id: sessionStorage.getItem("SeriesId"),
  });

  const { ...modify } = useEpisodeModify({
    seriesId: String(seriesid),
    episodeId: String(episodeid),
  });
  const { ...attr } = useEpisodeUpload();
  const [hoverIndex, setHoverIndex] = useState<boolean>(false);

  const { onChangeFiles, dragRef } = useDragFileUpload({
    File: episodeid ? modify.backGroundModifyFil : attr.thumbnailform,
    setFile: episodeid ? modify.setModifyImgSrc : attr.setThumbnailUrl,
    dragRef: episodeid ? modify.imgModifyRef : attr.thumbnailImgRef,
  });

  return (
    <>
      <S.EpisodeListBox>
        <S.RegisterText isEdit={true}>
          작품명<span>*</span>
        </S.RegisterText>
        <S.TitlePreview onClick={() => showAlert("수정이 불가합니다.", "INFO")}>
          {getEpisodeList?.title}
        </S.TitlePreview>

        <S.RegisterText>
          회차명<span>*</span>
        </S.RegisterText>
        <S.Input
          placeholder="회차명을 입력해 주세요"
          onChange={
            episodeid ? modify.handleEpisodeModify : attr.handleInputChange
          }
          id="title"
          name="title"
          value={
            episodeid ? modify.modifyEpisodeData.title : attr.seriesData.title
          }
        />
        <S.RegisterText>
          대표 이미지<span>*</span>
        </S.RegisterText>

        <input
          type="file"
          id="fileUpload"
          accept="image/png, image/jpg"
          style={{ display: "none" }}
          onChange={onChangeFiles}
        />

        {!episodeid && attr.RoundThumbnailUrl === undefined ? (
          <S.EpisodeImgUploadBox className="card">
            <label htmlFor="fileUpload" ref={dragRef}>
              <S.ImgPreview
                alt="preview"
                src={Tooni}
                style={{
                  width: "227px",
                  height: "241px",
                }}
              />
            </label>
          </S.EpisodeImgUploadBox>
        ) : (
          <S.EpisodeImgUploadBox
            className="card"
            onMouseEnter={() => setHoverIndex(true)}
            onMouseLeave={() => setHoverIndex(false)}
          >
            <label htmlFor="fileUpload" ref={dragRef}>
              <S.ImgPreview
                alt="preview"
                src={
                  modify.modifyImgUrl ||
                  modify.modifyEpisodeData.thumbnail ||
                  attr.RoundThumbnailUrl
                }
                style={{
                  width: "485px",
                  height: "302px",
                  position: "relative",
                }}
              />
            </label>

            {hoverIndex && (
              <S.ImgHoverAddBox>
                <label htmlFor="fileUpload" ref={dragRef}>
                  <S.ImgPreview
                    alt="preview"
                    src={Tooni}
                    style={{
                      width: "227px",
                      height: "241px",
                    }}
                  />
                </label>
              </S.ImgHoverAddBox>
            )}
          </S.EpisodeImgUploadBox>
        )}

        <S.EpisodeRegisterButton>
          <RegisterButton
            width={20}
            height={44}
            radius={8}
            fontsize={16}
            positions={true}
            buttonType="agree"
            click={() => {
              episodeid
                ? modify.onEpisodeModifyThumbnailUpload()
                : attr.handleEpisodeInfoUpload();
            }}
          >
            다음
          </RegisterButton>
        </S.EpisodeRegisterButton>
      </S.EpisodeListBox>
    </>
  );
};

export default EpisodeInfo;
