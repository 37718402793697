import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const TestT = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  @keyframes slide {
    from {
      right: -200px;
      overflow: hidden;
      display: none;
      width: 100%;
    }
    to {
      overflow: hidden;
      right: 0;
      width: 100%;
    }
  }
`;

export const IdInputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 85px;
`;

export const SignupState = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  input {
    width: 20px;
    height: 20px;

    margin-right: 10px;

    border: 2px solid ${Palette.Neutral.neutral5};
    border-radius: 3px;
  }

  p {
    font-size: 16px;
    color: ${Palette.Neutral.neutral5};
  }
`;

export const IdButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 121px 40px 0px 40px;

  gap: 10px;
`;
