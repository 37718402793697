import { css, keyframes } from "styled-components";

export const HoverAnimation = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transform: scale(1);
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #ebefff;
    transform: scale(0.98);
  }
`;

const skeleton = keyframes`
    0% {
      background-color: rgba(182, 182, 182, 0.1);
    }
    50% {
      background-color: rgba(182, 182, 182, 0.25);
    }
    100% {
      background-color: rgba(182, 182, 182, 0.1);
    }
`;

export const SkeletonAnimtaion = css`
  animation: ${skeleton} 1s infinite ease-in-out;
`;
