import styled from "styled-components";

export const LoadingText = styled.div`
  font-size: 16px;
  padding-bottom: 20px;

  font-family: Pretendard-Regular, Apple SD Gothic Neo, Malgun Gothic;
`;

export const RunningLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px 5px;

  margin-top: 106px;
`;
