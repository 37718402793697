import { useQueryClient } from "@tanstack/react-query";
import { useDeleteShortList } from "queries/ComicUpload/Short/short.query";
import { ChangeEvent, useCallback, useState } from "react";
import showAlert from "libs/Toast/toast";
import { QUERY_KEYS } from "queries/queryKey";
import { useNavigate } from "react-router-dom";

export function useShortDelete(toggleShortDeleteModal: () => void) {
  const deleteShortListMutation = useDeleteShortList();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [deleteShortCheck, setDeleteShortCheck] = useState<string>("");

  const handleShortDeleteCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setDeleteShortCheck(e.target.value);
  };

  const onSubmitShortDelete = useCallback(
    (shortsId: number, title: string | undefined, check: string) => {
      if (title === check) {
        deleteShortListMutation.mutate(
          {
            shortsId: shortsId,
          },
          {
            onSuccess: () => {
              showAlert("단편웹툰 삭제성공", "SUCCESS");
              queryClient.invalidateQueries(QUERY_KEYS.short.shortMyList(1));
              navigate("/shorts");
              toggleShortDeleteModal();
            },
            onError: (e) => {
              showAlert("단편웹툰 삭제실패", "ERROR");
            },
          }
        );
      }
    },
    []
  );
  return { onSubmitShortDelete, handleShortDeleteCheck, deleteShortCheck };
}
