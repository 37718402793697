import { useGetEpisodeList } from "queries/ComicUpload/Episode/episode.query";
import * as S from "./style";
import { ReactComponent as Arrow } from "assets/img/Upload/ikon.svg";
import Palette from "libs/Palette/palette";
import MoveButton from "components/common/Button/Move";
import { ReactComponent as Eyes } from "assets/img/Episode/Episodeeyes.svg";
import { ReactComponent as Communication } from "assets/img/Episode/Communication.svg";
import { ReactComponent as Health } from "assets/img/Episode/Health.svg";
import usePopupModal from "utils/Modal/usePopupModal";
import { Params, useNavigate, useParams } from "react-router-dom";
import EpisodeDeleteModal from "./EpisodeDeleteModal";
import { useState } from "react";
import EpisodeComment from "./EpisodeComment";
import EpisodeRegisterModal from "../ComicEpisodeRegister";

const ComicEpisodeManagement = () => {
  const { seriesid }: Readonly<Params<"seriesid">> = useParams();

  const { data: getEpisodeList } = useGetEpisodeList({
    seriesId: Number(seriesid),
    episodeNumber: Number(sessionStorage.getItem("EpisodeNumber")),
  });

  const { ...popup } = usePopupModal();
  const [commentState, setCommentState] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <S.ManagementContainer>
      <S.ManagementBox>
        <S.TitleBox>
          <S.Title fontColor={true}>연재 웹툰 관리</S.Title>
          <Arrow fill={Palette.Neutral.neutral4} />
          <S.Title
            onClick={() => navigate(`/series/manage/${seriesid}`)}
            fontColor={true}
          >
            {getEpisodeList?.seriesTitle}
          </S.Title>
          <Arrow fill={Palette.Neutral.neutral9} />
          <S.Title fontColor={false}>{getEpisodeList?.episodeTitle}</S.Title>
        </S.TitleBox>

        <S.EpisodeContainer>
          <S.EpisodeBox>
            <S.EpisodeInfoBox>
              <S.EpisodeThumbnail src={getEpisodeList?.thumbnail} />
              <S.EpisodeTitlebox>
                <S.SeriesTitle>{getEpisodeList?.seriesTitle}</S.SeriesTitle>
                <S.EpisodeTitle>{getEpisodeList?.episodeTitle}</S.EpisodeTitle>
              </S.EpisodeTitlebox>

              <div style={{ display: "flex", gap: "10px" }}>
                <MoveButton
                  fontcolor={"white"}
                  backroundcolor={"black"}
                  click={() => popup.toggleEpisodeDeleteModal()}
                  width={155}
                  height={41}
                >
                  작품 삭제
                </MoveButton>
                <MoveButton
                  fontcolor={"white"}
                  backroundcolor={"black"}
                  click={() => popup.toggleEpisodAddModal()}
                  width={155}
                  height={41}
                >
                  작품 정보 수정
                </MoveButton>
              </div>
            </S.EpisodeInfoBox>
            <S.PerformanceContainer>
              <S.PerformanceBox>
                <Eyes fill="black" style={{ marginBottom: "2px" }} />
                <div>{getEpisodeList?.views}회</div>
              </S.PerformanceBox>
              <S.PerformanceBox>
                <Health fill="black" style={{ marginBottom: "2px" }} />
                <div>{getEpisodeList?.likeCount}개</div>
              </S.PerformanceBox>
              <S.PerformanceBox onClick={() => setCommentState(!commentState)}>
                <Communication
                  fill={`${commentState ? "#703D8F" : "black"}`}
                  style={{ marginBottom: "2px" }}
                />
                <div style={{ color: commentState ? "#703D8F" : "black" }}>
                  {getEpisodeList?.commentCount}개
                </div>
              </S.PerformanceBox>
            </S.PerformanceContainer>
            <S.Date>{getEpisodeList?.createdDate.slice(0, 10)}</S.Date>
          </S.EpisodeBox>

          {commentState ? (
            <EpisodeComment episodeId={Number(getEpisodeList?.id)} />
          ) : (
            <S.EpisodePreviewBox>
              {getEpisodeList?.imageURL.map((key, index) => {
                return <S.EpisodePreviewImg alt="preview" src={key} />;
              })}
            </S.EpisodePreviewBox>
          )}
        </S.EpisodeContainer>
      </S.ManagementBox>
      <EpisodeDeleteModal
        episodeDeleteModal={popup.episodeDeleteModal}
        toggleEpisodeDeleteModal={popup.toggleEpisodeDeleteModal}
      />
      <EpisodeRegisterModal
        episodeAddModal={popup.episodeAddModal}
        toggleEpisodAddModal={popup.toggleEpisodAddModal}
      />
    </S.ManagementContainer>
  );
};

export default ComicEpisodeManagement;
