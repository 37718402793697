import axios from "axios";

import CONFIG from "config/config.json";
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  REQUEST_TOKEN_KEY,
} from "constants/Token/token.constants";

export const customAxios = axios.create({
  baseURL: `${CONFIG.TEST}`,
  headers: {
    [REQUEST_TOKEN_KEY]: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
  },
});

const errorInterceptor = async (config: any) => {
  const refresh_token = localStorage.getItem(REFRESH_TOKEN_KEY);

  const { status } = config.response;

  if (status === 401) {
    const originalRequest = config.config;

    try {
      const Test = {
        refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
      };
      const { data } = await axios.post(`${CONFIG.TEST}/user/refresh`, Test, {
        headers: {
          [REQUEST_TOKEN_KEY]: `Bearer ${refresh_token}`,
        },
      });

      localStorage.setItem(ACCESS_TOKEN_KEY, data);

      customAxios.defaults.headers[REQUEST_TOKEN_KEY] = `Bearer ${data}`;

      originalRequest.headers[REQUEST_TOKEN_KEY] = `Bearer ${data}`;
      return axios(originalRequest);
    } catch (error) {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    }
  }
};

customAxios.interceptors.response.use((response) => response, errorInterceptor);

export default customAxios;
