import { ReactNode } from "react";
import GlobalStyle from "styles/GlobalStyle";
import Popup from "../Popup";
import { useRecoilValue } from "recoil";
import { HideHeader, HideSidebar } from "stores/Common/common.store";
import Header from "../Header";
import { ToastContainer } from "react-toastify";
import usePopupModal from "utils/Modal/usePopupModal";
import Sidebar from "../Sidebar";

interface PageTemplateProps {
  children: ReactNode;
}

export default function PageTemplate({ children }: PageTemplateProps) {
  const hideHeader = useRecoilValue(HideHeader);
  const hideSidebar = useRecoilValue(HideSidebar);
  const { toggleUserPopup, userPopup } = usePopupModal();

  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <Popup userPopup={userPopup} toggleuUserPopup={toggleUserPopup} />
      {!hideHeader && <Header toggleuUserPopup={toggleUserPopup} />}
      <div style={{ display: "flex" }}>
        {!hideSidebar && <Sidebar />}
        <>{children}</>
      </div>
    </>
  );
}
