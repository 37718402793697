import * as S from "../style";

interface MoveProps {
  children: React.ReactNode;
  fontcolor: string;
  backroundcolor: string;
  click: () => void;
  width: number;
  height: number;
}

const MoveButton: React.FC<MoveProps> = ({
  children,
  fontcolor,
  backroundcolor,
  click,
  width,
  height,
}) => {
  return (
    <>
      <S.MoveButton
        onClick={() => `${click()}`}
        style={{
          color: `${fontcolor}`,
          backgroundColor: `${backroundcolor}`,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {children}
      </S.MoveButton>
    </>
  );
};

export default MoveButton;
