import RegisterButton from "components/common/Button/Register";
import * as S from "./style";
import Palette from "libs/Palette/palette";
import { Params, useParams } from "react-router-dom";
import {
  ShortModifyParam,
  ShortUploadParam,
} from "repositories/ComicUpload/Shorts/short.repository";
import { ChangeEvent } from "react";

interface ShortProps {
  Genre: string[];
  genreChange: string;
  setGenreChange: React.Dispatch<React.SetStateAction<string>>;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInfoUpload: () => void;
  handleShortGereSelection: (genre: string) => void;
  handleShortGenreDesc: (genre: string) => void;
  selectShortGenre: string[];
  shortUpload: ShortUploadParam;
  handleShortModifyChange: (e: ChangeEvent<HTMLInputElement>) => void;
  modifyShortData: ShortModifyParam;
  handleModifyInfo: () => void;
  selectModifyShortGenre: string[];
  modifyShortGenreDesc: (genre: string) => void;
  modifyShortGenreSelection: (genre: string) => void;
}

const ShortInfo = ({
  Genre,
  genreChange,
  setGenreChange,
  onKeyDown,
  handleInputChange,
  handleInfoUpload,
  handleShortGereSelection,
  handleShortGenreDesc,
  selectShortGenre,
  shortUpload,
  handleShortModifyChange,
  modifyShortData,
  handleModifyInfo,
  selectModifyShortGenre,
  modifyShortGenreDesc,
  modifyShortGenreSelection,
}: ShortProps) => {
  const { shortid }: Readonly<Params<"shortid">> = useParams();

  return (
    <S.ShortContainer>
      <S.ShortBox>
        <div>
          <S.RegisterText>
            작품명<span>*</span>
          </S.RegisterText>
          <S.Input
            onChange={shortid ? handleShortModifyChange : handleInputChange}
            id="title"
            name="title"
            placeholder="작품명을 입력해주세요"
            value={shortid ? modifyShortData.title : shortUpload.title}
          />
        </div>
        <S.ShortGenreBox>
          <S.ShortGenre>
            <S.RegisterText>
              장르<span>*</span>
            </S.RegisterText>
            <S.GenreListContainer>
              {Genre.map((genre) => {
                const isSelected = selectShortGenre.includes(genre);
                const isModifyShortGenre =
                  selectModifyShortGenre.includes(genre);

                return (
                  <S.Genre>
                    <S.Genres
                      isModifyShortGenre={isModifyShortGenre}
                      isSelected={isSelected}
                      onClick={() =>
                        shortid
                          ? isModifyShortGenre
                            ? modifyShortGenreDesc(genre)
                            : modifyShortGenreSelection(genre)
                          : isSelected
                          ? handleShortGenreDesc(genre)
                          : handleShortGereSelection(genre)
                      }
                      style={{
                        color: shortid
                          ? isModifyShortGenre
                            ? `${Palette.Neutral.neutral9}`
                            : `${Palette.Neutral.neutral4}`
                          : isSelected
                          ? `${Palette.Neutral.neutral9}`
                          : `${Palette.Neutral.neutral4}`,
                      }}
                    >
                      {genre}
                    </S.Genres>
                  </S.Genre>
                );
              })}
            </S.GenreListContainer>
          </S.ShortGenre>
        </S.ShortGenreBox>
        <S.GenreAddBox>
          <S.RegisterText>원하시는 장르가 없나요?</S.RegisterText>
          <S.Input
            placeholder="장르를 직접 추가해 주세요.."
            value={genreChange}
            onChange={(e) => setGenreChange(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </S.GenreAddBox>

        <S.ShortRegisterButton>
          <RegisterButton
            width={21}
            height={44}
            radius={8}
            fontsize={16}
            positions={true}
            buttonType="agree"
            click={() => (shortid ? handleModifyInfo() : handleInfoUpload())}
          >
            다음
          </RegisterButton>
        </S.ShortRegisterButton>
      </S.ShortBox>
    </S.ShortContainer>
  );
};

export default ShortInfo;
