import customAxios from "libs/Axios/customAxios";
import showAlert from "libs/Toast/toast";
import ColorThief from "color-thief-ts";
import { useGetEpisode } from "queries/ComicUpload/Episode/episode.query";
import { usePatchSeries } from "queries/ComicUpload/Series/series.query";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { PageStateAtom, SectionAtom } from "stores/Common/common.store";
import {
  ImageAverageColorAtom,
  SeriesModifyAtom,
} from "stores/Series/series.store";
import { SeriesParam } from "repositories/ComicUpload/Series/series.repository";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "queries/queryKey";

interface SeriesModifyProps {
  seriesId: number | undefined;
}

const useSeriesModify = ({ seriesId }: SeriesModifyProps) => {
  const { data: getEpisodeList } = useGetEpisode({
    id: seriesId ? String(seriesId) : sessionStorage.getItem("SeriesId"),
  });

  const queryClient = useQueryClient();
  const patchSeriesMutation = usePatchSeries();
  const [modifySeriesData, setModifySeriesData] =
    useRecoilState(SeriesModifyAtom);
  const [backGroundModifyFil] = useState(new FormData());
  const [publishStatusCheck, setPublishStatusCheck] = useState("");
  const imgModifyRef = useRef<HTMLLabelElement | null>(null);
  const [, setSection] = useRecoilState(SectionAtom);
  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const [modifyImgUrl, setModifyImgSrc] = useState<string | any>();
  const [selectModifySeriesGenre, setSelectModifSeriesGenre] = useState<
    string[]
  >([]);
  const [, setAverageColor] = useRecoilState<string | any>(
    ImageAverageColorAtom
  );

  const modifySeriesGenreSelection = (genre: string) => {
    const selectedGenreCount = selectModifySeriesGenre.length;
    if (selectedGenreCount < 3) {
      if (!selectModifySeriesGenre.includes(genre)) {
        setSelectModifSeriesGenre((prev) => [...prev, genre]);
      }
    } else {
      showAlert("장르는 최대 3개까지 선택 가능합니다", "INFO");
    }
  };

  const modifySeriesGenreDesc = (genre: string) => {
    setSelectModifSeriesGenre((prev) =>
      prev.filter((selectGenre) => selectGenre !== genre)
    );
  };

  const handlePublishStatusCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setPublishStatusCheck(e.target.value);
  };

  useEffect(() => {
    if (modifyImgUrl) {
      handleImageColor();
    }
  }, [modifyImgUrl]);

  const handleImageColor = () => {
    const image = new Image();
    image.src = modifyImgUrl;

    image.onload = () => {
      const fac = new ColorThief();
      const Color = fac.getPalette(image, 6);

      setAverageColor(Color);
    };
  };

  useEffect(() => {
    if (seriesId && getEpisodeList) {
      const { title, description, publishStatus, cycle, dayOfWeek, genre } =
        getEpisodeList;
      const { color, backgroundImage, font } = getEpisodeList.titleMaker;

      setModifySeriesData({
        title,
        description,
        publishStatus,
        cycle,
        dayOfWeek,
        genre,
        titleMaker: {
          color,
          font,
          backgroundImage,
        },
      });
      setSelectModifSeriesGenre(genre);
    }
  }, [getEpisodeList, seriesId]);

  const onChangeModifyContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    setModifySeriesData((prev) => ({ ...prev, [name]: value }));
  };

  const onModifyPageRouter = () => {
    setSection("modify");
    setPage((prev) => prev + 1);
  };

  const onBackGroundImgEdit = async () => {
    const { description } = modifySeriesData;

    if (description === "") {
      showAlert("작품 설명을 입력해주세요", "INFO");
      return;
    }

    if (modifyImgUrl !== undefined) {
      const backgroundImgRes = await customAxios.post(
        "comic/image/upload",
        backGroundModifyFil
      );

      setModifySeriesData((prev) => ({
        ...prev,
        titleMaker: {
          ...prev.titleMaker,
          backgroundImage: backgroundImgRes.data,
        },
      }));

      setSection("thumbnail");
      setPage((prev) => prev + 1);
    } else {
      setSection("thumbnail");
      setPage((prev) => prev + 1);
    }
  };

  const onSubmitModifySeries = (toggleSeriesModal: () => void) => {
    const modifiyDataList: SeriesParam = {
      ...modifySeriesData,
      genre: selectModifySeriesGenre,
    };

    const executeMutation = () => {
      patchSeriesMutation.mutate(
        {
          PatchData: modifiyDataList,
          seriesId: Number(seriesId),
        },
        {
          onSuccess: () => {
            showAlert("연재웹툰 수정 성공", "SUCCESS");
            queryClient.invalidateQueries(
              QUERY_KEYS.episode.episodeMyList(String(seriesId))
            );
            queryClient.invalidateQueries(QUERY_KEYS.series.seriesList(1));
            toggleSeriesModal();
            setPage(0);
            setSection("info");
          },
          onError: () => {
            showAlert("연재웹툰 수정 실패", "ERROR");
          },
        }
      );
    };

    if (modifySeriesData.publishStatus === "COMPLETED") {
      if (modifySeriesData.title === publishStatusCheck) {
        executeMutation();
      }
    } else {
      executeMutation();
    }
  };

  return {
    setModifySeriesData,
    onChangeModifyContent,
    modifySeriesData,
    onBackGroundImgEdit,
    backGroundModifyFil,
    setModifyImgSrc,
    imgModifyRef,
    modifyImgUrl,
    onSubmitModifySeries,
    onModifyPageRouter,
    handlePublishStatusCheck,
    selectModifySeriesGenre,
    modifySeriesGenreSelection,
    modifySeriesGenreDesc,
  };
};

export default useSeriesModify;
