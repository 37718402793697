import { Dispatch, SetStateAction, useRef } from "react";

interface DragType {
  setImgUrl: Dispatch<SetStateAction<string[]>>;
  imgUrl: string[];
  filename: string[];
  setFileName: Dispatch<SetStateAction<string[]>>;
}
const useDrag = ({ setFileName, setImgUrl, imgUrl, filename }: DragType) => {
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);

  const dragStart = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    dragOverItem.current = position;
  };

  const drop = (e: React.DragEvent<HTMLDivElement>) => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const newList = [...imgUrl];
      const newName = [...filename];

      const dragItemValue = newList[dragItem.current];
      const dragItemValues = newName[dragItem.current];

      newList.splice(dragItem.current, 1);
      newList.splice(dragOverItem.current, 0, dragItemValue);

      newName.splice(dragItem.current, 1);
      newName.splice(dragOverItem.current, 0, dragItemValues);
      dragItem.current = null;
      dragOverItem.current = null;
      setImgUrl(newList);
      setFileName(newName);
    }
  };

  return {
    dragStart,
    dragEnter,
    drop,
  };
};

export default useDrag;
