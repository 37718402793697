import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const useRouteChangeTracker = () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
  }
  const location = useLocation();
  const [initialzed, setInitialzed] = useState<boolean>(false);
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      if (TRACKING_ID) {
        ReactGA.initialize(TRACKING_ID);
      }
    }
    setInitialzed(true);
  }, []);
  useEffect(() => {
    if (initialzed) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send("pageview");
    }
  }, [initialzed, location]);
};

export default useRouteChangeTracker;
