import { styled } from "styled-components";
import Palette from "libs/Palette/palette";

export const NotFoundText = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  font-size: 90px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  margin-bottom: 20px;
`;

export const NotFoundPage = styled.div`
  display: flex;
  justify-content: center;

  font-size: 15px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  margin-bottom: 10px;
`;

export const NotFoundBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
`;

export const HomeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${Palette.basics};
  color: white;

  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  width: 500px;
  height: 50px;

  border: 0;
  border-radius: 15px;
`;
