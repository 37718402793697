import { useCallback, useState } from "react";
import { LoginParam } from "repositories/Auth/Login/login.repository";
import { useNavigate } from "react-router-dom";
import loginRepositoryImpl from "repositories/Auth/Login/login.repositoryImpl";
import { useRecoilState } from "recoil";
import { ErrorStateAtom } from "stores/Common/common.store";

export const useLogin = () => {
  const navigate = useNavigate();
  const [errorState, setErrorState] =
    useRecoilState<Record<string, string>>(ErrorStateAtom);
  const [LoginData, setLoginData] = useState<LoginParam>({
    userId: "",
    password: "",
  });

  const handleLoginChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      setLoginData((prev) => ({ ...prev, [name]: value }));
    },
    [setLoginData]
  );

  const onSubmitLogin = useCallback(async () => {
    try {
      await loginRepositoryImpl.postLogin(LoginData);
      navigate("/series");
    } catch (e) {
      setErrorState({
        ...errorState,
        password: "아이디 및 비밀번호를 확인해주세요",
      });
    }
  }, [LoginData]);

  return {
    handleLoginChange,
    onSubmitLogin,
    LoginData,
  };
};
