export const REGISTER_SECTION_NAME = [
  {
    title: "info",
  },
  {
    title: "thumbnail",
  },
  {
    title: "setting",
  },
  {
    title: "modify",
  },
];
