import { MutableRefObject, useCallback, useRef, useState } from "react";
import customAxios from "libs/Axios/customAxios";
import showAlert from "libs/Toast/toast";
import { PageStateAtom, SectionAtom } from "stores/Common/common.store";
import { usePosetEpisodeCreateMutation } from "queries/ComicUpload/Episode/episode.query";
import { useRecoilState } from "recoil";
import { EpisdoeCreateAtom, EpisodeIdAtom } from "stores/Episode/episode.store";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "queries/queryKey";

const useEpisodeUpload = () => {
  const episodeCreate = usePosetEpisodeCreateMutation();
  const queryClient = useQueryClient();

  const [episodeform, setEpisodeForm] = useState(new FormData());
  const [thumbnailform, setThumbnailForm] = useState(new FormData());

  const thumbnailImgRef = useRef<HTMLLabelElement | null>(null);
  const episodeImgRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const [episodeSection, setEpisodeSection] = useRecoilState(SectionAtom);
  const [episodeIds, setEpisodeId] = useRecoilState(EpisodeIdAtom);
  const [seriesData, setSeriesData] = useRecoilState(EpisdoeCreateAtom);

  const [RoundThumbnailUrl, setThumbnailUrl] = useState<string | any>();
  const [uploadDate, setUploadDate] = useState<string>("");

  const [filename, setFileName] = useState<string[]>([]);
  const [roundImgUrl, setRoundImgUrl] = useState<string[]>([]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setSeriesData((prev) => ({ ...prev, [name]: value }));
    },
    [setSeriesData]
  );

  const handleEpisodeAdd = () => {
    if (episodeImgRef.current) {
      episodeImgRef.current.click();
    }
  };

  const onEpisodeModalClose = (toggleEpisodAddModal: () => void) => {
    setSeriesData({
      title: "",
      thumbnail: "",
      uploadDate: "",
    });

    setEpisodeSection("info");
    setPage(0);
    setEpisodeForm(new FormData());
    setThumbnailForm(new FormData());
    setThumbnailUrl([]);
    setRoundImgUrl([]);
    toggleEpisodAddModal();
    setPage(0);
  };

  const onEpisodeListUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = e.target.files!!;

    let newImageUrlList = [...roundImgUrl];
    let newFilenameList = [...filename];

    Array.from(fileList).forEach((file) => {
      episodeform.append("fileList", file);
      newFilenameList.push(file.name);
      const imageUrl = URL.createObjectURL(file);
      newImageUrlList.push(imageUrl);
    });

    setRoundImgUrl(newImageUrlList);
    setFileName(newFilenameList);

    const episodeRespons = await customAxios.post(
      "comic/episode/upload",
      episodeform
    );

    setEpisodeId(episodeRespons.data);
  };

  const handleEpisodeListDelete = (id: number) => {
    const newImageUrlList = roundImgUrl.filter((_, index) => index !== id);
    const newFilenameList = filename.filter((_, index) => index !== id);
    setRoundImgUrl(newImageUrlList);
    setFileName(newFilenameList);
  };

  const handleEpisodeInfoUpload = async () => {
    const { title } = seriesData;
    if (title === "") {
      showAlert("제목을 입력해주세요", "INFO");
      return;
    }
    const thumbnailRes = await customAxios.post(
      "comic/image/upload",
      thumbnailform
    );

    setSeriesData((prev) => ({
      ...prev,
      thumbnail: thumbnailRes.data,
    }));

    setEpisodeSection("webtoon");
    setPage((prev) => prev + 1);
  };

  const onSubmitEpisodUpload = async (toggleEpisodeModal: () => void) => {
    const SeriesDataList = {
      title: seriesData.title,
      thumbnail: seriesData.thumbnail,
      uploadDate: uploadDate,
    };

    episodeCreate.mutate(
      {
        episodeData: SeriesDataList,
        seriesId: Number(sessionStorage.getItem("SeriesId")),
        episodeId: episodeIds,
      },
      {
        onSuccess: () => {
          showAlert("에피소드 업로드 성공", "SUCCESS");
          queryClient.invalidateQueries(
            QUERY_KEYS.episode.episodeMyList(sessionStorage.getItem("SeriesId"))
          );
          onEpisodeModalClose(toggleEpisodeModal);
        },
        onError: () => {
          showAlert("에피소드 업로드 실패", "ERROR");
          onEpisodeModalClose(toggleEpisodeModal);
        },
      }
    );
  };

  return {
    roundImgUrl,
    RoundThumbnailUrl,
    handleEpisodeAdd,
    onEpisodeListUpload,
    handleEpisodeListDelete,
    episodeImgRef,
    thumbnailImgRef,
    onSubmitEpisodUpload,
    setSeriesData,
    seriesData,
    filename,
    handleInputChange,
    thumbnailform,
    setThumbnailUrl,
    episodeSection,
    setEpisodeSection,
    setUploadDate,
    uploadDate,
    setFileName,
    setRoundImgUrl,
    handleEpisodeInfoUpload,
    episodeform,
    episodeCreate,
    onEpisodeModalClose,
  };
};

export default useEpisodeUpload;
