import styled from "styled-components";
import Palett from "libs/Palette/palette";

export const EpisodeUploadContainer = styled.div``;
export const EpisodeListBox = styled.div`
  width: 752px;
  height: 648px;
`;

export const RegisterText = styled.p`
  margin-bottom: 7px;
  margin-left: 5px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${Palett.Neutral.neutral9};
`;

export const PeriodBox = styled.div`
  display: flex;
  justify-content: center;

  gap: 10px;

  width: 100%;

  margin-bottom: 42px;
`;

export const PeriodButton = styled.button`
  background-color: white;

  width: 243.99px;
  height: 50px;

  border: 0;
  border-radius: 10px;
  border: 1px solid ${Palett.Neutral.neutral2};

  gap: 10px;

  font-size: 18px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  color: ${Palett.Neutral.neutral4};

  cursor: pointer;
`;

export const DateBox = styled.div`
  display: flex;
  justify-content: center;

  gap: 10px;

  width: 100%;
`;

export const DateButton = styled.button`
  width: 98.87px;
  height: 50px;

  border-radius: 10px;

  margin-bottom: 26px;

  font-size: 18px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  color: ${Palett.Neutral.neutral4};

  background-color: white;

  border: 1px solid ${Palett.Neutral.neutral2};

  cursor: pointer;
`;

export const EpisodeRegisterButton = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: 10px;

  width: 100%;
`;

export const CalendarBox = styled.div`
  margin-bottom: 27px;
`;

export const Text = styled.p`
  display: flex;
  justify-content: flex-end;

  font-size: 12px;
`;

export const Icon = styled.div`
  width: 5px;
  height: 5px;

  background-color: ${Palett.Primary.primary5};

  border-radius: 100px;

  margin-left: 1.5px;
  margin-top: -2px;
`;
