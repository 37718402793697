import * as S from "./style";
import Lottie from "react-lottie-player";
import TonnieRunning from "json/Toonie_Running_Lottie.json";

const RunningLoading = () => {
  return (
    <S.RunningLoadingContainer>
      <S.LoadingText>인증번호 찾으러 가는길!</S.LoadingText>
      <Lottie
        loop
        animationData={TonnieRunning}
        play
        style={{ width: 200, height: 200 }}
      />
    </S.RunningLoadingContainer>
  );
};

export default RunningLoading;
