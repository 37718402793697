import useBooleanToggle from "utils/Toggle/useBooleanToggle";

const usePopupModal = () => {
  const [userPopup, toggleUserPopup] = useBooleanToggle(false);
  const [seriesAddModal, toggleSeriesAddModal] = useBooleanToggle(false);
  const [episodeAddModal, toggleEpisodAddModal] = useBooleanToggle(false);
  const [shortAddModal, toggleShortAddModal] = useBooleanToggle(false);
  const [episodeDeleteModal, toggleEpisodeDeleteModal] =
    useBooleanToggle(false);
  const [shortDeleteModal, toggleShortDeleteModal] = useBooleanToggle(false);
  const [seriesDeleteModal, toggleSeriesDeleteModal] = useBooleanToggle(false);

  return {
    userPopup,
    toggleUserPopup,
    episodeAddModal,
    toggleEpisodAddModal,
    seriesAddModal,
    toggleSeriesAddModal,
    shortAddModal,
    toggleShortAddModal,
    episodeDeleteModal,
    toggleEpisodeDeleteModal,
    shortDeleteModal,
    toggleShortDeleteModal,
    seriesDeleteModal,
    toggleSeriesDeleteModal,
  };
};

export default usePopupModal;
