import * as S from "./style";
import { useGetComment } from "queries/Comment/comment.query";
import Profile from "assets/img/Sideber/임시.svg";
import DeleteButton from "assets/img/Comment/button.svg";
import { ReactComponent as Arrow } from "assets/img/Comment/ArrowBt.svg";
import { useState } from "react";
import Palette from "libs/Palette/palette";
import useShortComment from "hooks/Shorts/useShortComment";

interface CommentProps {
  shortId: number;
}

const ShortComment = ({ shortId }: CommentProps) => {
  const { data: getComment } = useGetComment(shortId);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const { handleEpisodeCommentChange, onEpisodeSubmitComment } =
    useShortComment(shortId);

  return (
    <S.ShortCommentContainer>
      <S.ShortCommentBox>
        <div>
          {getComment && getComment?.commentResponses.length > 0 ? (
            <>
              {getComment?.commentResponses.map((comment, index) => {
                const isHovered = hoverIndex === index;

                return (
                  <S.CommentContainer
                    onMouseEnter={() => setHoverIndex(index)}
                    onMouseLeave={() => setHoverIndex(null)}
                  >
                    <S.CommentList>
                      <S.Profile src={Profile} alt="profile" />
                      <S.CommentBox>
                        <S.CommentInfo>
                          {comment.author}
                          <p>{comment.createdDate}</p>
                        </S.CommentInfo>
                        <S.CommentContent>{comment.body}</S.CommentContent>
                      </S.CommentBox>
                    </S.CommentList>
                    {isHovered && (
                      <S.DeleteButton alt="delete" src={DeleteButton} />
                    )}
                  </S.CommentContainer>
                );
              })}
            </>
          ) : (
            <S.Info>댓글이 없습니다!</S.Info>
          )}
        </div>
      </S.ShortCommentBox>
      <S.InputBox>
        <S.Profile src={Profile} alt="profile" />
        <div>
          <S.CommentInput
            placeholder="독자와 함께 이야기를 나눠보세요 !!"
            onChange={handleEpisodeCommentChange}
          />
          <Arrow
            fill={Palette.Primary.primary6}
            className="arrow_bt"
            onClick={() => onEpisodeSubmitComment()}
          />
        </div>
      </S.InputBox>
    </S.ShortCommentContainer>
  );
};

export default ShortComment;
