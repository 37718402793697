import styled from "styled-components";

import Palette from "libs/Palette/palette";

export const EpisodeBox = styled.div`
  display: flex;

  gap: 44px;

  margin-bottom: 3.2px;
`;

export const EpisodeListBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RegisterText = styled.p`
  margin-bottom: 7px;
  margin-left: 5px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${Palette.Neutral.neutral9};

  span {
    color: ${Palette.error};
  }
`;

export const Preview = styled.div`
  width: 354px;
  height: 501px;

  border-radius: 10px;

  border: 1px solid ${Palette.Neutral.neutral2};

  overflow: auto;
`;

export const EpisodeList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  width: 354px;
  height: 501px;

  border-radius: 10px;

  border: 1px solid ${Palette.Neutral.neutral2};

  margin-bottom: 15px;
`;

export const EpisodeAddBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EpisodeAddButton = styled.button`
  width: 323px;
  height: 41px;

  background-color: #e7e8e9;

  border: none;
  border-radius: 8px;

  font-size: 14px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
`;

export const EpisodeRegisterButton = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: 10px;

  width: 100%;

  margin-top: 6px;
`;

export const ScriptList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  width: 323px;
  height: 41px;

  border-radius: 8px;

  cursor: pointer;

  &:hover {
    background-color: ${Palette.Primary.primary1};
  }
`;

export const Episode = styled.div`
  width: 275px;

  font-size: 18px;
  margin-left: 15px;
`;
