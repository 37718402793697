import Modal from "components/common/Modal";
import * as S from "./style";
import close from "assets/img/Upload/close.svg";
import { useRecoilState } from "recoil";
import { PageStateAtom } from "stores/Common/common.store";
import ProgressBar from "components/common/ProgressBar";
import EpisodeRegister from "./EpisodeRegisterModal";
import useEpisodeUpload from "hooks/Episode/useEpisodeUpload";

interface EpisodeModalProps {
  episodeAddModal: boolean;
  toggleEpisodAddModal: () => void;
}

const EpisodeRegisterModal = ({
  episodeAddModal,
  toggleEpisodAddModal,
}: EpisodeModalProps) => {
  const [page, setPage] = useRecoilState<number>(PageStateAtom);
  const { onEpisodeModalClose } = useEpisodeUpload();

  return (
    <Modal isOpen={episodeAddModal}>
      <S.RegisterModalContainer>
        <S.RegisterModalBox>
          <S.RegisterCloseButton>
            <S.IconBox
              onClick={() => onEpisodeModalClose(toggleEpisodAddModal)}
            >
              <img alt="close" src={close} />
            </S.IconBox>
          </S.RegisterCloseButton>

          <S.ProgressBarBox>
            <div style={{ flexDirection: "column", width: "100%" }}>
              <S.ProgressBarBoxList active={page >= 0}>
                <div>1</div>
                <div>회차 정보 입력</div>
              </S.ProgressBarBoxList>
              <ProgressBar active={page >= 0} width={244} />
            </div>
            <div style={{ flexDirection: "column", width: "100%" }}>
              <S.ProgressBarBoxList active={page >= 1}>
                <div>2</div>
                <div>원고 등록</div>
              </S.ProgressBarBoxList>
              <ProgressBar active={page >= 1} width={244} />
            </div>
            <div style={{ flexDirection: "column", width: "100%" }}>
              <S.ProgressBarBoxList active={page >= 2}>
                <div>3</div>
                <div>공개 설정</div>
              </S.ProgressBarBoxList>
              <ProgressBar active={page >= 2} width={244} />
            </div>
          </S.ProgressBarBox>

          <div style={{ overflow: "auto" }}>
            <EpisodeRegister toggleRegisterModal={toggleEpisodAddModal} />
          </div>
        </S.RegisterModalBox>
      </S.RegisterModalContainer>
    </Modal>
  );
};

export default EpisodeRegisterModal;
