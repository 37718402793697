import * as S from "./style";
import Modal from "components/common/Modal";
import { useGetEpisode } from "queries/ComicUpload/Episode/episode.query";
import Health from "assets/img/Episode/Health.svg";
import close from "assets/img/Upload/close.svg";
import { Params, useParams } from "react-router-dom";
import { useSeriesDelete } from "hooks/Series/useSeriesDelete";

interface SeriesDeleteProps {
  seriesDeleteModal: boolean;
  toggleSeriesDeleteModal: () => void;
}

const SeriesDeleteModal = ({
  seriesDeleteModal,
  toggleSeriesDeleteModal,
}: SeriesDeleteProps) => {
  const { seriesid }: Readonly<Params<"seriesid">> = useParams();
  const { data: getEpisodeList } = useGetEpisode({
    id: String(seriesid),
  });

  const { onSubmitSeriesDelete, deleteSeriesCheck, handleSeriesDeleteCheck } =
    useSeriesDelete(toggleSeriesDeleteModal);

  return (
    <Modal isOpen={seriesDeleteModal}>
      <S.ModalContainer>
        <S.ModalBox>
          <S.IconBox>
            <img
              alt="close"
              src={close}
              onClick={() => toggleSeriesDeleteModal()}
            />
          </S.IconBox>
          <S.TitleBox>
            <S.Title isColor={true}>{getEpisodeList?.title}</S.Title>
            <S.Title isColor={false}>
              "{getEpisodeList?.title}" 를 삭제하시겠어요?
            </S.Title>
          </S.TitleBox>
          <S.PerformanceContainer>
            <S.PerformanceBoc>
              <S.Icon alt="health" src={Health} />
              <div>10개</div>
            </S.PerformanceBoc>
          </S.PerformanceContainer>
          <S.DeleteInfoBox>
            <p>
              삭제를 하기 위해서,"{getEpisodeList?.title}" 박스 안에
              입력해주세요<span>*</span>
            </p>
            <S.DeleteInput onChange={handleSeriesDeleteCheck} />
          </S.DeleteInfoBox>

          <S.DeleteButtonBox>
            <S.DeleteButton
              disabled={deleteSeriesCheck !== getEpisodeList?.title}
              onClick={() =>
                onSubmitSeriesDelete(
                  Number(seriesid),
                  getEpisodeList?.title,
                  deleteSeriesCheck
                )
              }
            >
              삭제
            </S.DeleteButton>
          </S.DeleteButtonBox>
        </S.ModalBox>
      </S.ModalContainer>
    </Modal>
  );
};

export default SeriesDeleteModal;
