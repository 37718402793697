import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { HideSidebar } from "stores/Common/common.store";

const useHideSidebar = () => {
  const setHideSidebar = useSetRecoilState(HideSidebar);
  useEffect(() => {
    setHideSidebar(true);
    return () => setHideSidebar(false);
  }, [setHideSidebar]);
};

export default useHideSidebar;
