import { atom } from "recoil";
import { SeriesParam } from "repositories/ComicUpload/Series/series.repository";

export const ImageAverageColorAtom = atom<string[]>({
  key: "ImageAverageColorAtom",
  default: [],
});

export const SelectFontAtom = atom<string>({
  key: "SelectFontAtom",
  default: "CAFE24CLASSIC",
});

export const SeriesCreateAtom = atom<SeriesParam>({
  key: "SeriesCreateAtom",
  default: {
    title: "",
    description: "",
    publishStatus: "ON_GOING",
    cycle: "",
    dayOfWeek: "",
    genre: [],
    titleMaker: {
      color: "#eeeeee",
      font: "",
      backgroundImage: "",
    },
  },
});

export const SeriesModifyAtom = atom<SeriesParam>({
  key: "SeriesModifyAtom",
  default: {
    title: "",
    description: "",
    publishStatus: "ON_GOING",
    cycle: "",
    dayOfWeek: "",
    genre: [],
    titleMaker: {
      color: "#eeeeee",
      font: "",
      backgroundImage: "",
    },
  },
});

export const ImgAtom = atom<string | undefined>({
  key: "ImgAtom",
  default: "",
});

export const GenreAtom = atom<string[]>({
  key: "GenreAtom",
  default: [],
});
