import styled from "styled-components";
import Palette from "libs/Palette/palette";
import { HoverAnimation } from "styles/common.style";

export const ModalContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1;
`;

export const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 842px;
  height: 466px;

  background-color: white;

  border-radius: 20px;
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 16px;
`;

export const Title = styled.div<{ isColor: boolean }>`
  color: ${(props) => (props.isColor ? "#979BA0" : "#000000")};
  font-size: 24px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  margin-bottom: 14px;
`;

export const PerformanceContainer = styled.div`
  display: flex;

  gap: 87px;

  border-radius: 10px;

  margin-bottom: 56px;
`;

export const PerformanceBoc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled.img`
  margin-bottom: 2px;
`;

export const DeleteInput = styled.input`
  width: 752px;
  height: 50px;

  border-radius: 10px;
  border: 1px solid ${Palette.error};

  padding-left: 20px;

  font-size: 18px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
`;

export const DeleteButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 752px;
`;

export const DeleteButton = styled.button`
  width: 150px;
  height: 44px;

  border-radius: 8px;
  border: none;

  opacity: ${(props) => props.disabled && "0.8"};

  background-color: ${Palette.error};

  font-size: 16px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;
  color: white;

  &:hover {
    background-color: ${(props) => (props.disabled ? "" : "#f28080")};
    transition: ${(props) => (props.disabled ? "" : "all 0.1s ease-in-out")};
  }
`;

export const DeleteInfoBox = styled.div`
  p {
    font-size: 16px;
    font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

    span {
      color: red;
    }

    margin-bottom: 7px;
  }
  margin-bottom: 40px;
`;

export const IconBox = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 800px;

  margin-bottom: 15px;

  img {
    margin-top: -30px;

    padding: 9px 8px 8px 8px;

    ${HoverAnimation}
  }
`;
