import * as S from "./style";

interface SkeletonProps {
  height: number;
  top: boolean;
}

const SkeletonComponent = ({ height, top }: SkeletonProps) => {
  return (
    <S.SkeletonContainer top={top}>
      <S.SkeletonBox>
        <S.Skeleteon>
          {Array.from({ length: 8 }).map((item, idx) => (
            <S.SkeletonItem key={idx} height={height} />
          ))}
        </S.Skeleteon>
      </S.SkeletonBox>
    </S.SkeletonContainer>
  );
};

export default SkeletonComponent;
