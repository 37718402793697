import * as S from "./style";
import ToongetherLogo from "assets/img/Auth/Toongether_Logo.svg";
import Login from "./Login";
import { useState } from "react";
import Signup from "./Signup";
import useHideHeader from "hooks/common/Header/useHideHeader";
import useHideSidebar from "hooks/common/Sidebar/useHideSidebar";

const Auth = () => {
  useHideHeader();
  useHideSidebar();

  const [isLogin, setIsLogin] = useState(true);

  return (
    <>
      <S.AuthContainer>
        <S.AuthBox>
          <S.AuthListBox>
            <S.AuthLogoBox>
              <img alt="logo" src={ToongetherLogo} />
            </S.AuthLogoBox>
            <S.AuthList>
              {isLogin ? (
                <Login setIsLogin={setIsLogin} />
              ) : (
                <Signup setIsLogin={setIsLogin} />
              )}
            </S.AuthList>
          </S.AuthListBox>

          <S.AuthContractBox>
            <a
              href="https://docs.toongether.kr/service/terms/"
              target="blank"
              rel="noopener noreferrer"
            >
              이용약관
            </a>
            <a
              href="https://docs.toongether.kr/service/privacy"
              target="blank"
              rel="noopener noreferrer"
            >
              개인정보 처리방침
            </a>
            <a
              href="https://docs.toongether.kr/service/guidelines"
              target="blank"
              rel="noopener noreferrer"
            >
              운영 정책
            </a>
            <a
              href="https://docs.toongether.kr/introduction/support"
              target="blank"
              rel="noopener noreferrer"
            >
              고객지원
            </a>
          </S.AuthContractBox>
          <S.ToongetherInfo>
            Copyright ©
            <b
              style={{
                fontFamily:
                  "Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic",
              }}
            >
              Progress
            </b>
            All rights reserved.
          </S.ToongetherInfo>
        </S.AuthBox>
      </S.AuthContainer>
    </>
  );
};

export default Auth;
