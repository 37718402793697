import * as S from "./style";
import { Dispatch, SetStateAction } from "react";
import { SeriesDate, SeriesPeriod } from "utils/TitleMaker/seriesData";
import { SeriesParam } from "repositories/ComicUpload/Series/series.repository";
import Palette from "libs/Palette/palette";
import RegisterButton from "components/common/Button/Register";
import { useRecoilState } from "recoil";
import { PageStateAtom } from "stores/Common/common.store";
import Loading from "components/common/Loading";
import useSeriesUpload from "hooks/Series/useSeriesUpload";

interface SeriesProps {
  genreChange: string;
  Genre: string[];
  titleMakerData: SeriesParam;
  setSection: Dispatch<SetStateAction<string>>;
  setGenreChange: React.Dispatch<React.SetStateAction<string>>;
  handleAddGenre: any;
  setTitleMakerData: React.Dispatch<React.SetStateAction<SeriesParam>>;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onTitleMakerUpload: () => void;
  seriesCreate: any;
  toggleRegisterModal: () => void;
  selectSeriesGenre: string[];
  seriesid: string | undefined;
  setModifySeriesData: Dispatch<SetStateAction<SeriesParam>>;
  modifySeriesData: SeriesParam;
  selectModifySeriesGenre: string[];
  modifySeriesGenreDesc: (genre: string) => void;
  modifySeriesGenreSelection: (genre: string) => void;
  onModifyPageRouter: () => void;
}
const SeriesSetting = ({
  Genre,
  genreChange,
  setGenreChange,
  onKeyDown,
  setTitleMakerData,
  titleMakerData,
  onTitleMakerUpload,
  setSection,
  seriesCreate,
  selectSeriesGenre,
  seriesid,
  setModifySeriesData,
  modifySeriesData,
  selectModifySeriesGenre,
  modifySeriesGenreDesc,
  modifySeriesGenreSelection,
  onModifyPageRouter,
  handleAddGenre,
}: SeriesProps) => {
  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const { handleGenreDeseLection, handleGenreSelection } = useSeriesUpload();

  return (
    <S.SeriesSettingContainer>
      {seriesCreate.isLoading ? (
        <Loading>웹툰 등록 중...</Loading>
      ) : (
        <S.SeriesSettingBox>
          <S.RegisterText>
            연재 주기<span>*</span>
          </S.RegisterText>
          <S.SeriesPeriodBox>
            {SeriesPeriod.map((series) => (
              <S.PeriodButton
                onClick={() =>
                  seriesid
                    ? setModifySeriesData((prev) => ({
                        ...prev,
                        cycle: series.value,
                      }))
                    : setTitleMakerData((prev) => ({
                        ...prev,
                        cycle: series.value,
                      }))
                }
                style={{
                  border: seriesid
                    ? modifySeriesData.cycle === series.value
                      ? `2px solid ${Palette.basics}`
                      : ``
                    : titleMakerData.cycle === series.value
                    ? `2px solid ${Palette.basics}`
                    : ``,
                  color: seriesid
                    ? modifySeriesData.cycle == series.value
                      ? `${Palette.Neutral.neutral9}`
                      : `${Palette.Neutral.neutral4}`
                    : titleMakerData.cycle == series.value
                    ? `${Palette.Neutral.neutral9}`
                    : `${Palette.Neutral.neutral4}`,
                }}
              >
                {series.period}
              </S.PeriodButton>
            ))}
          </S.SeriesPeriodBox>

          <S.RegisterText>
            연재 요일<span>*</span>
          </S.RegisterText>
          <S.SeriesDateBox>
            {SeriesDate.map((date) => (
              <S.DateButton
                onClick={() =>
                  seriesid
                    ? setModifySeriesData((prev) => ({
                        ...prev,
                        dayOfWeek: date.value,
                      }))
                    : setTitleMakerData((prev) => ({
                        ...prev,
                        dayOfWeek: date.value,
                      }))
                }
                style={{
                  border: seriesid
                    ? modifySeriesData.dayOfWeek == date.value
                      ? `2px solid ${Palette.Primary.primary5}`
                      : ``
                    : titleMakerData.dayOfWeek == date.value
                    ? `2px solid ${Palette.Primary.primary5}`
                    : ``,
                  color: seriesid
                    ? modifySeriesData.dayOfWeek == date.value
                      ? `${Palette.Neutral.neutral9}`
                      : `${Palette.Neutral.neutral4}`
                    : titleMakerData.dayOfWeek == date.value
                    ? `${Palette.Neutral.neutral9}`
                    : `${Palette.Neutral.neutral4}`,
                }}
              >
                {date.day}
              </S.DateButton>
            ))}
          </S.SeriesDateBox>
          <S.SeriesGenreBox>
            <S.SeriesGenre>
              <S.RegisterText>
                장르<span>*</span>
              </S.RegisterText>

              <S.GenreListContainer>
                {Genre.map((genre) => {
                  const isSelected = selectSeriesGenre.includes(genre);
                  const isModifySeriesGenre =
                    selectModifySeriesGenre.includes(genre);
                  return (
                    <S.Genre>
                      <S.Genres
                        isModifySeriesGenre={isModifySeriesGenre}
                        isSelected={isSelected}
                        onClick={() =>
                          seriesid
                            ? isModifySeriesGenre
                              ? modifySeriesGenreDesc(genre)
                              : modifySeriesGenreSelection(genre)
                            : isSelected
                            ? handleGenreDeseLection(genre)
                            : handleGenreSelection(genre)
                        }
                        style={{
                          color: seriesid
                            ? isModifySeriesGenre
                              ? `${Palette.Neutral.neutral9}`
                              : `${Palette.Neutral.neutral4}`
                            : isSelected
                            ? `${Palette.Neutral.neutral9}`
                            : `${Palette.Neutral.neutral4}`,
                        }}
                      >
                        {genre}
                      </S.Genres>
                    </S.Genre>
                  );
                })}
              </S.GenreListContainer>
            </S.SeriesGenre>
          </S.SeriesGenreBox>
          <S.GenreAddBox>
            <S.RegisterText>원하시는 장르가 없나요?</S.RegisterText>
            <S.Input
              placeholder="장르를 직접 추가해 주세요.."
              value={genreChange}
              onChange={(e) => setGenreChange(e.target.value)}
              onKeyUp={onKeyDown}
            />
          </S.GenreAddBox>

          <S.SeriesRegisterButton>
            <RegisterButton
              width={12}
              height={44}
              radius={8}
              fontsize={16}
              positions={true}
              buttonType="cencel"
              click={() => {
                setPage((prev) => prev - 1);
                setSection("thumbnail");
              }}
            >
              이전
            </RegisterButton>

            <RegisterButton
              width={21}
              height={44}
              radius={8}
              fontsize={16}
              positions={true}
              buttonType="agree"
              click={() =>
                seriesid ? onModifyPageRouter() : onTitleMakerUpload()
              }
            >
              다음
            </RegisterButton>
          </S.SeriesRegisterButton>
        </S.SeriesSettingBox>
      )}
    </S.SeriesSettingContainer>
  );
};

export default SeriesSetting;
