import { useNavigate } from "react-router-dom";
import Modal from "../Modal";
import * as S from "./style";
import { useLogout } from "hooks/Auth/Login/useLogout";
import Palette from "libs/Palette/palette";

interface PopupProps {
  userPopup: boolean;
  toggleuUserPopup: () => void;
}

const Popup = ({ userPopup, toggleuUserPopup }: PopupProps) => {
  const { onLogout } = useLogout();
  const navigate = useNavigate();
  return (
    <Modal isOpen={userPopup} onClose={toggleuUserPopup}>
      <S.ModalWrap>
        <S.ModalContent>
          <S.ModalBox>
            <S.ListBox onClick={() => navigate("/my")}>
              <S.Title>내 정보</S.Title>
            </S.ListBox>
            <S.Line />
            <S.ListBox>
              <S.Title>이전 작품</S.Title>
            </S.ListBox>
            <S.Line />
            <S.ListBox onClick={onLogout}>
              <S.Title style={{ color: `${Palette.error}` }}>로그아웃</S.Title>
            </S.ListBox>
          </S.ModalBox>
        </S.ModalContent>
      </S.ModalWrap>
    </Modal>
  );
};

export default Popup;
