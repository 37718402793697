import customAxios from "libs/Axios/customAxios";
import showAlert from "libs/Toast/toast";
import {
  useGetEpisodeList,
  usePatchEpisode,
} from "queries/ComicUpload/Episode/episode.query";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { EpisodeUploadParam } from "repositories/ComicUpload/Episode/episode.repository";
import { SectionAtom } from "stores/Common/common.store";
import { EpisodeModifyAtom } from "stores/Episode/episode.store";

interface EpisodeModifyProps {
  seriesId: string | null;
  episodeId: string | null;
}

const useEpisodeModify = ({ seriesId, episodeId }: EpisodeModifyProps) => {
  const { data: getEpisodeList } = useGetEpisodeList({
    seriesId: Number(seriesId),
    episodeNumber: Number(episodeId),
  });

  const [modifyEpisodeData, setModifyEpisodeData] =
    useRecoilState(EpisodeModifyAtom);
  const [modifyImgUrl, setModifyImgSrc] = useState<string | any>();
  const [backGroundModifyFil] = useState(new FormData());
  const imgModifyRef = useRef<HTMLLabelElement | null>(null);
  const [, setEpisodeSection] = useRecoilState(SectionAtom);
  const [episodeImgList, setEpisodeImgList] = useState<string[]>([]);
  const [modifyUploadDate, setModifyUploadDate] = useState<string>("");
  const patchEpisodeMutation = usePatchEpisode();

  useEffect(() => {
    if (episodeId && getEpisodeList) {
      const { episodeTitle, thumbnail, createdDate, imageURL } = getEpisodeList;
      setModifyEpisodeData({
        title: episodeTitle,
        thumbnail,
        uploadDate: createdDate,
      });

      setEpisodeImgList(imageURL);
      setModifyUploadDate(createdDate);
    }
  }, [episodeId, getEpisodeList]);

  const handleEpisodeModify = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      setModifyEpisodeData((prev) => ({ ...prev, [name]: value }));
    },
    [setModifyEpisodeData]
  );

  const onEpisodeModifyThumbnailUpload = async () => {
    if (modifyImgUrl !== undefined) {
      const thumbnailResponse = await customAxios.post(
        "comic/image/upload",
        backGroundModifyFil
      );

      setModifyEpisodeData((prev) => ({
        ...prev,
        thumbnail: thumbnailResponse.data,
      }));

      setEpisodeSection("webtoon");
    } else {
      setEpisodeSection("webtoon");
    }
  };

  const onSubmitModifyEpisode = (toggleEpisodeModal: () => void) => {
    const modifiyDataList: EpisodeUploadParam = {
      ...modifyEpisodeData,
      uploadDate: modifyUploadDate,
    };

    patchEpisodeMutation.mutate(
      {
        episodeData: modifiyDataList,
        seriesId: Number(seriesId),
        episodeId: Number(episodeId),
      },
      {
        onSuccess: () => {
          showAlert("에피소드 수정 성공", "SUCCESS");
          toggleEpisodeModal();
        },
      }
    );
  };

  return {
    handleEpisodeModify,
    modifyEpisodeData,
    modifyImgUrl,
    setModifyImgSrc,
    backGroundModifyFil,
    imgModifyRef,
    onEpisodeModifyThumbnailUpload,
    episodeImgList,
    modifyUploadDate,
    setModifyUploadDate,
    onSubmitModifyEpisode,
  };
};

export default useEpisodeModify;
