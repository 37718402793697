import customAxios from "libs/Axios/customAxios";
import { SeriesRepository, SeriesParam } from "./series.repository";
import { SeriesId, SeriesListType } from "types/Series/series.type";

class SeriesRepositoryImpl implements SeriesRepository {
  public async postSeriesCreate(seriesData: SeriesParam): Promise<void> {
    const { data } = await customAxios.post("comic/series/create", seriesData);
    return data;
  }

  public async getSeriesMyList(page: number): Promise<SeriesListType> {
    const { data } = await customAxios.get(
      `/comic/series/my?page=${page}&limit=${12}`
    );
    return data;
  }

  public async deleteSeriesList({ seriesId }: SeriesId): Promise<void> {
    const { data } = await customAxios.delete(
      `comic/series/delete/${seriesId}`
    );
    return data;
  }

  public async patchSeries(
    seriesData: SeriesParam,
    seriesId: number
  ): Promise<void> {
    await customAxios.put(`/comic/series/update/${seriesId}`, seriesData);
  }

  public async getTitleMaker(
    title: string,
    font: string,
    alignLeft: boolean
  ): Promise<string> {
    const { data } = await customAxios.get(
      `/comic/titlemaker?title=${title}&font=${font}&alignLeft=${alignLeft}`
    );

    return data;
  }
}

export default new SeriesRepositoryImpl();
