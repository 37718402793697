import * as S from "./style";
import useTitleMakerUpload from "hooks/Series/useSeriesUpload";
import RegisterButton from "components/common/Button/Register";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { SeriesStatus } from "utils/TitleMaker/seriesData";
import Palette from "libs/Palette/palette";
import { SeriesParam } from "repositories/ComicUpload/Series/series.repository";
import showAlert from "libs/Toast/toast";
import { useRecoilState } from "recoil";
import { PageStateAtom } from "stores/Common/common.store";

interface SeriesModifyProps {
  setSection: Dispatch<SetStateAction<string>>;
  modifyImgUrl: string;
  modifySeriesData: SeriesParam;
  setModifySeriesData: Dispatch<SetStateAction<SeriesParam>>;
  handlePublishStatusCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmitModifySeries: () => void;
}

const SeriesModify = ({
  setSection,
  modifyImgUrl,
  modifySeriesData,
  setModifySeriesData,
  handlePublishStatusCheck,
  onSubmitModifySeries,
}: SeriesModifyProps) => {
  const [, setPage] = useRecoilState<number>(PageStateAtom);
  const { imgSrc } = useTitleMakerUpload();
  const [State, setState] = useState("연재");
  return (
    <S.SeriesContainer>
      <S.SeriesListBox>
        <div>
          <S.RegisterText>
            썸네일 미리보기<span>*</span>
          </S.RegisterText>

          <S.SeriesImgUploadBox>
            <S.ImgPreview
              alt="preview"
              src={
                modifyImgUrl ||
                modifySeriesData.titleMaker.backgroundImage ||
                imgSrc
              }
              style={{
                width: "377px",
                height: "502px",
                position: "relative",
              }}
            />
          </S.SeriesImgUploadBox>
        </div>
        <S.SeriesInputBox>
          <S.InputList>
            <S.RegisterText>
              작품명<span>*</span>
            </S.RegisterText>
            <S.TitlePreview
              onClick={() => showAlert("수정이 불가합니다.", "INFO")}
            >
              {modifySeriesData.title}
            </S.TitlePreview>
            <S.RegisterText>
              연재 상태<span>*</span>
            </S.RegisterText>
            <S.StatusBox>
              {SeriesStatus.map((status) => (
                <S.PeriodButton
                  onClick={() => {
                    setModifySeriesData((prev) => ({
                      ...prev,
                      publishStatus: status.value,
                    }));
                    setState(status.status);
                  }}
                  style={{
                    border:
                      modifySeriesData.publishStatus === "COMPLETED" &&
                      status.value === "COMPLETED"
                        ? `2px solid ${Palette.error}`
                        : modifySeriesData.publishStatus === status.value
                        ? `2px solid ${Palette.basics}`
                        : "",
                    color:
                      modifySeriesData.publishStatus == status.value
                        ? `${Palette.Neutral.neutral9}`
                        : `${Palette.Neutral.neutral4}`,
                  }}
                >
                  {status.status}
                </S.PeriodButton>
              ))}
            </S.StatusBox>
            {modifySeriesData.publishStatus === "COMPLETED" && (
              <>
                <S.RegisterText>
                  완결을 하기 위해서, 작품명을 박스 안에 입력해주세요
                  <span>*</span>
                </S.RegisterText>
                <S.CompletedCheckBox onChange={handlePublishStatusCheck} />
              </>
            )}
          </S.InputList>
        </S.SeriesInputBox>
      </S.SeriesListBox>
      <S.SeriesRegisterButton>
        <RegisterButton
          width={12}
          height={44}
          radius={8}
          fontsize={16}
          positions={true}
          buttonType="cencel"
          click={() => {
            setPage((prev) => prev - 1);
            setSection("setting");
          }}
        >
          이전
        </RegisterButton>
        <RegisterButton
          width={21}
          height={44}
          radius={8}
          fontsize={16}
          positions={true}
          buttonType={
            modifySeriesData.publishStatus === "COMPLETED"
              ? "completed"
              : "agree"
          }
          click={() => onSubmitModifySeries()}
        >
          {State}
        </RegisterButton>
      </S.SeriesRegisterButton>
    </S.SeriesContainer>
  );
};

export default SeriesModify;
