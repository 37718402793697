import { useEffect, useState } from "react";

const useSignup = () => {
  //이메일 인증 타이머 state
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [timerActive, setTimerActive] = useState<boolean>(false);

  //이메일 인증 타이머
  const startTimer = () => {
    setTimeRemaining(300);
    setTimerActive(true);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setTimerActive(false);
    }

    return () => clearInterval(interval);
  }, [timerActive, timeRemaining]);

  return {
    timeRemaining,
    startTimer,
  };
};

export default useSignup;
