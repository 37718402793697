import * as S from "./style";
import Profile from "assets/img/Sideber/임시.svg";
import RegisterButton from "components/common/Button/Register";
import Info from "assets/img/info.svg";
import { useGetUserInfo } from "queries/User/user.query";
import showAlert from "libs/Toast/toast";
import { useState } from "react";

const My = () => {
  const { data: getUserInfo } = useGetUserInfo();
  const [editState, setEditState] = useState<boolean>(false);

  return (
    <S.MyContainer>
      <S.MyBox>
        <S.ProfileBox>
          <S.ProfileImg src={Profile} alt="profile" />
        </S.ProfileBox>
        <S.UserInfoBox>
          <div>
            <S.Title>
              닉네임
              <S.Icon
                src={Info}
                onClick={() =>
                  showAlert("30일에 1번 변경이 가능합니다", "INFO")
                }
              />
            </S.Title>
            <S.Input width={true} value={getUserInfo?.name} />
          </div>
          <div>
            <S.Title>
              아이디
              <S.Icon
                src={Info}
                onClick={() =>
                  showAlert("30일에 1번 변경이 가능합니다", "INFO")
                }
              />
            </S.Title>
            <S.Input width={true} value={getUserInfo?.userId} />
          </div>
        </S.UserInfoBox>
        <S.EmailBox>
          <div>
            <S.Title>이메일</S.Title>
            <S.Input width={false} value={getUserInfo?.email} />
          </div>
        </S.EmailBox>
        <S.ButtonContainer>
          <RegisterButton
            width={19}
            height={44}
            radius={8}
            fontsize={16}
            positions={true}
            buttonType="agree"
            click={() => setEditState(true)}
          >
            수정
          </RegisterButton>
        </S.ButtonContainer>
      </S.MyBox>
    </S.MyContainer>
  );
};

export default My;
