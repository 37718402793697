import TextField from "components/common/TextField";
import * as S from "./style";
import RegisterButton from "components/common/Button/Register";
import Line from "assets/img/Auth/Line.svg";
import { useLogin } from "hooks/Auth/Login/useLogin";
import { Dispatch, SetStateAction } from "react";
import CheckBoxHeadless from "components/common/CheckBox";
import Lottie from "react-lottie-player";
import CheckBoxLottie from "json/Toongether_Checkbox.json";
import { useRecoilState } from "recoil";
import { ErrorStateAtom } from "stores/Common/common.store";

interface LoginProps {
  setIsLogin: Dispatch<SetStateAction<boolean>>;
}

const Login = ({ setIsLogin }: LoginProps) => {
  const { onSubmitLogin, handleLoginChange, LoginData } = useLogin();
  const [, setErrorState] =
    useRecoilState<Record<string, string>>(ErrorStateAtom);

  return (
    <>
      <S.LoginInputBox>
        <TextField
          onchange={handleLoginChange}
          id="userId"
          name="userId"
          functions="password"
        >
          이메일 또는 아이디
        </TextField>
      </S.LoginInputBox>

      <S.LoginInputBox>
        <TextField
          onchange={handleLoginChange}
          id="password"
          name="password"
          type="password"
          functions={onSubmitLogin}
        >
          비밀번호
        </TextField>
      </S.LoginInputBox>
      <S.LoginStateBox>
        <S.LoginState>
          <CheckBoxHeadless>
            {({ isChecked, onChange }) => {
              return (
                <S.Label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    accept="image/png, image/jpg"
                    onChange={onChange}
                    style={{ display: "none" }}
                  />

                  {isChecked ? (
                    <Lottie
                      animationData={CheckBoxLottie}
                      loop={false}
                      play
                      style={{ width: 20, height: 20 }}
                      direction={1}
                    />
                  ) : (
                    <Lottie
                      animationData={CheckBoxLottie}
                      loop={false}
                      play
                      style={{ width: 20, height: 20 }}
                      direction={-1}
                    />
                  )}

                  <p>로그인 상태 유지</p>
                </S.Label>
              );
            }}
          </CheckBoxHeadless>
        </S.LoginState>
      </S.LoginStateBox>
      <S.LoginButtonBox>
        <RegisterButton
          width={100}
          height={53}
          radius={8}
          fontsize={18}
          click={onSubmitLogin}
          positions={true}
          buttonType="agree"
          disabled={
            LoginData.userId.length === 0 || LoginData.password.length === 0
          }
        >
          로그인
        </RegisterButton>
      </S.LoginButtonBox>
      <S.LoginInfoBox>
        <p
          onClick={() => {
            setIsLogin(false);
            setErrorState({});
          }}
        >
          회원가입
        </p>
        <S.LoginFindBox>
          <p>아이디 찾기</p> &nbsp;&nbsp;
          <img alt="line" src={Line} />
          &nbsp;&nbsp;
          <p>비밀번호 찾기</p>
        </S.LoginFindBox>
      </S.LoginInfoBox>
    </>
  );
};

export default Login;
