import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const EpisodeCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 820px;
  height: 903px;

  background-color: white;

  border-radius: 10px;

  @media screen and (max-height: 1054px) {
    height: 860px;
  }

  @media screen and (max-height: 990px) {
    height: 800px;
  }

  position: relative;
`;

export const EpisodeCommentBox = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 30px;

  width: 100%;
`;

export const Profile = styled.img`
  width: 40px;
  height: 40px;
`;

export const CommentBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
`;
export const CommentInfo = styled.div`
  display: flex;

  font-size: 16px;
  font-family: Pretendard-SemiBold, Apple SD Gothic Neo, Malgun Gothic;

  gap: 10px;

  margin-bottom: 7px;
  p {
    font-size: 14px;
    font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
    color: ${Palette.Neutral.neutral5};
  }
`;
export const CommentContent = styled.div`
  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
`;
export const CommentList = styled.div`
  display: flex;

  gap: 10px;
`;

export const ParentCommentList = styled.div`
  display: flex;

  gap: 10px;

  margin-left: 50px;
  margin-bottom: 10px;
`;
export const CommentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 1800px) {
    width: 41vw;
  }

  width: 38.5vw;
`;

export const InputBox = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  bottom: 0;

  padding-bottom: 20px;
  gap: 20px;

  .button {
    position: absolute;

    margin-left: -47px;
    margin-top: 12px;
  }
`;

export const CommentInput = styled.input`
  width: 700px;
  height: 50px;

  border-radius: 100px;

  border: 1px solid ${Palette.Neutral.neutral5};

  padding-left: 25px;

  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
`;

export const Button = styled.img`
  position: absolute;

  margin-left: -47px;
  margin-top: 12px;
`;

export const DeleteButton = styled.img`
  user-select: none;
  cursor: pointer;
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40vw;

  height: 68vh;

  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;
`;

export const ParentCommentContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const ParentCommentBox = styled.div`
  display: flex;
  align-items: center;

  gap: 5px;

  cursor: pointer;

  user-select: none;
  p {
    font-size: 14px;
    margin-top: 1px;
  }
`;
