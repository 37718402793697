export const EPISODE_SECTION_NAME = [
  {
    title: "info",
  },
  {
    title: "webtoon",
  },
  {
    title: "setting",
  },
];
