import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { DragType } from "types/Drag/drag.type";

interface FileTypes {
  id: number;
  object: File;
}

const useDragFileUpload = ({ File, setFile, dragRef }: DragType) => {
  const [, setIsDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<FileTypes[]>([]);
  const fileId = useRef<number>(0);

  const onClickImgs = () => {
    if (dragRef.current) {
      dragRef.current.click();
    }
  };

  const onChangeFiles = useCallback(
    (e: ChangeEvent<HTMLInputElement> | any): void => {
      let selectFiles: File[] = [];
      let tempFiles: FileTypes[] = files;

      if (e.type === "drop") {
        selectFiles = e.dataTransfer.files;
      } else {
        selectFiles = e.target.files;
      }

      Array.from(selectFiles).forEach((file) => {
        tempFiles = [
          ...tempFiles,
          {
            id: fileId.current++,
            object: file,
          },
        ];
      });

      if (selectFiles.length === 0) {
        return;
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(selectFiles[0]);

        reader.onload = () => {
          File.append("file", selectFiles[0]);
          setFile(reader.result || null);
        };
      }

      setFile(null); // 파일 데이터 초기화
      fileId.current = 0; // 파일 ID 초기화
      setFiles([]); // 파일 배열 초기화
    },
    [files]
  );

  const handleDragIn = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer!.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles]
  );

  const initDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener("dragenter", handleDragIn);
      dragRef.current.addEventListener("dragleave", handleDragOut);
      dragRef.current.addEventListener("dragover", handleDragOver);
      dragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener("dragenter", handleDragIn);
      dragRef.current.removeEventListener("dragleave", handleDragOut);
      dragRef.current.removeEventListener("dragover", handleDragOver);
      dragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();

    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  return { onClickImgs, onChangeFiles, dragRef };
};

export default useDragFileUpload;
