import { ChangeEvent, useCallback, useState } from "react";
import { useDeleteEpisode } from "queries/ComicUpload/Episode/episode.query";
import showAlert from "libs/Toast/toast";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "queries/queryKey";
import { useNavigate } from "react-router-dom";

export function useEpisodeDelete(toggleEpisodeDeleteModal: () => void) {
  const deleteEpisodeMutation = useDeleteEpisode();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [deleteEpisodeCheck, setDeleteEpisodeCheck] = useState<string>("");

  const handleEpisodeDeleteCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setDeleteEpisodeCheck(e.target.value);
  };

  const onSubmitEpisodeDelete = useCallback(
    (
      seriesId: string | null,
      episodeId: number,
      title: string | undefined,
      check: string
    ) => {
      if (title === check) {
        deleteEpisodeMutation.mutate(
          {
            seriesId: seriesId,
            episodeId: episodeId,
          },
          {
            onSuccess: () => {
              showAlert("에피소드 삭제성공", "SUCCESS");
              queryClient.invalidateQueries(
                QUERY_KEYS.episode.episodeMyList(
                  sessionStorage.getItem("SeriesId")
                )
              );
              navigate(`/series/manage/${sessionStorage.getItem("SeriesId")}`);

              toggleEpisodeDeleteModal();
            },
            onError: () => {
              showAlert("에피소드 삭제실패", "ERROR");
            },
          }
        );
      }
    },
    []
  );
  return {
    onSubmitEpisodeDelete,
    deleteEpisodeCheck,
    handleEpisodeDeleteCheck,
  };
}
