import styled from "styled-components";
import Palette from "libs/Palette/palette";

export const EpisodeListBox = styled.div`
  width: 752px;

  display: flex;
  flex-direction: column;
`;

export const RegisterText = styled.p<{ isEdit?: boolean }>`
  margin-bottom: 7px;
  margin-left: 5px;

  font-size: 16px;
  font-family: Pretendard-Bold, Apple SD Gothic Neo, Malgun Gothic;

  color: ${(props) =>
    props.isEdit
      ? `${Palette.Neutral.neutral4}`
      : `${Palette.Neutral.neutral9}`};
  span {
    color: ${Palette.error};
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;

  border-radius: 10px;
  border: 1px solid ${Palette.Neutral.neutral2};

  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  padding-left: 20px;

  margin-bottom: 42px;

  color: ${Palette.Neutral.neutral9};

  &::placeholder {
    color: ${Palette.Neutral.neutral4};
  }

  &:focus {
    border: 1px solid ${Palette.Primary.primary5};
  }
`;

export const TitlePreview = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;

  margin-bottom: 42px;

  border-radius: 10px;
  border: 1px solid ${Palette.Neutral.neutral2};

  font-size: 16px;
  font-family: Pretendard-Medium, Apple SD Gothic Neo, Malgun Gothic;

  padding-left: 20px;

  color: ${Palette.Neutral.neutral4};

  user-select: none;
`;

export const EpisodeImgUploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 485px;
  height: 302px;

  border: 1px solid ${Palette.Neutral.neutral2};
  border-radius: 10px;

  margin-bottom: 35px;

  img {
    width: 134px;
    height: 150px;

    margin-bottom: 24px;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const ImgPreview = styled.img`
  border-radius: 10px;
  margin-top: 24px;
`;

export const EpisodeRegisterButton = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;
`;

export const ImgHoverAddBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  background-color: rgba(255, 255, 255, 0.65);

  width: 485px;
  height: 302px;

  border-radius: 10px;
`;
