import * as S from "./style";
import { ReactComponent as Claendars } from "assets/img/Sideber/calendar.svg";
import { ReactComponent as Grap } from "assets/img/Sideber/grap.svg";
import { ReactComponent as Message } from "assets/img/Sideber/message.svg";
import { ReactComponent as Money } from "assets/img/Sideber/money.svg";
import { ReactComponent as Books } from "assets/img/Sideber/book.svg";
import Profile from "assets/img/Sideber/임시.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Palette from "libs/Palette/palette";
import { useGetUserInfo } from "queries/User/user.query";

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: getUserInfo } = useGetUserInfo();
  const [activeButton, setActiveButton] = useState("series");
  const handleButtonClick = (buttonName: string) => {
    navigate(`/${buttonName}`);
  };

  useEffect(() => {
    if (pathname === "/series") {
      setActiveButton("series");
    } else if (pathname === "/shorts") {
      setActiveButton("shorts");
    }
  }, [pathname]);

  return (
    <S.SidebarContainer>
      <S.SidebarBox>
        <S.SidebarListBox>
          <S.ProfileBox>
            <img alt="profile" src={Profile} />
            <S.Name>{getUserInfo?.name} 작가</S.Name>
          </S.ProfileBox>
          <S.SidebarList
            isBefore={true}
            onClick={() => handleButtonClick("series")}
            style={{
              color:
                activeButton === "series" ? `${Palette.Primary.primary5}` : "",
            }}
            isSelect={activeButton === "series"}
          >
            <Claendars
              className="icon"
              fill={
                activeButton === "series"
                  ? `${Palette.Primary.primary5}`
                  : `${Palette.Neutral.neutral4}`
              }
            />
            연재 관리
          </S.SidebarList>
          <S.SidebarList
            isBefore={true}
            onClick={() => handleButtonClick("shorts")}
            isSelect={activeButton === "shorts"}
            style={{
              color:
                activeButton === "shorts" ? `${Palette.Primary.primary5}` : "",
            }}
          >
            <Books
              className="icon"
              fill={
                activeButton === "shorts"
                  ? `${Palette.Primary.primary5}`
                  : `${Palette.Neutral.neutral4}`
              }
            />
            단편 관리
          </S.SidebarList>
          <S.SidebarList isBefore={false}>
            <Message fill={Palette.Neutral.neutral3} className="icon" />
            커뮤니티<span>준비중</span>
          </S.SidebarList>
          <S.SidebarList isBefore={false}>
            <Grap fill={Palette.Neutral.neutral3} className="icon" />
            통계<span>준비중</span>
          </S.SidebarList>
          <S.SidebarList isBefore={false}>
            <Money fill={Palette.Neutral.neutral3} className="icon" />
            수익창출<span>준비중</span>
          </S.SidebarList>
        </S.SidebarListBox>
      </S.SidebarBox>
    </S.SidebarContainer>
  );
};

export default Sidebar;
