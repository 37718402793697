import * as S from "../style";
import { buttonType } from "types/Button/button.type";

interface ReturnProps {
  height: number;
  radius: number;
  fontsize: number;
  positions: boolean;
  width: number;
  buttonType: buttonType;
  children: React.ReactNode;
  click?: (e: React.FormEvent) => void;
  disabled?: boolean;
  onkeydown?: React.KeyboardEventHandler<HTMLButtonElement> | undefined;
}

const RegisterButton: React.FC<ReturnProps> = ({
  children,
  width,
  height,
  radius,
  fontsize,
  click,
  disabled,
  positions,
  onkeydown,
  buttonType,
}) => {
  return (
    <S.RegisterButton
      onClick={click}
      onKeyDown={onkeydown}
      disabled={disabled}
      isPosition={positions}
      buttonType={buttonType}
      style={{
        width: `${width}%`,
        height: `${height}px`,
        borderRadius: `${radius}px`,
        fontSize: `${fontsize}px`,
        border: 0,
      }}
    >
      {children}
    </S.RegisterButton>
  );
};

export default RegisterButton;
